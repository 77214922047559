/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import { Box, Text, Grid, GridItem, Checkbox, FormControl, FormErrorMessage, Select, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IoChevronDown } from 'react-icons/io5';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { getErrorMessage, hasErrorMessage, clearErrorMessage, FormattedError } from '../../../../utils/FormErrorUtils';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { FitbandTemplateType } from '../../fitbandConfiguration/FitbandConfigurationTypes';
import colors from '../../../../styles/colors';

export const JobPositionActionEnableContainer = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <Grid alignItems="baseline" templateColumns="repeat(3, 1fr)" gap={8} autoRows="auto">
      {children}
    </Grid>
  );
};

interface FitbandSelectProps {
  fitbandTemplateEnabled: boolean;
  onHandleChange: (value: string | boolean | null, fieldName: string, dataType: boolean) => void;
  fitBandTemplateId: string;
  formErrors: FormattedError[];
  setFormErrors: (errors: FormattedError[]) => void;
}

function JobPositionFitbandTemplate({
  fitbandTemplateEnabled,
  onHandleChange,
  formErrors,
  setFormErrors,
  fitBandTemplateId,
}: FitbandSelectProps) {
  const { t } = useTranslation();
  const isFitbandTemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.fitbandTemplateEnabled);
  const chekboxRef = React.useRef<HTMLInputElement>(null);
  const [showTemplateSelect, setShowTemplateSelect] = useState<boolean>(false);

  React.useEffect(() => {
    setShowTemplateSelect(fitbandTemplateEnabled);
  }, [fitbandTemplateEnabled]);

  return (
    <JobPositionActionEnableContainer>
      <GridItem>
        <Checkbox
          ref={chekboxRef}
          isChecked={fitbandTemplateEnabled}
          onChange={(e) => onHandleChange(e.target.checked, 'fitBandTemplateEnable', false)}
        >
          {t('createJobPosition:fitband.fitbandEvaluation')}
        </Checkbox>
      </GridItem>
      {isFitbandTemplateEnabled && showTemplateSelect && (
        <GridItem>
          <TemplateSelect
            fitbandTemplateEnabled={fitbandTemplateEnabled}
            onHandleChange={onHandleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            fitBandTemplateId={fitBandTemplateId}
          />
        </GridItem>
      )}
    </JobPositionActionEnableContainer>
  );
}
// eslint-disable-next-line react/display-name
export const TemplateSelect = React.memo(
  ({ fitbandTemplateEnabled, fitBandTemplateId, onHandleChange, formErrors, setFormErrors }: FitbandSelectProps) => {
    const { t } = useTranslation('createJobPosition');
    const selectRef = React.useRef<HTMLSelectElement>(null);

    const { getFitbandTemplates } = useStoreActions((state) => state.fitbandTemplate);
    const isFitbandTemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.fitbandTemplateEnabled);

    const { currentUserProfile } = useCurrentUserProfile();
    const accountId = currentUserProfile?.account ?? '';

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [templates, setTemplates] = useState<FitbandTemplateType[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<FitbandTemplateType | null>(null);

    const hasError = hasErrorMessage(formErrors, 'fitBandTemplateId');

    React.useEffect(() => {
      if (hasError && selectRef.current) {
        selectRef.current.focus();
        selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    React.useEffect(() => {
      if (templates.length === 0) return;

      setSelectedTemplate(templates.find((template) => template.id === fitBandTemplateId) ?? null);
    }, [fitBandTemplateId, templates]);

    const handleGetTemplates = React.useCallback(() => {
      setIsLoading(true);
      getFitbandTemplates({ accountId, params: {} })
        .then((res) => {
          setTemplates(res.templates);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          return setIsLoading(false);
        });
    }, [accountId, getFitbandTemplates]);

    React.useEffect(() => {
      handleGetTemplates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTemplateChange = (value: string) => {
      const fitbandTemplate = templates.find((template) => template.id === value) ?? null;
      setSelectedTemplate(fitbandTemplate);
      onHandleChange(value, 'fitBandTemplateId', false);
      setFormErrors(clearErrorMessage(formErrors, 'fitBandTemplateId'));
    };

    return (
      <FormControl isRequired isInvalid={hasError}>
        <Select
          className="filled"
          id="jobPositionFitbandTemplate"
          ref={selectRef}
          isLoading={isLoading}
          value={selectedTemplate?.id}
          disabled={!(isFitbandTemplateEnabled && fitbandTemplateEnabled)}
          icon={isLoading ? <Spinner size="sm" /> : <IoChevronDown size={15} color={colors.black} />}
          data-testid="selectFitbandTemplate"
          onChange={(e) => handleTemplateChange(e.target.value)}
          _placeholder={{ color: colors.gray[400] }}
          backgroundColor={colors.input.background}
        >
          <option value="" selected disabled>
            {t('createJobPosition:selectTemplate')}
          </option>

          {templates?.map((template) => (
            <option key={template.id} value={template.id} data-testid="fitband-template">
              {template.name}
              {template?.default === true && ` ${t('createJobPosition:systemDefault')}`}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{getErrorMessage(formErrors, 'fitBandTemplateId')}</FormErrorMessage>
      </FormControl>
    );
  },
);

// eslint-disable-next-line react/display-name
const FitbandEvaluationSelect = React.memo((props: FitbandSelectProps) => {
  const fitbandEnabled = useStoreState((state) => state.app.accounts?.configuration?.fitbandTemplateEnabled);
  if (!fitbandEnabled) return null;
  return (
    <Box mb={6} data-testid="job-position-fitband-enabled">
      <JobPositionFitbandTemplate {...props} />
    </Box>
  );
});

interface GetSeletedFitbandTemplateProps {
  templateId: string | null;
}

export function GetSeletedFitbandTemplate({ templateId }: GetSeletedFitbandTemplateProps) {
  const { fitbandTemplates } = useStoreState((state) => state.fitbandTemplate);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedFitbandTemplate =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    fitbandTemplates?.templates?.find((template: { id: string | null }) => template.id === templateId) ?? null;
  if (!selectedFitbandTemplate) return null;
  return (
    <Text fontSize="sm" data-testid="preview-fitband-template">
      {selectedFitbandTemplate.name}
    </Text>
  );
}

export default FitbandEvaluationSelect;
