/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import { Box, Text, GridItem, Checkbox, FormControl, FormErrorMessage, Select, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IoChevronDown } from 'react-icons/io5';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { getErrorMessage, hasErrorMessage, clearErrorMessage, FormattedError } from '../../../../utils/FormErrorUtils';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import colors from '../../../../styles/colors';
import { LanguageEvaluationTemplateResponseType } from '../../languageEvaluation/LanguageEvaluationTypes';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { Lookups } from '../../../../firebase/firestore/documents/lookups';
import { LanguageEvaluationRating } from '../../../../firebase/firestore/documents/candidate';
import { JobPositionActionEnableContainer } from './FitbandEvaluationSelect';

interface LeTemplateSelectProps {
  leTemplateEnable: boolean;
  onHandleChange: (value: string | number | boolean | null, fieldName: string, dataType: boolean) => void;
  leTemplateId: string;
  languageEvaluationThreshold: number;
  audioEvaluationOptions: Lookups[] | undefined;
  formErrors: FormattedError[];
  setFormErrors: (errors: FormattedError[]) => void;
}

function JobPositionLETemplate({
  leTemplateEnable,
  languageEvaluationThreshold,
  onHandleChange,
  formErrors,
  setFormErrors,
  leTemplateId,
  audioEvaluationOptions,
}: LeTemplateSelectProps) {
  const { t, i18n } = useTranslation();
  const isLanguageEvaluationEnabled = useStoreState((s) => s.app.accounts?.configuration?.languageEvaluationEnabled);
  const chekboxRef = React.useRef<HTMLInputElement>(null);
  const [showTemplateSelect, setShowTemplateSelect] = useState<boolean>(false);

  React.useEffect(() => {
    setShowTemplateSelect(leTemplateEnable);
  }, [leTemplateEnable]);

  return (
    <JobPositionActionEnableContainer>
      <GridItem>
        <Checkbox
          ref={chekboxRef}
          isChecked={leTemplateEnable}
          onChange={(e) => onHandleChange(e.target.checked, 'languageEvaluationEnable', false)}
        >
          {t('createJobPosition:languageEvaluation')}
        </Checkbox>
      </GridItem>
      {isLanguageEvaluationEnabled && showTemplateSelect && (
        <>
          <GridItem>
            <TemplateSelect
              leTemplateEnable={leTemplateEnable}
              onHandleChange={onHandleChange}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              leTemplateId={leTemplateId}
            />
          </GridItem>
          <GridItem>
            <FormControl isRequired isInvalid={hasErrorMessage(formErrors, 'languageEvaluationPassThreshold')}>
              <Select
                value={languageEvaluationThreshold}
                name="languageEvaluationPassThreshold"
                id="languageEvaluationPassThreshold-label"
                data-testid="languageThresholdChange"
                onChange={(e) => {
                  onHandleChange(Number(e.target.value), 'languageEvaluationPassThreshold', false);
                }}
              >
                <option value="" selected disabled>
                  {t('createJobPosition:selectPassThreshold')}
                </option>
                {audioEvaluationOptions
                  ?.filter((option) => option.id !== LanguageEvaluationRating.NEEDS_REVIEW)
                  .map((option) => (
                    <option key={option.order} value={option.order}>
                      {getTranslatedValue(option.label, i18n.language)}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage>{getErrorMessage(formErrors, 'languageEvaluationPassThreshold')}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </>
      )}
    </JobPositionActionEnableContainer>
  );
}
// eslint-disable-next-line react/display-name
export const TemplateSelect = React.memo(
  ({
    leTemplateEnable,
    leTemplateId,
    onHandleChange,
    formErrors,
    setFormErrors,
  }: Omit<LeTemplateSelectProps, 'audioEvaluationOptions' | 'languageEvaluationThreshold'>) => {
    const { t } = useTranslation('createJobPosition');
    const selectRef = React.useRef<HTMLSelectElement>(null);

    const { getLanguageEvaluationTemplates } = useStoreActions((state) => state.languageEvaluationTemplate);
    const isLanguageEvaluationEnabled = useStoreState((s) => s.app.accounts?.configuration?.languageEvaluationEnabled);

    const { currentUserProfile } = useCurrentUserProfile();
    const accountId = currentUserProfile?.account ?? '';

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [templates, setTemplates] = useState<LanguageEvaluationTemplateResponseType[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<LanguageEvaluationTemplateResponseType | null>(null);

    const hasError = hasErrorMessage(formErrors, 'leTemplateId');

    React.useEffect(() => {
      if (hasError && selectRef.current) {
        selectRef.current.focus();
        selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    React.useEffect(() => {
      if (templates?.length === 0) return;

      setSelectedTemplate(templates?.find((template) => template.id === leTemplateId) ?? null);
    }, [leTemplateId, templates]);

    const handleGetLETemplates = React.useCallback(() => {
      setIsLoading(true);
      const params: { after?: string; before?: string; default?: boolean; limit?: number } = {};
      getLanguageEvaluationTemplates({ accountId, params })
        .then((res) => {
          setTemplates(res.languageEvaluationTemplates);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          return setIsLoading(false);
        });
    }, [accountId, getLanguageEvaluationTemplates]);

    React.useEffect(() => {
      handleGetLETemplates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTemplateChange = (value: string) => {
      const leTemplate = templates?.find((template) => template.id === value) ?? null;
      setSelectedTemplate(leTemplate);
      onHandleChange(value, 'leTemplateId', false);
      setFormErrors(clearErrorMessage(formErrors, 'leTemplateId'));
    };

    return (
      <FormControl isRequired isInvalid={hasError}>
        <Select
          className="filled"
          id="jobPositionLETemplate"
          ref={selectRef}
          isLoading={isLoading}
          value={selectedTemplate?.id}
          disabled={!(isLanguageEvaluationEnabled && leTemplateEnable)}
          icon={isLoading ? <Spinner size="sm" /> : <IoChevronDown size={15} color={colors.black} />}
          data-testid="leTemplateSelect"
          onChange={(e) => handleTemplateChange(e.target.value)}
          _placeholder={{ color: colors.gray[400] }}
          backgroundColor={colors.input.background}
        >
          <option value="" selected disabled>
            {t('createJobPosition:selectTemplate')}
          </option>

          {templates?.map((template) => (
            <option key={template.id} value={template.id} data-testid="LE-template">
              {template.templateName}
              {template?.default === true && ` ${t('createJobPosition:systemDefault')}`}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{getErrorMessage(formErrors, 'leTemplateId')}</FormErrorMessage>
      </FormControl>
    );
  },
);

// eslint-disable-next-line react/display-name
const LanguageEvaluationTemplateSelect = React.memo((props: LeTemplateSelectProps) => {
  const isLanguageEvaluationEnabled = useStoreState((s) => s.app.accounts?.configuration?.languageEvaluationEnabled);
  if (!isLanguageEvaluationEnabled) return null;
  return (
    <Box mb={6} data-testid="job-position-language-evaluation-enabled">
      <JobPositionLETemplate {...props} />
    </Box>
  );
});

interface GetSelectedLETemplateProps {
  templateId: string | null;
}

export function GetSelectedLETemplate({ templateId }: GetSelectedLETemplateProps) {
  const { leTemplates } = useStoreState((state) => state.languageEvaluationTemplate);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const selectedLETemplate =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    leTemplates?.languageEvaluationTemplates?.find((template: { id: string | null }) => template.id === templateId) ?? null;

  if (!selectedLETemplate) return null;

  return (
    <Text fontSize="sm" data-testid="preview-LE-template-name">
      {selectedLETemplate?.templateName}
    </Text>
  );
}

export default LanguageEvaluationTemplateSelect;
