import React, { useState } from 'react';
import {
  Box,
  GridItem,
  Checkbox,
  Select,
  FormControl,
  FormErrorMessage,
  NumberInputStepper,
  NumberInputField,
  NumberInput,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IoChevronDown } from 'react-icons/io5';
import { useStoreState } from '../../../../models/hooks';
import { FormattedError, hasErrorMessage, getErrorMessage } from '../../../../utils/FormErrorUtils';
import { JobPositionActionEnableContainer } from './FitbandEvaluationSelect';
import colors from '../../../../styles/colors';

interface ModernHireProps {
  modernHireEnabled: boolean;
  modernHireThreshold: number;
  modernHireTemplateId: string;
  onHandleChange: (value: string | boolean | null, fieldName: string, dataType: boolean) => void;
  formErrors: FormattedError[];
  setFormErrors: (errors: FormattedError[]) => void;
}

function JobPositionModernHireTemplate({
  modernHireEnabled,
  modernHireThreshold,
  onHandleChange,
  formErrors,
  ...rest
}: ModernHireProps) {
  const { t } = useTranslation();
  const chekboxRef = React.useRef<HTMLInputElement>(null);
  const isModernHireEnabled = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);
  const [showTemplateSelect, setShowTemplateSelect] = useState<boolean>(false);

  React.useEffect(() => {
    setShowTemplateSelect(modernHireEnabled);
  }, [modernHireEnabled]);

  return (
    <JobPositionActionEnableContainer>
      <GridItem>
        <Checkbox
          ref={chekboxRef}
          isChecked={modernHireEnabled}
          data-testid="modernHire"
          onChange={(e) => onHandleChange(e.target.checked, 'modernHireEnable', false)}
        >
          {t('createJobPosition:modernHire')}
        </Checkbox>
      </GridItem>

      {isModernHireEnabled && showTemplateSelect && (
        <>
          <GridItem>
            <TemplateSelect
              {...rest}
              modernHireEnabled={modernHireEnabled}
              onHandleChange={onHandleChange}
              formErrors={formErrors}
            />
          </GridItem>
          <GridItem>
            <FormControl isInvalid={hasErrorMessage(formErrors, 'modernHirePassThreshold')}>
              <NumberInput
                min={0}
                max={100}
                variant="filled"
                id="experienceYears"
                type="number"
                w="100%"
                isDisabled={!(isModernHireEnabled && modernHireEnabled)}
                value={modernHireThreshold ?? /* istanbul ignore next */ ''}
                onChange={/* istanbul ignore next */ (value) => onHandleChange(value, 'modernHirePassThreshold', true)}
                placeholder="Evaluation Pass Threshold"
                data-testid="modernHirePassThreshold"
              >
                <NumberInputField />
                <NumberInputStepper borderColor="#dee1e3">
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>{getErrorMessage(formErrors, 'modernHirePassThreshold')}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </>
      )}
    </JobPositionActionEnableContainer>
  );
}

// eslint-disable-next-line react/display-name
export const TemplateSelect = React.memo(
  ({ modernHireEnabled, modernHireTemplateId, onHandleChange, formErrors }: Omit<ModernHireProps, 'modernHireThreshold'>) => {
    const { t } = useTranslation();
    const selectRef = React.useRef<HTMLSelectElement>(null);
    const isModernHireEnabled = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);
    const modernHireTemplateIdList = useStoreState((s) => s.app.accounts?.configuration?.modernHireTemplateIdList);

    const hasError = hasErrorMessage(formErrors, 'modernHireTemplateId');

    // istanbul ignore next
    React.useEffect(() => {
      if (hasError && selectRef.current) {
        selectRef.current.focus();
        selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    return (
      <FormControl isInvalid={hasError}>
        <Select
          className="filled"
          id="jobLabel"
          value={modernHireTemplateId}
          disabled={!(isModernHireEnabled && modernHireEnabled)}
          data-testid="modernHireTemplateSelect"
          icon={<IoChevronDown size={15} color={colors.black} />}
          onChange={
            /* istanbul ignore next */ (e) => {
              onHandleChange(e.target.value, 'modernHireTemplateId', false);
            }
          }
          ref={selectRef}
          backgroundColor={colors.input.background}
        >
          <option value="" selected disabled>
            {t('createJobPosition:templateID')}
          </option>
          {modernHireTemplateIdList?.map((template) => (
            <option key={template} value={template} data-testid="template-template">
              {template}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{getErrorMessage(formErrors, 'modernHireTemplateId')}</FormErrorMessage>
      </FormControl>
    );
  },
);

// eslint-disable-next-line react/display-name
const ModernHireSelect = React.memo((props: ModernHireProps) => {
  const isModernHireEnabled = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);

  /* istanbul ignore next */
  if (!isModernHireEnabled) return null;
  return (
    <Box mb={6} data-testid="job-position-modern-hire-enabled">
      <JobPositionModernHireTemplate {...props} />
    </Box>
  );
});

export default ModernHireSelect;
