import React from 'react';
import { SchemaBuilderProps, updateMissingTranslations } from './formBuilderSchema';
import TextSchemaBuilder from './TextSchemaBuilder';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useHireFormContext } from '../HireForm.context';

interface FormElementTextBuilderProps extends SchemaBuilderProps {
  placeholder?: string;
  startWithEditView?: boolean;
}

const FormElementTextBuilder = React.memo(
  function ({
    onChange,
    defaultValue,
    language,
    placeholder,
    startWithEditView,
    sectionIndex,
    fieldIndex,
    ...props
  }: FormElementTextBuilderProps) {
    const path = [...props.path, 'text', language];
    const { languages, defaultLanguage } = useHireFormContext();

    const handleChange = (value: string) => {
      const changedValue = { ...defaultValue, text: { ...defaultValue?.text!, [language]: value } };

      const missingTranslations = updateMissingTranslations(changedValue, 'text', defaultLanguage, languages);
      onChange({ ...missingTranslations });
    };

    return (
      <TextSchemaBuilder
        label={defaultValue?.text?.[language]!}
        placeholder={placeholder}
        onLabelChange={handleChange}
        startWithEditView={startWithEditView}
        language={language}
        path={path}
      />
    );
  },
  (prevProps, nextProps) => {
    return isEqual(omit(prevProps, ['startWithEditView', 'placeholder']), omit(nextProps, ['startWithEditView', 'placeholder']));
  },
);

export default FormElementTextBuilder;
