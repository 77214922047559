import { Input } from '@chakra-ui/core';
import React from 'react';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import useErrorHandling from '../useErrorHandling';
import theme from '../../../../styles/customTheme';
import ErrorWrapper from '../errorHandling/ErrorWrapper';
import { useTranslation } from 'react-i18next';
import { PATH_HIRE_FORM_COPY } from '../../../../routes/constants';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import useExitPrompt from '../../companyManagement/useExitPrompt';

interface TemplateNameInputProps {
  templateName: string;
  setTemplateName: React.Dispatch<React.SetStateAction<string>>;
}

const TemplateNameInput = React.memo(({ templateName, setTemplateName }: TemplateNameInputProps) => {
  const { currentTemplate } = useStoreState((state) => state.hrFormTemplate);
  const { checkUniqueTemplateName } = useStoreActions((state) => state.hrFormTemplate);

  const { t } = useTranslation('hrFormTemplate');

  const templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
  const isCopy = useRouteMatch(PATH_HIRE_FORM_COPY);

  const { currentUserProfile } = useCurrentUserProfile();

  const location = useLocation();

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path: ['name'],
    valdationFor: 'template_name',
    onValidation: (value) => value,
  });
  const [, setShowExitPrompt] = useExitPrompt(false);
  React.useEffect(() => {
    const hasChanged = templateName?.trim()?.length > 0 && templateName !== currentTemplate?.name;
    setShowExitPrompt(hasChanged);
  }, [templateName, currentTemplate, setShowExitPrompt]);

  React.useEffect(() => {
    inputRef.current?.focus();
    const queryParams = new URLSearchParams(location.search);
    const templateName = queryParams.get('templateName');
    if (templateName) {
      setTemplateName(templateName);
    }
    // eslint-disable-next-line
  }, []);

  async function checkUniqueCopyName() {
    if (!currentTemplate || !templateId) return;
    if (currentTemplate.name) {
      let name = currentTemplate.name;
      if (isCopy) {
        const newSuggestedName = await checkUniqueTemplateName({ account: currentUserProfile?.account ?? '', name });
        name = newSuggestedName;
      }
      setTemplateName(name);
    }
  }

  React.useEffect(() => {
    checkUniqueCopyName();

    // eslint-disable-next-line
  }, [currentTemplate, templateId]);

  const handleChange = (val: string) => {
    setTemplateName(val);
    validateError(val);
  };

  return (
    <ErrorWrapper ref={inputRef} error={t(error?.message!)} hasError={hasError}>
      <Input
        data-testid="template-name-input"
        variant="unstyled"
        width="100%"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        value={templateName ?? ''}
        placeholder={t('formBuilder.templateNameInputPlaceholder')}
        flexGrow={1}
        fontSize="normal"
        fontWeight="bold"
        size="md"
        borderRadius={theme.radii.sm}
        ref={inputRef}
        _placeholder={{
          fontWeight: 'normal',
        }}
      />
    </ErrorWrapper>
  );
});

export default TemplateNameInput;
