import React from 'react';
import { FieldSchema, formBuilderType, OptionSchema } from '../formElements/formBuilderSchema';
import { Box, Center, HStack, Link, Stack, Text } from '@chakra-ui/core';
import mobilePreviewSchema from './mobilePreview.schema';
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import isEqual from 'lodash/isEqual';
import theme from '../../../../styles/customTheme';
import { useHireFormContext } from '../HireForm.context';
import { formBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';
import logo from '../../../../assets/img/AppyHere-Recruiter-Blue-Logo.png';
import { SignatureIcon } from '../formElements/SignatureSchemaBuilder';
import { useTranslation } from 'react-i18next';

interface PreviewElementProps {
  question?: FieldSchema;
  answer?: string;
  isFollowUp?: boolean;
  isLastFollowUp?: boolean;
}

const PreviewElementWrapper = React.memo(
  ({ question, answer, isFollowUp = false, isLastFollowUp = false }: PreviewElementProps) => {
    const { language } = useHireFormContext();

    const showQuestion = !!question;
    if (!showQuestion) return null;

    const options = question?.optionList ?? [];

    const followUps = question?.followUpQuestionList ?? [];
    const hasFollowups = (question?.followUpQuestionList?.length ?? 0) > 0;
    const hasFollowupsForNo =
      hasFollowups &&
      followUps?.some((followUp) => followUp?.optionId === options?.find((o) => o?.text?.en?.toLowerCase() === 'no')?.id);
    let selectedOptionId = options?.find((o) => o?.text?.en?.toLowerCase() === 'yes')?.id;
    if (hasFollowupsForNo) {
      selectedOptionId = options?.find((o) => o?.text?.en?.toLowerCase() === 'no')?.id;
    }
    const followupQuestions = followUps?.filter((followUp) => followUp?.optionId === selectedOptionId);

    function renderOptions() {
      switch (question?.inputType) {
        case formBuilderType.date:
          return <DatePreview />;
        case formBuilderType.time:
          return <TimePreview />;
        case formBuilderType.address:
          return <AddressPreview />;
        case formBuilderType.yesNo:
          return <YesNoPreview options={options} />;
        case formBuilderType.checkbox:
          return <CheckBoxPreview options={options} />;
        case formBuilderType.radio:
          return <RadioPreview options={options} />;
        case formBuilderType.link:
          return (
            <Link
              href={question?.link?.[language]}
              isExternal
              textDecoration="underline"
              color={theme.colors.blue[400]}
              fontSize="sm"
            >
              {question?.link?.[language]}
            </Link>
          );
        case formBuilderType.bankAccountNumber:
        case formBuilderType.routingNumber:
        case formBuilderType.branchTransitNumber:
        case formBuilderType.financialInstitutionNumber:
          return <BankInputPreview />;
        case formBuilderType.image:
        case formBuilderType.file:
          return <ImageFilePreview />;
        case formBuilderType.documentReview:
          return <DocumentPreview />;
        case formBuilderType.signature:
          return <SignaturePreview />;
        default:
          return null;
      }
    }

    function renderAnswer() {
      let seekerAnswer = mobilePreviewSchema?.[question?.inputType!]?.answer ?? answer;
      let content: React.ReactNode = null;

      switch (question?.inputType) {
        case formBuilderType.yesNo:
          if (hasFollowupsForNo) {
            const tag = options?.find((o) => o?.text?.en?.toLowerCase() === 'no')?.tag;
            seekerAnswer = tag ?? options?.find((o) => o?.text?.en?.toLowerCase() === 'no')?.text?.[language];
          } else {
            const tag = options?.find((o) => o?.text?.en?.toLowerCase() === 'yes')?.tag;
            seekerAnswer = tag ?? options?.find((o) => o?.text?.en?.toLowerCase() === 'yes')?.text?.[language];
          }

          break;
        case formBuilderType.checkbox:
          seekerAnswer = options?.[0]?.text?.en;
          break;
        case formBuilderType.radio:
          seekerAnswer = options?.[0]?.text?.en;
          break;
        case formBuilderType.image:
        case formBuilderType.file:
          seekerAnswer = undefined;
          content = <img src={logo} alt="Logo" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />;
          break;
        case formBuilderType.signature:
          seekerAnswer = undefined;
          content = <SignatureIcon />;
          break;
        case formBuilderType.documentReview:
          seekerAnswer = question?.documentReview?.acknowledgeContent?.[language];
          break;
        default:
          break;
      }
      return <AnswerPreview answer={seekerAnswer!} content={content} />;
    }
    const componentLabel = (formBuilderSchemas[question?.inputType!]?.defaultValue as FieldSchema)?.label?.[language];
    const text = question?.text?.[language];

    return (
      <Stack spacing={1} sx={{ span: { fontWeight: 'bold' } }}>
        <Box
          style={{
            paddingLeft: isFollowUp ? '10px' : '0px',
            marginLeft: isFollowUp ? '10px' : '0px',
            borderLeft: isFollowUp ? '1px solid gray' : 'none',
            marginBottom: isLastFollowUp ? '20px' : 0,
          }}
        >
          <Stack>
            <Box
              display="flex"
              flexDirection="column"
              backgroundColor="#455A641A"
              color="#36464E"
              paddingX={'7.6px'}
              paddingY={'7.6px'}
              borderRadius="15.2px"
              width="fit-content"
              maxW="80%"
              minW="80%"
            >
              <Box>
                <Text
                  as="div"
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="400"
                  color={text?.length ? 'black' : theme.colors.gray[400]}
                  dangerouslySetInnerHTML={{ __html: text || componentLabel || '' }}
                  sx={{ li: { marginLeft: '14px' } }}
                />
              </Box>
              <Box marginTop={question?.optionList?.length ? '10px' : '0px'}>{renderOptions()}</Box>
            </Box>
            {renderAnswer()}
          </Stack>
        </Box>
        {followupQuestions.length > 0 &&
          followupQuestions.map((followupQuestion, index) => (
            <PreviewElementWrapper
              key={index}
              question={followupQuestion}
              isFollowUp={true}
              isLastFollowUp={index === followupQuestions.length - 1}
            />
          ))}
      </Stack>
    );
  },
  (prev, next) => isEqual(prev.question, next.question),
);

function AnswerPreview({ answer, content }: { answer: string; content?: React.ReactNode }) {
  if (!answer && !content) {
    return null;
  }
  return (
    <Box display="flex" justifyContent="flex-end">
      <Box
        backgroundColor="#e9e9e9"
        color="#4D4D4D"
        paddingX={'7.6px'}
        paddingY={'7.6px'}
        borderRadius="15.2px"
        width="fit-content"
        maxW="80%"
      >
        {answer && (
          <Text
            as="div"
            fontSize="14px"
            lineHeight="21px"
            fontWeight="400"
            dangerouslySetInnerHTML={{ __html: answer ?? '' }}
            sx={{ '*': { lineHeight: 'inherit', fontFamily: 'inherit' } }}
          />
        )}
        {content}
      </Box>
      <Box marginTop="10px" marginLeft="5px">
        <Center backgroundColor="#FF6600" color="white" borderRadius="50%" height="15px" width="15px">
          <Text fontSize="9px" fontWeight="400" lineHeight="9px">
            JD
          </Text>
        </Center>
      </Box>
    </Box>
  );
}
function YesNoPreview({ options }: { options: OptionSchema[] }) {
  const { language } = useHireFormContext();
  return (
    <HStack margin={0}>
      {options.map((option) => (
        <ButtonPreview key={option.id} text={option?.text?.[language]} />
      ))}
    </HStack>
  );
}

function CheckBoxPreview({ options }: { options: OptionSchema[] }) {
  const { language } = useHireFormContext();

  return (
    <Stack marginTop="5px" spacing={1}>
      {options?.map((option, index) => (
        <HStack key={index}>
          <MdOutlineCheckBoxOutlineBlank color="blue" fontSize="sm" style={{ flexShrink: 0 }} />

          <Text
            fontSize="14px"
            lineHeight="21px"
            fontWeight="400"
            dangerouslySetInnerHTML={{ __html: option?.text?.[language] ?? '' }}
          />
        </HStack>
      ))}
    </Stack>
  );
}

function RadioPreview({ options }: { options: OptionSchema[] }) {
  const { language } = useHireFormContext();

  return (
    <OptionsWrapper>
      {options?.map((option) => (
        <ButtonPreview key={option.id} text={option?.text?.[language]} />
      ))}
    </OptionsWrapper>
  );
}

function ImageFilePreview() {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <OptionsWrapper>
      <ButtonPreview text={t('formBuilder.chooseAFile')} />
      <ButtonPreview text={t('formBuilder.openCamera')} />
    </OptionsWrapper>
  );
}

function DatePreview() {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <OptionsWrapper>
      <ButtonPreview text={t('formBuilder.preview.mobilePreview.selectDate')} />
    </OptionsWrapper>
  );
}
function TimePreview() {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <OptionsWrapper>
      <ButtonPreview text={t('formBuilder.preview.mobilePreview.selectTime')} />
    </OptionsWrapper>
  );
}

function DocumentPreview() {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <OptionsWrapper>
      <ButtonPreview text={t('formBuilder.preview.mobilePreview.reviewDocument')} />
    </OptionsWrapper>
  );
}
function SignaturePreview() {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <OptionsWrapper>
      <ButtonPreview text={t('formBuilder.preview.mobilePreview.signHere')} />
    </OptionsWrapper>
  );
}
function BankInputPreview() {
  const { t } = useTranslation('hrFormTemplate');
  return (
    <OptionsWrapper>
      <ButtonPreview text={t('formBuilder.preview.mobilePreview.showMe')} />
    </OptionsWrapper>
  );
}

function AddressPreview() {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <OptionsWrapper>
      <ButtonPreview text={t('formBuilder.preview.mobilePreview.findAddress')} />
    </OptionsWrapper>
  );
}

function OptionsWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Center>
      <Stack marginTop="5px" spacing={2} align="center" width="fit-content">
        {children}
      </Stack>
    </Center>
  );
}
function ButtonPreview({ text = '' }) {
  return (
    <Box
      border="1px solid"
      borderColor={theme.colors.blue[400]}
      borderRadius={theme.radii['3xl']}
      paddingX={3}
      paddingY={1}
      flex={1}
      textAlign="center"
      width="-webkit-fill-available"
    >
      <Text
        fontSize={theme.fontSizes.sm}
        color={theme.colors.blue[400]}
        fontWeight={'bold'}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Box>
  );
}

export default PreviewElementWrapper;
