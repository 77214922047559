/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Button, Flex, Heading, useToast, useDisclosure, FormControl, FormLabel, Switch } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import _findIndex from 'lodash/findIndex';
import { Column } from 'react-table';
import { IoAdd } from 'react-icons/io5';
import {
  LanguageEvaluationTemplateDataType,
  LanguageEvaluationTemplateResponseType,
  LanguageEvaluationType,
} from '../LanguageEvaluationTypes';
import { LanguageEvaluationPhrases } from '../../../../firebase/firestore/documents/languageEvaluationPhrases';
import { clearErrorMessage, FormattedError } from '../../../../utils/FormErrorUtils';
import LanguageEvaluationTemplateTableView from './LanguageEvaluationTemplateTableView';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { TemplatePagination } from './TemplatePagination';
import useLanguagePhrasesByAccountId from '../../../../app/hooks/useLanguagePhrasesByAccountId';
import { LanguageEvaluationCreateModal } from './LanguageEvaluationCreateModal';
import theme from '../../../../styles/customTheme';

export const LanguageEvaluationTemplateView = (): JSX.Element => {
  const { t } = useTranslation('administration');
  const toast = useToast();
  const accountId = useStoreState((state) => state.app.accounts?.id);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dataTableContainer = useRef<HTMLDivElement | null>(null);
  const { getLanguageEvaluationTemplates, copyLanguageEvaluationTemplate, getLanguageEvaluationTemplateDetail } = useStoreActions(
    (actions) => actions.languageEvaluationTemplate,
  );

  const initialTemplateData = {
    templateName: '',
    languageEvaluationTemplates: [],
  };

  const languageEvaluationPhrases = useLanguagePhrasesByAccountId(accountId ?? '');

  const [showDefault, setShowDefault] = useState<boolean>(false);
  const [currentTemplates, setCurrentTemplates] = useState<LanguageEvaluationTemplateDataType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasNextValue, setNextValue] = useState<boolean | undefined>(false);
  const [hasPrevValue, setPrevValue] = useState<boolean | undefined>(false);
  const [firstId, setFirstId] = useState<string | undefined>(undefined);
  const [lastId, setLastId] = useState<string | undefined>(undefined);
  const [totalCount, setTotalCount] = useState<number>();
  const [dataCount, setDataCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [templateData, setTemplateData] = useState<LanguageEvaluationType>(initialTemplateData);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [templateToEdit, setTemplateToEdit] = useState<LanguageEvaluationTemplateResponseType | null>(null);
  const [templateToView, setTemplateToView] = useState<LanguageEvaluationTemplateResponseType | null>(null);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(false);

  const templateHeaderFromTranslation = {
    name: t('languageEvaluation.templateModal.templateName'),
    created: t('hrFormTemplate:templateListView.tableHeaders.createdDate'),
    action: t('languageEvaluation.action'),
  };

  const templateListTableColumns: Column<LanguageEvaluationTemplateDataType>[] = [
    {
      Header: templateHeaderFromTranslation.name,
      accessor: 'templateName',
    },
    {
      Header: templateHeaderFromTranslation.created,
      accessor: 'createdAt',
    },
    {
      Header: templateHeaderFromTranslation.action,
      accessor: 'actionType',
      disableSortBy: true,
    },
  ];

  // function that fetches LE templates
  const onGetLETemplates = useCallback(
    // eslint-disable-next-line consistent-return
    async (pageParams?: { after?: string; before?: string }) => {
      if (accountId) {
        const params: { after?: string; before?: string; default?: boolean; limit?: number } = {
          limit: 10,
        };

        if (pageParams?.after) {
          params.after = pageParams.after;
        }

        if (pageParams?.before) {
          params.before = pageParams.before;
        }

        if (showDefault) {
          params.default = true;
        }

        const response = await getLanguageEvaluationTemplates({ accountId, params });
        const sortedTemplates = response?.languageEvaluationTemplates;

        setNextValue(response.hasNext);
        setPrevValue(response.hasPrevious);
        setFirstId(response?.firstId);
        setLastId(response?.lastId);
        setCurrentTemplates(sortedTemplates);
        setTotalCount(response.count);

        setIsLoading(false);
        return response;
      }
    },
    [accountId, getLanguageEvaluationTemplates, showDefault],
  );

  useEffect(() => {
    onGetLETemplates().catch((err) => console.log(err));
  }, [onGetLETemplates]);

  useEffect(() => {
    // istanbul ignore next
    if (!isFetching) {
      if (showDefault) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onGetLETemplates().then((response) => {
          setDataCount(response?.languageEvaluationTemplates?.length);
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onGetLETemplates().then((response) => {
          setDataCount(response?.languageEvaluationTemplates?.length);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDefault]);

  useEffect(() => {
    setDataCount((prevCount) => (prevCount === 0 ? currentTemplates.length : prevCount));
  }, [currentTemplates]);

  const handleLanguageEvaluationPhrase = (checked: boolean, language: string, phrase: LanguageEvaluationPhrases) => {
    const formatPhrase = {
      language,
      phrase: phrase.description || '',
      phraseId: phrase.phraseId || '',
      id: phrase.id || '',
    };

    if (checked) {
      templateData.languageEvaluationTemplates?.push(formatPhrase);
      if (templateData.languageEvaluationTemplates?.length) {
        setFormErrors(clearErrorMessage(formErrors, 'languageEvaluationTemplates'));
      }
    } else {
      const indexOfItem = _findIndex(templateData.languageEvaluationTemplates, { id: phrase.id });
      if (indexOfItem > -1) {
        templateData.languageEvaluationTemplates?.splice(indexOfItem, 1);
      }
    }

    setTemplateData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: templateData.languageEvaluationTemplates,
    }));
  };

  const handleLanguageChange = () => {
    setTemplateData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: [],
    }));
  };

  const handleNextPage = async () => {
    if (hasNextValue && lastId && !isFetching) {
      setIsFetching(true);
      setIsLoading(true);
      try {
        const res = await onGetLETemplates({ after: lastId });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-plus-operands
        setDataCount((prevCount) => prevCount + res?.languageEvaluationTemplates?.length);
      } finally {
        setIsLoading(false);
        setIsFetching(false);
      }
    }
  };

  const handlePreviousPage = async () => {
    if (hasPrevValue && firstId && !isFetching) {
      setIsFetching(true);
      setIsLoading(true);
      try {
        await onGetLETemplates({ before: firstId });
        setDataCount((prevCount) => prevCount - currentTemplates.length);
      } finally {
        setIsLoading(false);
        setIsFetching(false);
      }
    }
  };

  // copy template
  const handleCopyTemplate = async (data: LanguageEvaluationTemplateDataType) => {
    if (!data.id) return;

    await copyLanguageEvaluationTemplate({ templateId: data.id });
    toast({
      title: t('languageEvaluation.templateToastMessage.copyTemplateTitle'),
      description: t('languageEvaluation.templateToastMessage.copyTemplateSuccess', { templateName: data.templateName }),
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    await onGetLETemplates();
  };

  // get template detail by id
  const getEditTemplate = async (id: string) => {
    if (!id) return;

    if (accountId) {
      const res = await getLanguageEvaluationTemplateDetail({ accountId, templateId: id });
      setTemplateToEdit(res);
    }
    onOpen();
  };

  const handleViewTemplate = async (id: string) => {
    if (!id) return;

    if (accountId) {
      const res = await getLanguageEvaluationTemplateDetail({ accountId, templateId: id });
      setTemplateToView(res);
      setIsEditDisabled(true);
    }
    onOpen();
  };

  return (
    <Box height="100%" minHeight={0} backgroundColor={theme.colors.white}>
      <Flex p={4} backgroundColor="white" alignItems="center" justifyContent="space-between">
        <Box>
          <Heading as="h4" fontSize="lg" lineHeight="1.3">
            {t('languageEvaluation.templateHeader')}
          </Heading>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControl w="auto" display="flex" alignItems="center" mr={3}>
            <FormLabel htmlFor="showDefaultTemplate" mb="0">
              {t('languageEvaluation.showDefault')}
            </FormLabel>
            <Switch
              size="sm"
              id="showDefaultTemplate"
              data-testid="showDefaultTemplate"
              cursor="pointer"
              onChange={(e) => setShowDefault(e.target.checked)}
            />
          </FormControl>
          <Button
            colorScheme="blue"
            data-testid="createTemplateBtn"
            fontWeight="bold"
            borderRadius="4px"
            px={4}
            py={5}
            leftIcon={<IoAdd size={24} />}
            marginLeft="12px"
            onClick={onOpen}
          >
            {t('languageEvaluation.addNewTemplate')}
          </Button>
        </Box>
      </Flex>
      <Box ref={dataTableContainer} height="100%" overflowY="auto">
        <Box bg="#fff">
          <LanguageEvaluationTemplateTableView
            data={currentTemplates}
            columns={templateListTableColumns}
            handleCopy={handleCopyTemplate}
            handleEdit={getEditTemplate}
            handleView={handleViewTemplate}
            isLoading={isLoading}
          />

          <TemplatePagination
            hasNext={hasNextValue}
            hasPrevious={hasPrevValue}
            isFetching={isFetching}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            totalDataCount={totalCount}
            dataCount={dataCount}
            showingResultsTranslation={(variable: string) => t('languageEvaluation.showingResult', variable)}
          />
        </Box>
      </Box>

      {isOpen && (
        <LanguageEvaluationCreateModal
          isOpen={isOpen}
          onClose={onClose}
          templateToEdit={templateToEdit}
          setTemplateEdit={setTemplateToEdit}
          templateToView={templateToView}
          setTemplateView={setTemplateToView}
          getLETemplates={onGetLETemplates}
          templateData={templateData}
          setTemplateData={setTemplateData}
          languagePhrases={languageEvaluationPhrases}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          handleLanguageEvaluationPhrase={handleLanguageEvaluationPhrase}
          handleLanguageChange={handleLanguageChange}
          isDisabled={isEditDisabled}
          setIsDisabled={setIsEditDisabled}
        />
      )}
    </Box>
  );
};
