/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/core';
import { motion } from 'framer-motion';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { BiWalk } from 'react-icons/bi';
import { BsFillFileEarmarkPersonFill } from 'react-icons/bs';
import { FaGraduationCap } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { GiPathDistance } from 'react-icons/gi';
import { IoIosBicycle, IoMdCar } from 'react-icons/io';
import { MdExpandMore, MdTrain } from 'react-icons/md';
import { RiMailOpenFill, RiPhoneFill } from 'react-icons/ri';
import useEducationLevels from '../../../../../app/hooks/useEducationLevels';
import { Candidate, CandidateStatus, FitStatus, MatchedRequisition } from '../../../../../firebase/firestore/documents/candidate';
import { useSeekerResume } from '../../../../../firebase/storage/hooks/useSeekerResume';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import {
  isCandidateAlreadyDismissed,
  isCandidateAlreadyHired,
  isCandidateHireInReview,
  isCandidateReadOnly,
} from '../../../../../services/CandidateService';
import colors from '../../../../../styles/colors';
import { getTranslatedNumber } from '../../../../../utils/localizedNumber';
import { secondsToTime } from '../../../../../utils/positionUtils';
import { getGradientBackground } from '../../../../../utils/uiUtils';
import { Label } from '../../../../common';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import usePosition from '../../../sidebar/seekerList/usePosition';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { CandidateWithStatusView } from './CandidateWithStatusView';
import { profileViewWrapper } from './Profile.styles';
import { CandidateAvailabilities } from './components/CandidateAvailabilities';
import { CandidateNightAvailabilities } from './components/CandidateNightAvailabilities';
import CandidateResume, { SupportedFileTypes } from './components/CandidateResume';
import { Education } from './components/Education';
import { Experience } from './components/Experience';
import { SeekerAllCandidates } from './components/SeekerAllCandidates';
import useProfile from './useProfile';

const MotionStack = motion.custom(Stack);
const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.05 },
  },
  hidden: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};
const childrenVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -200 },
    },
  },
  hidden: {
    y: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export type ProfileProps = BoxProps & {
  hiredAndOfferedCandidates: Candidate[];
  isCandidateEligibleToInteract: boolean | undefined;
};

enum AvailabilityTypes {
  DAY = 'Day',
  NIGHT = 'Night',
}

export const Profile = ({
  className = '',
  padding = 2,
  hiredAndOfferedCandidates,
  isCandidateEligibleToInteract,
}: ProfileProps): JSX.Element | null => {
  const { t, i18n } = useTranslation();
  const [isProfileLoaded, setIsProfileLoaded] = React.useState<boolean>(true);
  const [isSeekerAllCandidateExpanded, setIsSeekerAllCandidateExpanded] = useState<boolean>(false);
  const [accordionIndex, setAccordionIndex] = useState<number[]>([0, 1, 2, 3]);
  // api call
  const { getVirtualResume } = useStoreActions((actions) => actions.profileManagement);

  const toast = useToast();
  const { selectedCandidate } = useSeekerContainerContext();
  const { onHireModalOpen, setReq } = useMessagingViewContext();
  const availabilityFitRef = useRef<null | HTMLDivElement>(null);

  const { profile, initialized } = useProfile();

  const [availabilityType, setAvailabilityType] = React.useState<AvailabilityTypes>(AvailabilityTypes.DAY);

  const educationLevels = useEducationLevels();
  const [bestMatchedRequisition, setBestMatchedRequisition] = useState<MatchedRequisition | undefined>(
    profile.availabilityMatchedRequisitions[0],
  );

  const firstElOfavailabilityMatchedRequisitions = profile.availabilityMatchedRequisitions[0];

  useMemo(() => {
    if (initialized && isProfileLoaded) {
      setIsProfileLoaded(false);
      if (profile.experiences.length <= 0) {
        const indexOfExpericences = accordionIndex.indexOf(1);
        // istanbul ignore else
        if (indexOfExpericences > 0) {
          accordionIndex.splice(indexOfExpericences, 1);
        }
      }
      if (profile.educations.length <= 0) {
        const indexOfEducations = accordionIndex.indexOf(2);
        // istanbul ignore else
        if (indexOfEducations > 0) {
          accordionIndex.splice(indexOfEducations, 1);
        }
      }
      setAccordionIndex(accordionIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    setAccordionIndex([0, 1, 2, 3]);
    setIsProfileLoaded(true);
  }, [selectedCandidate]);

  useEffect(() => {
    // istanbul ignore else
    if (firstElOfavailabilityMatchedRequisitions) setBestMatchedRequisition(firstElOfavailabilityMatchedRequisitions);
  }, [firstElOfavailabilityMatchedRequisitions]);

  const showOfferMadeButton = () => {
    const candidateStatus = selectedCandidate?.computedStatus?.status;

    return !(
      selectedCandidate &&
      (candidateStatus === CandidateStatus.OfferMade ||
        candidateStatus === CandidateStatus.Hired ||
        candidateStatus === CandidateStatus.OfferRejected ||
        isCandidateAlreadyHired(selectedCandidate) ||
        isCandidateAlreadyDismissed(selectedCandidate) ||
        isCandidateReadOnly(selectedCandidate) ||
        isCandidateHireInReview(selectedCandidate.computedStatus?.status))
    );
  };

  const requisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);
  const scheduleMatchEnabled = useStoreState((state) => state.app.accounts?.configuration?.scheduleMatchEnabled);
  const shouldEnableAppliedPositions = useStoreState((state) => state.app.accounts?.configuration?.shouldEnableAppliedPositions);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
  const isAccountResumeAllowed = useStoreState((state) => state.app.accounts?.configuration?.resumeEnabled);
  const isRequsitionEnabled = useStoreState((s) => s.app.accounts?.configuration?.requisitionEnabled);
  const seekerResumeLink = useSeekerResume(selectedCandidate?.seeker, selectedCandidate?.resume?.fileName);

  const positionDetail = usePosition(selectedCandidate?.position);

  const canShowResume = isAccountResumeAllowed && positionDetail?.resumeEnabled;

  const calculateAvailabilityFitBand = (availabilityFitBand: FitStatus | undefined) => {
    if (FitStatus.GOOD === availabilityFitBand) {
      return {
        icon: <AiFillPlusCircle style={{ color: 'green' }} />,
        message: t('profile.matchingRequisition.goodFit'),
      };
    }
    if (FitStatus.MEDIUM === availabilityFitBand) {
      return {
        icon: <AiFillPlusCircle style={{ color: 'orange' }} />,
        message: t('profile.matchingRequisition.mediumFit'),
      };
    }
    return {
      icon: <AiFillMinusCircle style={{ color: 'red' }} />,
      message: t('profile.matchingRequisition.poorFit'),
    };
  };

  const getCommuteIcon = (mode: string) => {
    if (mode === 'BICYCLE' || mode === 'TWO_WHEELER') {
      return <IoIosBicycle style={{ color: '#4B5563' }} />;
    }
    if (mode === 'DRIVE') {
      return <IoMdCar style={{ color: '#4B5563' }} />;
    }
    if (mode === 'TRANSIT') {
      return <MdTrain style={{ color: '#4B5563' }} />;
    }
    if (mode === 'WALK') {
      return <BiWalk style={{ color: '#4B5563' }} />;
    }
    return <GiPathDistance style={{ color: '#4B5563' }} />;
  };

  const getCommuteDistance = (distance: number) => {
    const splitByComma = selectedCandidate?.businessCompleteAddress?.split(',');
    if (splitByComma) {
      const country = splitByComma[splitByComma.length - 1].trim();
      if (country.toLowerCase() === 'usa' || country.toString() === 'us') {
        return `(${distance.toLocaleString(i18n.language, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })} mi)`;
      }
      return `(${(distance * 1.60934).toLocaleString(i18n.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })} km)`;
    }

    return `(${(distance * 1.60934).toLocaleString(i18n.language, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })} km)`;
  };

  const isUserDeactivated = selectedCandidate && Boolean(selectedCandidate.deactivated || selectedCandidate.scheduledDeletion);

  const onHandleAccordion = (index: number, shouldClose: boolean) => {
    if (accordionIndex.includes(index) && shouldClose) {
      setAccordionIndex((prevArray) => prevArray.filter((item) => item !== index));
    } else {
      setAccordionIndex((prevArray) => [...prevArray, index]);
    }
  };

  // istanbul ignore next
  const handleDownload = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Prevent accordion toggle when download button is clicked
    event.stopPropagation();
    try {
      const response = await getVirtualResume({ seekerId: selectedCandidate?.seeker });
      // 1. Decode the Base64 string:
      const byteCharacters = atob(response.attachment);

      // 2. Create a Uint8Array (binary data):
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      const blob = new Blob(byteArrays, { type: 'application/pdf' });

      // 3. Create a download link:
      const link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', `virtualResume.pdf`);

      // 4. Trigger the download:
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast({
        title: t(`profile.errorResumeDownload`),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <MotionStack
      data-testid="Profile"
      initial="hidden"
      animate="visible"
      variants={parentVariants}
      spacing={0}
      padding={padding}
      className={['user-selectable', className].join(' ')}
      css={profileViewWrapper}
    >
      {profile.hasContactInformation && (
        <MotionStack variants={childrenVariants} spacing={4} data-testid="Profile_Contact">
          <Flex alignItems="center" flexWrap="wrap" pb={3} mb={3} borderBottom="1px solid" borderColor="gray.50">
            {profile.email && (
              <Flex alignItems="center" flex="auto">
                <RiMailOpenFill fontSize="18px" color={colors.blue['500']} />
                <Text pl={1} color="gray.500" fontSize=".95rem">
                  <a href={`mailto:${profile?.email}`} target="_blank" rel="noopener noreferrer">
                    {profile.email}
                  </a>
                </Text>
              </Flex>
            )}
            {profile.phone && (
              <Flex alignItems="center" flex="auto">
                <RiPhoneFill fontSize="18px" color={colors.blue['500']} />
                <Text pl={1} color="gray.500" fontSize=".95rem">
                  <a href={`tel:${profile.phone}`}>{profile.formattedPhone}</a>
                </Text>
              </Flex>
            )}
          </Flex>
        </MotionStack>
      )}
      <Box pb={3} mb={3} borderBottom="1px solid" borderColor="gray.50">
        {((profile.hasInsights && (profile?.insights?.flexibleTransportation || profile?.insights?.highSalaryDemand)) ||
          (!_isNil(profile?.availabilityFitBand) /* istanbul ignore next */ && isRequsitionEnabled) ||
          !_isNil(profile?.availabilityMatchedPositions) ||
          hiredAndOfferedCandidates.length > 0) && (
          <Text fontWeight="semibold" pb={1} fontSize=".95rem">
            {t('profile.appyPros.title')}
          </Text>
        )}

        {((profile.hasInsights && (profile?.insights?.flexibleTransportation || profile?.insights?.highSalaryDemand)) ||
          profile.isStudent ||
          profile.isAdult ||
          (!_isNil(profile?.availabilityFitBand) /* istanbul ignore next */ && isRequsitionEnabled) ||
          !_isNil(profile?.availabilityMatchedPositions)) && (
          <Stack spacing={1}>
            {profile?.insights?.flexibleTransportation && (
              <Box size="sm" color="gray.500">
                <Box display="flex" alignItems="center">
                  <Tooltip hasArrow label={t('profile.appyPros.positivePoint')}>
                    <span>
                      <AiFillPlusCircle style={{ color: 'green' }} />
                    </span>
                  </Tooltip>
                  <Text style={{ marginLeft: '0.5rem' }} fontSize="sm" color="gray.500">
                    {t('profile.appyPros.flexibleTransportation')}
                  </Text>
                </Box>
              </Box>
            )}
            {profile?.isStudent && (
              <Box size="sm" color="gray.500">
                <Box display="flex" alignItems="center">
                  <Tooltip hasArrow label={t('profile.isStudentInfo')}>
                    <span>
                      <FaGraduationCap style={{ color: '#4B5563' }} />
                    </span>
                  </Tooltip>
                  <Text style={{ marginLeft: '0.5rem' }} fontSize="sm" color="gray.500">
                    {t('profile.isAStudent')}
                  </Text>
                </Box>
              </Box>
            )}
            {profile?.isAdult && (
              <Box size="sm" color="gray.500">
                <Box display="flex" alignItems="center">
                  <Tooltip hasArrow label={t('profile.adultInfo')}>
                    <span>
                      <BsFillFileEarmarkPersonFill style={{ color: '#4B5563' }} />
                    </span>
                  </Tooltip>
                  <Text style={{ marginLeft: '0.5rem' }} fontSize="sm" color="gray.500">
                    {t('profile.adult')}
                  </Text>
                </Box>
              </Box>
            )}
            {profile?.commuteData && (
              <Box size="sm" color="gray.500">
                <Box display="flex" alignItems="center">
                  <span>{getCommuteIcon(profile.commuteData.travelMode.toUpperCase())}</span>

                  <Text style={{ marginLeft: '0.5rem' }} fontSize="sm" color="gray.500">
                    {`${secondsToTime(profile.commuteData.durationInSeconds)}`}
                  </Text>
                  <Text style={{ marginLeft: '0.5rem' }} fontSize="sm" color="gray.500">
                    {getCommuteDistance(profile.commuteData.distanceInMiles)}
                  </Text>
                </Box>
              </Box>
            )}
            {profile?.insights?.highSalaryDemand && (
              <Box size="sm" color="gray.500">
                <Box display="flex" alignItems="center">
                  <Tooltip hasArrow label={t('profile.appyNeg.negativePoint')}>
                    <span>
                      <AiFillMinusCircle style={{ color: 'red' }} />
                    </span>
                  </Tooltip>
                  <Text style={{ marginLeft: '0.5rem' }} fontSize="sm" color="gray.500">
                    {t('profile.appyNeg.highSalaryDemand')}
                    {profile.preferredSalary &&
                      ` - $${getTranslatedNumber(profile?.preferredSalary?.value / 100, i18n.language)} `}
                    {profile.preferredSalary && t(`messaging:actions.hire.confirmationModal.${profile.preferredSalary?.unit}`)}
                  </Text>
                </Box>
              </Box>
            )}

            {!_isNil(profile.availabilityFitBand) /* istanbul ignore next */ && isRequsitionEnabled && (
              <Box>
                <Box display="flex" alignItems="center">
                  <Tooltip hasArrow label={`${calculateAvailabilityFitBand(profile?.availabilityFitBand).message}`}>
                    <span>{calculateAvailabilityFitBand(profile?.availabilityFitBand).icon}</span>
                  </Tooltip>
                  <Text
                    fontSize="sm"
                    color="gray.500"
                    style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                    onClick={() => {
                      onHandleAccordion(3, false);
                      availabilityFitRef?.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    data-testid="availabilityFitBandLabel"
                  >
                    {t('profile.matchingRequisition.availabilityFitBand')}
                    <Box as="span" color="blue.500" pl={1}>
                      {calculateAvailabilityFitBand(profile?.availabilityFitBand).message}
                    </Box>
                  </Text>
                </Box>
              </Box>
            )}

            {!_isNil(profile.availabilityMatchedPositions) && (
              <Box>
                <Box display="flex" alignItems="center">
                  <Tooltip hasArrow label={`${calculateAvailabilityFitBand(profile?.availabilityMatchedPositions).message}`}>
                    <span>{calculateAvailabilityFitBand(profile?.availabilityMatchedPositions).icon}</span>
                  </Tooltip>
                  <Text
                    fontSize="sm"
                    color="gray.500"
                    style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                    onClick={() => {
                      onHandleAccordion(3, false);
                      availabilityFitRef?.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    data-testid="availabilityFitBandLabelPosition"
                  >
                    {t('profile.matchingRequisition.availabilityFitBand')}
                    <Box as="span" color="blue.500" pl={1}>
                      {calculateAvailabilityFitBand(profile?.availabilityMatchedPositions).message}
                    </Box>
                  </Text>
                </Box>
              </Box>
            )}
          </Stack>
        )}
      </Box>
      <Box maxHeight="185px" overflowY="auto">
        {hiredAndOfferedCandidates.length > 0 && (
          <Box mb={2}>
            {hiredAndOfferedCandidates.map((candidate) => (
              <CandidateWithStatusView key={candidate.id} candidate={candidate} />
            ))}
          </Box>
        )}
      </Box>
      <Flex
        className="notice"
        justifyContent="space-between"
        pb={profile.noticePeriod || profile.earliestAvailabilityDate ? 3 : 0}
        mb={profile.noticePeriod || profile.earliestAvailabilityDate ? 3 : 0}
        borderBottom={profile.noticePeriod || profile.earliestAvailabilityDate ? '1px' : '0'}
        borderStyle="solid"
        borderColor="gray.50"
      >
        {!_isNil(profile.noticePeriod) && !_isNil(profile.noticePeriod.value) && profile.noticePeriod.value !== '' && (
          <Box>
            <Text fontWeight="semibold" fontSize=".95rem">
              {t('common:noticePeriod')}
            </Text>
            <Text color="gray.500" fontSize="14px">
              {String(profile.noticePeriod.value) === '0'
                ? t('common:immediately')
                : `${profile.noticePeriod.value}${
                    profile?.noticePeriod?.unit
                      ? ` ${t(`common:profile.${profile.noticePeriod.unit}`)}`
                      : /* istanbul ignore next */ ''
                  }`}
            </Text>
          </Box>
        )}
        {profile.earliestAvailabilityDate && (
          <Box>
            <Text fontWeight="semibold" fontSize=".95rem">
              {t('common:earliestAvailabilityDate')}
            </Text>
            <Text color="gray.500" fontSize="14px">
              {moment(profile.earliestAvailabilityDate).locale(i18n.language).format('ll')}
            </Text>
          </Box>
        )}
      </Flex>

      <Accordion allowToggle data-testid="Profile_Resume" index={accordionIndex}>
        <AccordionItem style={{ border: 0 }} data-testid="resumeSection" isExpanded display={canShowResume ? 'block' : 'none'}>
          {({ isExpanded }) => (
            <Box>
              <AccordionButton
                bgColor="#FFF"
                py={2}
                px={1}
                _hover={{ outline: 'none', boxShadow: 'none' }}
                _active={{ outline: 'none', boxShadow: 'none' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                pl={0}
                onClick={() => onHandleAccordion(0, true)}
                data-testid="resumeBtn"
              >
                <Flex direction="row" justifyContent="space-between" alignItems="center" w="100%">
                  <Text fontWeight="semibold" pb={1} fontSize=".95rem">
                    {t('profile.resume')}
                  </Text>
                  {!seekerResumeLink && (
                    <Button
                      onClick={handleDownload}
                      mr={3}
                      background={colors.blue[400]}
                      color="white"
                      aria-label="First"
                      borderRadius="6px"
                      borderColor="#718096"
                      data-testid="downloadBtn"
                      size="sm"
                      cursor="pointer"
                    >
                      {t(`profile.download`)}
                      <FiDownload style={{ marginLeft: '8px' }} />
                    </Button>
                  )}
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={3} px={0} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                {isExpanded && (
                  <Box>
                    {(selectedCandidate?.resume?.createdAt || selectedCandidate?.resume?.updatedAt) && (
                      <Flex color="gray.500" ml={5}>
                        <Text fontWeight="600" fontSize="14px" mr={1}>
                          {t('profile.lastUpdatedResume')}
                        </Text>
                        <Text fontSize="14px">
                          {moment(selectedCandidate?.resume?.updatedAt || selectedCandidate?.resume?.createdAt).format(
                            'MMM D, YYYY h:mm A',
                          )}
                        </Text>
                      </Flex>
                    )}
                    <Box>
                      {seekerResumeLink && selectedCandidate?.resume?.mimeType ? (
                        <Box data-testid="candidateResume">
                          <CandidateResume
                            url={seekerResumeLink}
                            type={selectedCandidate?.resume?.mimeType as SupportedFileTypes}
                          />
                        </Box>
                      ) : (
                        <Text
                          size="sm"
                          color={colors.gray[500]}
                          colorDarkMode={colors.gray['600']}
                          data-testid="userNotUploadedResume"
                        >
                          {t('profile.noResumeFound')}
                        </Text>
                      )}
                    </Box>
                  </Box>
                )}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>

        <AccordionItem isExpanded style={{ border: 0 }}>
          {({ isExpanded }) => (
            <Box>
              <AccordionButton
                bgColor="#FFF"
                py={2}
                px={1}
                _hover={{ outline: 'none', boxShadow: 'none' }}
                _active={{ outline: 'none', boxShadow: 'none' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                pl={0}
                onClick={() => onHandleAccordion(1, true)}
                data-testid="experienceBtn"
              >
                <Box flex="1" textAlign="left">
                  <Text fontWeight="semibold" pb={1} fontSize=".95rem">
                    {t('profile.experience.title')}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={3} px={0} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                {isExpanded && (
                  <Box border="1px solid" borderColor="gray.50">
                    {!initialized && (
                      <Stack textAlign="center">
                        <Center>
                          <Spinner color={colors.blue.default} data-testid="Spinner" />
                        </Center>
                        <Text color={colors.gray[400]} fontSize="xs">
                          {t('profile.experience.fetching')}
                        </Text>
                      </Stack>
                    )}
                    {initialized &&
                      profile.experiences.length > 0 &&
                      profile.experiences.map((experience, index) => (
                        <Experience
                          key={experience.id}
                          experience={experience}
                          totalItems={profile.experiences.length}
                          dataIndex={index}
                          displayBusiness={selectedCandidate?.monetized}
                        />
                      ))}
                    {initialized && profile.experiences.length === 0 && (
                      <Label p={4} size="sm" color={colors.gray[500]} colorDarkMode={colors.gray['600']}>
                        {t('profile.experience.none')}
                      </Label>
                    )}
                  </Box>
                )}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
        <AccordionItem isExpanded style={{ border: 0 }}>
          {({ isExpanded }) => (
            <Box>
              <AccordionButton
                bgColor="#FFF"
                py={2}
                px={1}
                _hover={{ outline: 'none', boxShadow: 'none' }}
                _active={{ outline: 'none', boxShadow: 'none' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                pl={0}
                onClick={() => onHandleAccordion(2, true)}
                data-testid="educationBtn"
              >
                <Box flex="1" textAlign="left">
                  <Text fontWeight="semibold" pb={1} fontSize=".95rem">
                    {t('profile.education.title')}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={3} px={0} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                {isExpanded && (
                  <Box border="1px solid" borderColor="gray.50">
                    {!initialized && (
                      <Stack textAlign="center">
                        <Center>
                          <Spinner color={colors.blue.default} data-testid="Spinner" />
                        </Center>
                        <Text color={colors.gray[400]} fontSize="xs">
                          {t('profile.education.fetching')}
                        </Text>
                      </Stack>
                    )}
                    {initialized &&
                      profile.educations.length > 0 &&
                      profile.educations.map((education, index) => (
                        <Education
                          key={education.id}
                          dataIndex={index}
                          education={education}
                          totalItems={profile.educations.length}
                          educationLevels={educationLevels}
                        />
                      ))}
                    {initialized && profile.educations.length === 0 && (
                      <Label p={4} size="sm" color={colors.gray[500]} colorDarkMode={colors.gray['600']}>
                        {t('profile.education.none')}
                      </Label>
                    )}
                  </Box>
                )}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
        {!_isNil(profile.expectedWorkHour) && !_isNil(profile.expectedWorkHour.value) && profile.expectedWorkHour.value !== '' && (
          <Box>
            <Text fontWeight="semibold" fontSize=".95rem">
              {t('common:expectedWork')}
            </Text>
            <Text color="gray.500" fontSize="14px">
              {String(profile.expectedWorkHour.value) === '0'
                ? t('common:immediately')
                : `${profile.expectedWorkHour.value} ${t('common:hrsWeek')}`}
            </Text>
          </Box>
        )}

        <AccordionItem isExpanded style={{ border: 0 }}>
          {({ isExpanded }) => (
            <Box>
              <AccordionButton
                bgColor="#FFF"
                py={2}
                px={1}
                _hover={{ outline: 'none', boxShadow: 'none' }}
                _active={{ outline: 'none', boxShadow: 'none' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                pl={0}
                onClick={() => onHandleAccordion(3, true)}
                data-testid="availabilityBtn"
              >
                <Box flex="1" textAlign="left">
                  <Text fontWeight="semibold" pb={1} fontSize=".95rem">
                    {t('profile.availability.title')}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              {isExpanded && (
                <Box>
                  {initialized &&
                  (profile?.candidateAvailabilities || profile?.seeker?.nightAvailabilities) &&
                  (!_isEmpty(profile?.candidateAvailabilities) || !_isEmpty(profile?.seeker?.nightAvailabilities)) ? (
                    <AccordionPanel pb={0} px={0} mb={1} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                      {profile.availabilityUpdatedAt && (
                        <Box fontSize="sm" color="gray.500" mb={2}>
                          {`${t('profile.updatedAt')} ${moment(profile.availabilityUpdatedAt)
                            .locale(i18n.language)
                            .format('lll')}`}
                        </Box>
                      )}
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        border="1px solid #E5E7EB"
                        borderRadius="4px"
                        mb={2}
                        p={1}
                        position="relative"
                        width="100%"
                        height="42px"
                        background={getGradientBackground(availabilityType)}
                        overflow="hidden"
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          fontWeight="700"
                          cursor="pointer"
                          flex="1"
                          mr="50px"
                          mt="8px"
                          onClick={() => setAvailabilityType(AvailabilityTypes.DAY)}
                          data-testid="dayBtn"
                          color={availabilityType === AvailabilityTypes.DAY ? 'blue.500' : 'gray.800'}
                        >
                          <Text fontSize="14px" fontWeight={700}>
                            {t('availability.day')}
                          </Text>
                        </Flex>
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          fontWeight="700"
                          cursor="pointer"
                          flex="1"
                          ml="50px"
                          mb="8px"
                          onClick={() => setAvailabilityType(AvailabilityTypes.NIGHT)}
                          data-testid="nightBtn"
                          color={availabilityType === AvailabilityTypes.NIGHT ? 'blue.500' : 'gray.800'}
                        >
                          <Text fontSize="14px" fontWeight={700}>
                            {t('availability.night')}
                          </Text>
                        </Flex>
                      </Flex>

                      {/* eslint-disable no-nested-ternary */}
                      {availabilityType === AvailabilityTypes.DAY && profile.candidateAvailabilities ? (
                        <CandidateAvailabilities candidateAvailabilities={profile.candidateAvailabilities} />
                      ) : availabilityType === AvailabilityTypes.NIGHT && profile.seeker?.nightAvailabilities ? (
                        <CandidateNightAvailabilities nightAvailabilities={profile?.seeker?.nightAvailabilities} />
                      ) : (
                        /* istanbul ignore next */
                        <Label
                          p={4}
                          size="sm"
                          color={colors.gray[500]}
                          colorDarkMode={colors.gray['600']}
                          border="1px solid #eceff1"
                        >
                          ---
                        </Label>
                      )}

                      {profile?.availabilityMatchedRequisitions.length > 0 && (
                        <Box ref={availabilityFitRef} mt={4} mb={3}>
                          <Heading as="h4" fontSize="md">
                            {t('profile.matchingRequisition.title')}
                          </Heading>
                          <Flex justifyContent="space-between" alignItems="center">
                            <Box w="70%">
                              {profile?.availabilityMatchedRequisitions.length > 0 && (
                                <Menu>
                                  <MenuButton
                                    size="md"
                                    isAttached
                                    data-testid="requisitionsDropdownMenu"
                                    width="100%"
                                    display="inline-block"
                                  >
                                    <Flex w="100%">
                                      <Button
                                        fontWeight="normal"
                                        borderTopRightRadius={0}
                                        borderBottomRightRadius={0}
                                        bg="#EDF2F7"
                                        textOverflow="ellipsis"
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        width="100%"
                                        justifyContent="flex-start"
                                      >
                                        <Text isTruncated>
                                          {
                                            /* istanbul ignore next */ !_isNil(bestMatchedRequisition)
                                              ? bestMatchedRequisition.requisitionId
                                              : profile.availabilityMatchedRequisitions[0].requisitionId
                                          }
                                        </Text>
                                      </Button>
                                      <Button
                                        aria-label="menu"
                                        bg="#EDF2F7"
                                        borderLeft="1px solid"
                                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftColor: '#E2E8F0' }}
                                        icon={<MdExpandMore />}
                                      />
                                    </Flex>
                                  </MenuButton>
                                  {!_isNil(selectedCandidate?.computedStatus?.status) &&
                                    showOfferMadeButton() &&
                                    requisitionEnabled && (
                                      <MenuList py={0} borderColor="gray.50">
                                        {profile.availabilityMatchedRequisitions.map((matchedRequisition, index) => (
                                          <Fragment key={matchedRequisition.requisitionId}>
                                            <MenuItem
                                              _hover={{ bg: 'gray.150' }}
                                              _active={{
                                                bg: 'gray.150',
                                              }}
                                              _focus={{
                                                bg: 'gray.150',
                                              }}
                                              onClick={() => setBestMatchedRequisition(matchedRequisition)}
                                              data-testid={`menuItem-${matchedRequisition.requisitionId}`}
                                            >
                                              {calculateAvailabilityFitBand(matchedRequisition.fit).icon}
                                              <Text ml="8px">{matchedRequisition.requisitionId}</Text>
                                            </MenuItem>
                                            {profile.availabilityMatchedRequisitions.length === index + 1 && (
                                              <MenuDivider m={0} />
                                            )}
                                          </Fragment>
                                        ))}
                                      </MenuList>
                                    )}
                                </Menu>
                              )}
                            </Box>
                            {!_isNil(selectedCandidate?.computedStatus?.status) &&
                              showOfferMadeButton() &&
                              requisitionEnabled &&
                              scheduleMatchEnabled && (
                                <Box w="28%">
                                  <Tooltip
                                    hasArrow
                                    shouldWrapChildren
                                    label={
                                      !isCandidateEligibleToInteract
                                        ? t('messaging:actions.disabledActionLabelNotLookingForWork')
                                        : ''
                                    }
                                  >
                                    <Button
                                      size="sm"
                                      colorScheme="blue"
                                      data-testid="offermadeReq"
                                      disabled={isUserDeactivated || !isCandidateEligibleToInteract}
                                      onClick={() => {
                                        setReq(
                                          bestMatchedRequisition?.requisitionId
                                            ? bestMatchedRequisition?.requisitionId
                                            : /* istanbul ignore next */ profile?.availabilityMatchedRequisitions[0]
                                                ?.requisitionId,
                                        );
                                        onHireModalOpen();
                                      }}
                                    >
                                      {t('profile.matchingRequisition.offerMade')}
                                    </Button>
                                  </Tooltip>
                                </Box>
                              )}
                          </Flex>
                        </Box>
                      )}
                    </AccordionPanel>
                  ) : (
                    <AccordionPanel pb={0} px={0} mb={3} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                      <Label
                        p={4}
                        size="sm"
                        color={colors.gray[500]}
                        colorDarkMode={colors.gray['600']}
                        border="1px solid #eceff1"
                      >
                        ---
                      </Label>
                    </AccordionPanel>
                  )}
                </Box>
              )}
            </Box>
          )}
        </AccordionItem>

        {shouldEnableAppliedPositions && (
          <AccordionItem isExpanded style={{ border: 0 }}>
            {({ isExpanded }) => (
              <Box>
                <AccordionButton
                  bgColor="#FFF"
                  py={2}
                  px={1}
                  _hover={{ outline: 'none', boxShadow: 'none' }}
                  _active={{ outline: 'none', boxShadow: 'none' }}
                  _focus={{ outline: 'none', boxShadow: 'none' }}
                  pl={0}
                  data-testid="appliedPositionBtn"
                  onClick={() => {
                    onHandleAccordion(4, true);
                    /* istanbul ignore next */
                    setIsSeekerAllCandidateExpanded(!isSeekerAllCandidateExpanded);
                  }}
                >
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="semibold" pb={1} fontSize=".95rem">
                      {t('profile.appliedPositions')}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={3} px={0} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                  {isExpanded && (
                    <Box border="1px solid" borderColor="gray.50">
                      {isSeekerAllCandidateExpanded && <SeekerAllCandidates />}
                    </Box>
                  )}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        )}
      </Accordion>
    </MotionStack>
  );
};
