import React from 'react';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  FormLabel,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Switch,
  Text,
} from '@chakra-ui/core';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import { useTable, useSortBy, Column } from 'react-table';
import { mainTable, tableHeading } from '../recruiterManagement/recruiterDashboard/recruitersInfoTable/RecruiterInfoTableStyle';
import moment from 'moment';
import { IoAdd, IoChevronBack, IoChevronForward, IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { HrFromTemplate } from './formElements/formBuilderSchema';
import { useHistory } from 'react-router-dom';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { PATH_ADMINISTRATION, PATH_HIRE_FORM_COPY, PATH_HIRE_FORM_EDIT } from '../../../routes/constants';
import theme from '../../../styles/customTheme';
import colors from '../../../styles/colors';
import { v4 as uuidv4 } from 'uuid';
import HireFormViewMode from './hireFormViewMode/HireFormViewMode';
import { MdOutlineUnfoldMore } from 'react-icons/md';
import HireFormLanguageSelectModal from './HireFormLanguageSelectModal';
import { TableStyle } from '../fitbandConfiguration/FitbandConfigurationTableView';
import { BsThreeDotsVertical } from 'react-icons/bs';

export default function HRFormTemplateView() {
  return <DataTable />;
}
const PAGE_SIZE = 10;
interface PaginationQueryType {
  page: number;
  limit: number | null;
  before?: string | null;
  after?: string | null;
  type: string | null;
  total: number;
}

const PAGINATION_LIMIT_KEY = 'hireFormTemplatesPaginationLimit';

const defaultPagination = {
  page: 1,
  limit: Number(localStorage.getItem(PAGINATION_LIMIT_KEY)) || PAGE_SIZE,
  before: null,
  after: null,
  type: null,
  total: 0,
};

function DataTable() {
  const [currentItems, setCurrentItems] = React.useState<HrFromTemplate[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { templates } = useStoreState((state) => state.hrFormTemplate);
  const history = useHistory();
  const { t } = useTranslation('hrFormTemplate');
  const [paginationQuery, setPaginationQuery] = React.useState<PaginationQueryType>(() => defaultPagination);

  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';
  const { getTemplates } = useStoreActions((state) => state.hrFormTemplate);
  const [showDefault, setShowDefault] = React.useState(false);

  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);
  const userId = useStoreState((s) => s.app.user?.id);
  const isSuperAdmin = (): boolean => {
    if (!superOwners || !userId) {
      return false;
    }
    return superOwners?.includes(userId);
  };

  const isHireFormTemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormTemplateEnabled) ?? false;
  React.useEffect(() => {
    if (!isHireFormTemplateEnabled) {
      history.replace(PATH_ADMINISTRATION);
    }
    // eslint-disable-next-line
  }, [isHireFormTemplateEnabled]);

  function handleGetTemplates(pagination: PaginationQueryType) {
    setIsLoading(true);
    getTemplates({ account: accountId, ...omit(pagination, ['page']) })
      .then((res) => {
        setPaginationQuery((prev) => ({ ...prev, after: res?.after, before: res?.before, total: res?.total }));
      })
      .finally(() => {
        return setIsLoading(false);
      });
  }

  React.useEffect(() => {
    const pagination = { ...paginationQuery, after: null, before: null, type: showDefault ? 'default' : null, page: 1 };
    setPaginationQuery((prev) => ({ ...prev, ...pagination }));
    handleGetTemplates(pagination);
    // eslint-disable-next-line
  }, [showDefault]);

  React.useEffect(() => {
    setCurrentItems(templates?.templates ?? []);
    setPaginationQuery((prev) => ({
      ...prev,
      before: templates?.before ?? null,
      after: templates?.after ?? null,
      total: templates?.total ?? 0,
    }));
    // eslint-disable-next-line
  }, [templates]);

  const columns = React.useMemo(
    () => {
      const columnsList = [
        {
          Header: t('templateListView.tableHeaders.name'),
          accessor: 'name',
        },
        {
          Header: t('templateListView.tableHeaders.createdDate'),
          accessor: 'createdAt',
        },
        {
          Header: t('templateListView.tableHeaders.editHistory'),
          accessor: 'updatedAt',
        },
      ] as Column<HrFromTemplate>[];

      if (isSuperAdmin()) {
        columnsList.push({
          Header: t('templateListView.tableHeaders.actions'),
          accessor: 'action',
          disableSortBy: true,
        } as Column<HrFromTemplate>);
      }
      return columnsList;
    },
    // eslint-disable-next-line
    [t],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { data: currentItems, columns },
    useSortBy,
  );

  function handleNextPage() {
    const pagination = { ...paginationQuery, before: null, after: paginationQuery.after, page: paginationQuery.page + 1 };
    setPaginationQuery((prev) => ({ ...prev, ...pagination }));
    handleGetTemplates(pagination);
  }

  function handlePreviousPage() {
    const pagination = {
      ...paginationQuery,
      before: paginationQuery.before,
      after: null,
      page: paginationQuery.page - 1,
    };
    setPaginationQuery((prev) => ({ ...prev, ...pagination }));
    handleGetTemplates(pagination);
  }

  function handleLimitChange(limit: number) {
    const pagination = { ...paginationQuery, before: null, after: null, page: 1, limit, type: null };
    setPaginationQuery((prev) => ({ ...prev, ...pagination }));
    handleGetTemplates(pagination);
  }

  return (
    <Box height="100%" minHeight={0} overflowY="auto" backgroundColor={theme.colors.white}>
      <HStack justify="space-between" paddingX={4} paddingY={3}>
        <Text fontSize="lg" fontWeight={700}>
          {t('templateListView.title')}
        </Text>
        <HStack spacing={4}>
          <HStack>
            <HStack>
              <FormLabel htmlFor="hr-form-templates-defaults" mb="0">
                {t('templateListView.showDefault')}
              </FormLabel>
              <Switch
                id="hr-form-templates-defaults"
                size="sm"
                onChange={() => setShowDefault(!showDefault)}
                isChecked={showDefault}
              />
            </HStack>
          </HStack>
          {isSuperAdmin() && (
            <HireFormLanguageSelectModal
              triggerRender={
                <Button colorScheme="blue" leftIcon={<IoAdd size={24} />} size="md">
                  <Text>{t('templateListView.addTemplate')}</Text>
                </Button>
              }
            />
          )}
        </HStack>
      </HStack>
      <Table {...getTableProps()} css={mainTable} className="mainTable simple-bordered-table">
        <Thead css={tableHeading} className="tableHeading">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...omit(column.getHeaderProps(column.getSortByToggleProps()), 'title')}
                  isNumeric={column.isNumeric}
                  align="left"
                >
                  <Box display="flex" width="fit-content">
                    {column.render('Header')}
                    {!column?.disableSortBy && (
                      <chakra.span pl="4">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <IoChevronDown aria-label="sorted descending" />
                          ) : (
                            <IoChevronUp aria-label="sorted ascending" />
                          )
                        ) : (
                          <MdOutlineUnfoldMore aria-label="unsorted" />
                        )}
                      </chakra.span>
                    )}
                  </Box>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {isLoading && (
            <Tr>
              <Td colSpan={4} textAlign="center">
                <Spinner size="sm" />
              </Td>
            </Tr>
          )}
          {rows.map((row) => {
            prepareRow(row);
            const dateFormat = 'DD/MM/YYYY';
            return (
              <Tr
                css={TableStyle}
                {...row.getRowProps()}
                style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'all' }}
              >
                {row.cells.map((cell) => {
                  switch (cell.column.id) {
                    case 'updatedAt':
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric} px={5} py={3}>
                          <HStack>
                            <Text fontSize={'sm'} color={'gray.500'} fontWeight={'400'}>
                              {moment(cell.value).format(dateFormat)}
                            </Text>
                          </HStack>
                        </Td>
                      );
                    case 'createdAt':
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric} px={5} py={3}>
                          {moment(cell.value).format(dateFormat)}
                        </Td>
                      );
                    case 'action':
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric} px={5} py={3}>
                          <TemplateTableActions template={cell.row.original} />
                        </Td>
                      );
                    case 'name':
                      return (
                        <Td {...cell.getCellProps()} px={5} py={3}>
                          <HireFormViewMode
                            templateId={cell.row.original.id}
                            templateName={cell.row.original.name}
                            templateType={cell.row.original.type}
                            trigger={
                              <Text
                                as="div"
                                display="flex"
                                whiteSpace="nowrap"
                                flexWrap="wrap"
                                _hover={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                                maxWidth="400px"
                                isTruncated
                                noOfLines={1}
                              >
                                {cell.render('Cell')}
                                {cell.row.original.type && (
                                  <Text fontStyle="italic" color={colors.gray[400]} textOverflow="ellipsis">
                                    , ({t('formBuilder.systemDefault')})
                                  </Text>
                                )}
                              </Text>
                            }
                          />
                        </Td>
                      );
                    default:
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric} px={5} py={3}>
                          {cell.render('Cell')}
                        </Td>
                      );
                  }
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Box backgroundColor={'white'} paddingX={4} paddingY={3}>
        <HStack justify="space-between">
          <Text fontSize="sm" color="gray.500">
            {t('templateListView.paginationText.showingResult', {
              currentItems: currentItems.length,
              totalItems: paginationQuery.total,
            })}
          </Text>
          <HStack spacing={4}>
            <PaginationLimit limit={paginationQuery.limit} onChange={handleLimitChange} />
            <ButtonGroup variant="outline" size="xs" color="gray" padding={0}>
              <Button
                disabled={!templates?.hasPrevious || isLoading}
                onClick={handlePreviousPage}
                isLoading={!!paginationQuery.before && isLoading}
              >
                <IoChevronBack />
              </Button>
              <Button
                disabled={!templates?.hasNext || isLoading}
                onClick={handleNextPage}
                isLoading={!!paginationQuery.after && isLoading}
              >
                <IoChevronForward />
              </Button>
            </ButtonGroup>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
}

interface PaginationLimitProps {
  limit: number | null;
  onChange: (limit: number) => void;
}

const PaginationLimit = ({ limit, onChange }: PaginationLimitProps) => {
  const [value, setValue] = React.useState<number>(() => {
    return limit ?? (Number(localStorage.getItem(PAGINATION_LIMIT_KEY)) || PAGE_SIZE);
  });

  React.useEffect(() => {
    if (limit && value !== limit) {
      setValue(limit);
      localStorage.setItem(PAGINATION_LIMIT_KEY, limit.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    onChange(Number(e.target.value));
  }

  return (
    <Select value={value} onChange={handleChange} size="sm" variant="filled">
      {[10, 25, 50, 100].map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </Select>
  );
};

const TemplateTableActions = React.memo(({ template }: { template: HrFromTemplate }) => {
  const history = useHistory();
  const [isDeleting] = React.useState(false);
  const { t } = useTranslation('hrFormTemplate');
  const isDefaultTemplate = template.type === 'default';
  return (
    <Menu closeOnSelect={false} key={uuidv4()}>
      <MenuButton
        disabled={isDeleting}
        height="32px"
        px={4}
        py={2}
        transition="all 0.2s"
        borderRadius="md"
        _hover={{ bg: 'gray.400' }}
        _focus={{ boxShadow: 'outline', backgroundColor: 'none' }}
        key={uuidv4()}
      >
        {isDeleting ? <Spinner size="sm" /> : <BsThreeDotsVertical />}
      </MenuButton>
      <MenuList key={uuidv4()}>
        <MenuItem onClick={() => history.push(PATH_HIRE_FORM_COPY.replace(':templateId', template.id))}>
          {t('templateListView.tableActions.copy')}
        </MenuItem>
        {!isDefaultTemplate && (
          <>
            <MenuItem onClick={() => history.push(PATH_HIRE_FORM_EDIT.replace(':templateId', template.id))}>
              {t('templateListView.tableActions.edit')}
            </MenuItem>
            {/* <DeleteTemplateAction template={template} isDeleting={isDeleting} setIsDeleting={setIsDeleting} /> */}
          </>
        )}
      </MenuList>
    </Menu>
  );
});

// const DeleteTemplateAction = React.memo(
//   ({
//     template,
//     isDeleting,
//     setIsDeleting,
//   }: {
//     template: HrFromTemplate;
//     isDeleting: boolean;
//     setIsDeleting: (val: boolean) => void;
//   }) => {
//     const { t } = useTranslation('hrFormTemplate');
//     const toast = useToast();
//     const { deleteTemplate, getTemplates } = useStoreActions((state) => state.hrFormTemplate);
//     const { currentUserProfile } = useCurrentUserProfile();
//     const accountId = currentUserProfile?.account ?? '';
//     const [open, setOpen] = React.useState(false);

//     async function handleOnDelete() {
//       try {
//         setIsDeleting(true);
//         await deleteTemplate({ account: accountId, id: template.id });

//         toast({
//           title: t('templateListView.tableActions.deleteSuccessMessage', { template: template.name }),
//           status: 'success',
//           isClosable: true,
//         });
//         await getTemplates({ account: accountId, ...omit(defaultPagination, ['page']) });
//       } catch (error) {
//         toast({
//           title: t('templateListView.tableActions.deleteErrorMessage', { template: template.name }),
//           status: 'error',
//           isClosable: true,
//         });
//       } finally {
//         setIsDeleting(false);
//       }
//     }

//     return (
//       <MenuItem
//         padding={0}
//         isDisabled={isDeleting}
//         icon={isDeleting ? <Spinner size="sm" color="red.500" /> : undefined}
//         onClick={(e) => {
//           e.preventDefault();
//           e.stopPropagation();
//           setOpen(true);
//         }}
//       >
//         <DeletePopover
//           open={open}
//           handleClose={() => setOpen(false)}
//           popoverTrigger={
//             <Box style={{ padding: '0.4rem 0.8rem' }} color={'red.500'} width="100%">
//               {t('templateListView.tableActions.delete')}
//             </Box>
//           }
//           onYes={handleOnDelete}
//         />
//       </MenuItem>
//     );
//   },
// );
