/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-default-export */
import React, { useRef } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import {
  Flex,
  Box,
  Text,
  ScaleFade,
  Alert,
  AlertIcon,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  chakra,
  MenuItem,
} from '@chakra-ui/core';
import { css } from '@emotion/core';
import { useTable, usePagination, Cell, Column, useSortBy } from 'react-table';
import moment from 'moment';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import omit from 'lodash/omit';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { MdOutlineUnfoldMore } from 'react-icons/md';
import colors from '../../../styles/colors';
import { FitbandTemplateType } from './FitbandConfigurationTypes';
import { mainTable, tableHeading } from '../recruiterManagement/recruiterDashboard/recruitersInfoTable/RecruiterInfoTableStyle';

export const TableStyle = css`
  cursor: pointer;
  &:hover, &:focus, &:active {
    background: #f7fafc;
  }
  &.activeRow {
    background: ${colors.gray[50]};
  }
  &:hover .rightArrow {
    opacity: 1;
    transform: translate(0, -50%);
   }
}
`;

export type TableProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
  isLoading?: boolean;
  handleCopy: (data: FitbandTemplateType) => void;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
};

export default function FitbandConfigurationTableView<Data extends Record<string, unknown>>({
  data,
  columns,
  isLoading,
  handleCopy,
  handleEdit,
  handleView,
}: TableProps<Data>) {
  const { t } = useTranslation('administration');
  const tableContainerRef = useRef<null | HTMLTableElement>(null);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  );

  const renderCell = (cellData: Cell<Data>) => {
    const columnData = (cellData.row.original as unknown) as FitbandTemplateType;

    switch (cellData.column.id) {
      case 'actionType':
        return (
          <Box width="25%">
            <Menu>
              <MenuButton
                height="32px"
                px={4}
                py={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="1px"
                border="none"
                _hover={{ bg: '#e2e8f0' }}
                data-testid={`MenuButton-${columnData.id}`}
              >
                <Box>
                  <BsThreeDotsVertical />
                </Box>
              </MenuButton>
              <MenuList minWidth="8rem" key={uuidv4()}>
                <MenuItem>
                  <Box onClick={/* istanbul ignore next */ () => handleCopy(columnData)}>
                    {t('fitbandConfiguration.duplicate')}
                  </Box>
                </MenuItem>
                {!columnData?.default && (
                  <MenuItem>
                    <Box onClick={/* istanbul ignore next */ () => handleEdit(columnData?.id)}>
                      {t('fitbandConfiguration.edit')}
                    </Box>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
        );
      case 'name':
        return (
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            onClick={/* istanbul ignore next */ () => handleView(columnData.id)}
            _hover={{ textDecoration: 'underline' }}
          >
            <Text fontSize="14px" data-testid="templateNameCell">
              {columnData.name}
            </Text>
            {columnData?.default && (
              <Text fontSize="14px" color={colors.gray[400]} fontStyle="italic" ml={2}>
                {t('fitbandConfiguration.systemDefault')}
              </Text>
            )}
          </Box>
        );
      case 'createdAt':
        return (
          <Box>
            <Text fontSize="14px">{moment(columnData.createdAt).format('DD/MM/YYYY')}</Text>
          </Box>
        );
      case 'modifiedAt':
        return (
          <Box>
            {columnData.modifiedAt ? (
              <Text fontSize="14px">{moment(columnData.modifiedAt).format('DD/MM/YYYY')}</Text>
            ) : (
              <Text fontSize="14px" fontStyle="italic" color={colors.gray[400]}>
                {t('fitbandConfiguration.noChanges')}
              </Text>
            )}
          </Box>
        );
      /* istanbul ignore next */
      default:
        return <Text>{cellData.value !== undefined ? cellData.render('Cell') : 'N/A'}</Text>;
    }
  };

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      <Table
        {...getTableProps()}
        className="mainTable simple-bordered-table"
        key="mainTable"
        css={mainTable}
        // w="100%"
        // border="1px solid #E5E7EB"
        // borderCollapse="collapse"
        // bg="#fff"
        ref={tableContainerRef}
      >
        <Thead css={tableHeading} className="tableHeading">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => {
                return (
                  <Th
                    {...omit(column.getHeaderProps(column.getSortByToggleProps()), 'title')}
                    isNumeric={column.isNumeric}
                    key={column.getHeaderProps().key}
                    className="fixBorder"
                    px={5}
                    py={3}
                    w={column.id === 'actionType' ? '20%' : 'auto'}
                    border="1px solid #E5E7EB"
                    borderCollapse="collapse"
                    align="left"
                  >
                    <Box display="flex" width="fit-content">
                      {column.render('Header')}
                      {!column?.disableSortBy && (
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            // istanbul ignore next

                            column.isSortedDesc ? (
                              <IoChevronDown aria-label="sorted descending" />
                            ) : (
                              <IoChevronUp aria-label="sorted ascending" />
                            )
                          ) : (
                            <MdOutlineUnfoldMore aria-label="unsorted" />
                          )}
                        </chakra.span>
                      )}
                    </Box>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!isLoading ? (
            data.length ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    css={TableStyle}
                    {...row.getRowProps()}
                    style={{ height: '20px' }}
                    key={row.getRowProps().key}
                    cursor="pointer"
                    width="100%"
                  >
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps()}
                        sNumeric={cell.column.isNumeric}
                        // border="1px solid #E5E7EB"
                        key={cell.getCellProps().key}
                        // px={5}
                        // py={3}
                        // borderCollapse="collapse"
                      >
                        {renderCell(cell)}
                      </Td>
                    ))}
                  </Tr>
                );
              })
            ) : (
              <Td colSpan={4}>
                <Alert status="info" backgroundColor={colors.gray[400]} color={colors.white}>
                  <AlertIcon color="white" />
                  {t('fitbandConfiguration.noDataFound')}
                </Alert>
              </Td>
            )
          ) : (
            <Td colSpan={4}>
              <Flex alignItems="center" justifyContent="center" p={5}>
                <Spinner colorScheme="blue" size="lg" />
              </Flex>
            </Td>
          )}
        </Tbody>
      </Table>
    </ScaleFade>
  );
}
