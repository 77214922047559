import ToDoList from '../../../locales/en/toDoList';

export interface ToDoList {
  count: Count;
  data: ToDoData[];
}

export interface Count {
  offerMade: OfferMade;
  backgroundCheck: BackgroundCheck;
  hireForm: HireForm;
  languageEvaluation: number;
  dialogflow: number;
  total: number;
  totalHireForm: number;
  totalOfferMade: number;
  totalBackgroundCheck: number;
  hireProcessing: number;
  outsandingOffers: number;
  toDo: number;
  completed: number;
}

export interface OfferMade {
  offerMade24HoursAgo: number;
  offerMade36HoursAgo: number;
  offerMade48HoursAgo: number;
}

export interface BackgroundCheck {
  bgCheckRequested24HoursAgo: number;
  bgCheckRequested48HoursAgo: number;
  bgCheckRequested72HoursAgo: number;
  bgCheckFlaggedByCertnCandidates: number;
  bgCheckNotProcessedByCertn24HoursAgoCandidates: number;
}

export interface HireForm {
  hireFormRequested12HoursAgo: number;
  hireFormRequested24HoursAgo: number;
  hireFormRequested48HoursAgo: number;
  hireFormRequested72HoursAgo: number;
  hireFormCompleted24HoursAgo: number;
  hireFormHelpRequested: number;
  hireFormInReview: number;
}

export interface ToDoData {
  id: string;
  firstName: string;
  lastName: string;
  businessName: string;
  position: string;
  category: string;
  computedStatus: ComputedStatus;
  status: string;
  customName: string;
  lastUpdate: string;
  recruiterActionId: string;
  todoStatus?: string;
  helpRequestedMessages?: {
    userType: 'seeker' | 'recruiter';
    message: string;
    timestamp: Date;
    name: string;
  }[];
  latestHelpRequestedFor?: string;
  hireDetailsRequestedAt?: string;
  seekerHireDetailId?: string;
  statusAssignedBy?: {
    recruiterId: string;
  };
}

export interface ComputedStatus {
  category: string;
  lastUpdate: Date;
  prioritySortValue: number;
  status: string;
}

export const ToDoDefault: ToDoList = {
  count: {
    offerMade: {
      offerMade24HoursAgo: 0,
      offerMade36HoursAgo: 0,
      offerMade48HoursAgo: 0,
    },
    backgroundCheck: {
      bgCheckRequested24HoursAgo: 0,
      bgCheckRequested48HoursAgo: 0,
      bgCheckRequested72HoursAgo: 0,
      bgCheckFlaggedByCertnCandidates: 0,
      bgCheckNotProcessedByCertn24HoursAgoCandidates: 0,
    },
    hireForm: {
      hireFormRequested12HoursAgo: 0,
      hireFormRequested24HoursAgo: 0,
      hireFormRequested48HoursAgo: 0,
      hireFormRequested72HoursAgo: 0,
      hireFormCompleted24HoursAgo: 0,
      hireFormHelpRequested: 0,
      hireFormInReview: 0,
    },
    languageEvaluation: 0,
    dialogflow: 0,
    total: 0,
    totalHireForm: 0,
    totalOfferMade: 0,
    totalBackgroundCheck: 0,
    outsandingOffers: 0,
    hireProcessing: 0,
    toDo: 0,
    completed: 0,
  },
  data: [],
};
