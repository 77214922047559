import React from 'react';
import { Select, theme } from '@chakra-ui/core';
import { FieldSchema } from '../formBuilderSchema';
import { useHireFormContext } from '../../HireForm.context';

interface FollowUpOptionSelectProps {
  followUpOptions: string[];
  followUpOptionId: string;
  setFollowUpOptions: (followUps: string[]) => void;
  parentDefaultValue: FieldSchema;
  onChange: (field: FieldSchema) => void;
  isAdvanced: boolean;
}

export default function FollowUpOptionSelect({
  followUpOptionId,
  followUpOptions,
  setFollowUpOptions,
  parentDefaultValue,
  onChange,
  isAdvanced,
}: FollowUpOptionSelectProps) {
  const { language } = useHireFormContext();
  const options = [...parentDefaultValue?.optionList!];
  const followUpQuestions = parentDefaultValue?.followUpQuestionList || [];
  const availableOptions = options?.filter(
    (option) => !followUpOptions?.filter((optionId) => optionId !== followUpOptionId)?.find((id) => id === option.id),
  );

  return (
    <Select
      width="auto"
      size="sm"
      borderRadius={theme.radii.md}
      variant="filled"
      backgroundColor="#F3F6F9"
      value={followUpOptionId}
      isDisabled={isAdvanced}
      onChange={(e) => {
        const optionId = e.target.value;
        const followUpIndex = followUpOptions?.findIndex((optionId) => optionId === followUpOptionId);
        if (followUpIndex === -1) return;

        const newFollowUpQuestions = [...followUpQuestions]?.map((question) => {
          if (question?.optionId === followUpOptionId) {
            return {
              ...question,
              optionId,
            };
          }
          return question;
        });
        const schema = {
          ...parentDefaultValue,
          followUpQuestionList: newFollowUpQuestions,
        };
        onChange(schema);

        const newFollowUps = [...followUpOptions];
        newFollowUps[followUpIndex] = optionId;
        setFollowUpOptions(newFollowUps);
      }}
    >
      {availableOptions?.map(({ id, text }, index) => {
        return (
          <option key={index} value={id}>
            {text[language]}
          </option>
        );
      })}
    </Select>
  );
}
