export default {
  formBuilder: {
    title: 'Form Builder',
    save: 'Save',
    clearAll: 'Clear All',
    cancel: 'Cancel',
    loadingText: "We're building your template...",
    templateNameInputPlaceholder: 'Enter Template Name',
    signatureSwapError: 'The signature component must remain at the end of the template',
    dragBoxInfo: 'Drag or click on the Components menu to the left to begin building your template.',
    sectionInputPlaceholder: 'Enter Section Name',
    sectionNameExistsMessage: 'This section name already exists. You must choose a different name.',
    deletePopoverMessage: 'Are you sure you want to delete this?',
    clearAllPopoverMessage: 'Are you sure you want to clear all form elements?',
    yes: 'Yes',
    no: 'No',
    selectAField: 'Select a Field',
    sectionSortDrawer: {
      title: 'Edit Section',
      save: 'Save',
      cancel: 'Cancel',
    },
    languageReviewModal: {
      title: 'Have You Reviewed This Template In All Its Languages?',
      message: "You should verify this template's content in all applicable languages before proceeding.",
      confirmText: "I have reviewed the template's content in all applicable languages.",
      confirmButton: 'Confirm',
      finalReviewSignature: {
        label: 'Require Candidate Signature',
        description: 'Enable this option to have the candidate provide their signature before submitting their HR Form.',
        disclaimerTitle: 'Disclaimer text',
        disclaimerText: `I {{name}}, confirm that all information entered in the application from on {{date}} is accurate, and I understand that providing false information may result in my disqualification from the recruitment process or dismissal.`,
      },
    },
    preview: {
      title: 'Form Preview',
      publish: 'Publish',
      deviceView: 'Device View',
      listView: 'List View',
      mobilePreview: {
        newHire: 'New Hire',
        help: 'Help',
        getStarted: 'Get Started',
        chooseFile: 'Choose a File',
        openCamera: 'Open Camera',
        selectDate: 'Select Date',
        selectTime: 'Select Time',
        reviewDocument: 'Review Document',
        signHere: 'Sign Here',
        showMe: 'Show Me',
        findAddress: 'Find Address',
      },
    },
    componentSidebar: {
      tabs: {
        basic: 'Basic',
        advanced: 'Advanced',
        tags: 'Tags',
      },
      searchPlaceholder: 'Search Components',
      pageElement: 'Form Elements',
      components: {
        text: 'Text',
        info: 'Instructions',
        email: 'Email',
        phone: 'Phone',
        link: 'Link',
        date: 'Date',
        time: 'Time',
        number: 'Number',
        radio: 'Single Choice',
        checkbox: 'Multiple Choice',
        gender: 'Gender',
        yesNo: 'Yes/No',
        address: 'Address',
        image: 'Upload Image',
        file: 'File',
        document: 'Document Preview',
        singleCheckbox: 'Checkbox',
        button: 'Button',
        correspondenceLanguage: 'Correspondence Language',
        signature: 'Signature',
        section: 'Section',
        sin: 'SIN',
        ssn: 'SSN',
        finalSinature: 'Final Signature',
        documentPreview: 'Document Preview',
        canadianBank: 'Canadian Bank',
        americanBank: 'American Bank',
        earliestStartDate: 'Earliest Start Date',
        addressValidator: 'Address Validator',
        emergencyContact: 'Emergency Contact',
        miscellaneous: 'Miscellaneous',
        bankAccountNumber: 'Bank Account Number',
        routingNumber: 'Routing Number',
        branchTransitNumber: 'Branch Transit Number',
        financialInstitutionNumber: 'Financial Institution Number',
        checkboxDisabled: 'Checkbox Disabled',
        fullname: 'Full Name',
      },
      advancedOnceInsertMessage: '{{component}} can only be inserted once time per template.',
      onlyOneBankInsertMessage: 'Only one of {{component1}} or {{component2}} can only be inserted once per template.',
    },
    signHere: 'Sign here',
    previewDocument: 'Preview Document',
    showNextQuestion: 'Show next question on',
    inputHere: 'Input here',
    inputField: 'Input Field',
    systemDefault: 'System Default',
    addFollowUp: 'Add Follow-up',
    emptyFollowUpPlaceholder: 'Drag and drop components on your left to add a follow-up.',
    editorPlaceholder: {
      text: 'Enter text here',
      yesNo: 'Enter text here for yes/no input',
      date: 'Enter text here for date input',
      number: 'Enter text here for number input',
      radio: 'Enter text here for single choice input',
      checkbox: 'Enter text here for multiple choice input',
      image: 'Enter text here for image input',
      file: 'Enter text here for file input',
      document: 'Enter text here for document input',
      address: 'Enter text here for address input',
      email: 'Enter text here for email input',
      phone: 'Enter text here for phone input',
      link: 'Enter text here for link input',
      time: 'Enter text here for time input',
    },
    chooseAFile: 'Choose a File',
    openCamera: 'Open Camera',
    addLabel: 'Add Label',
    showMe: 'Show Me',
    documentReview: {
      description1: 'Click the button below to upload your PDF document.',
      description2:
        'Make sure the file is in PDF format, with a size no larger than 2MB and an ideal resolution suitable for viewing on a mobile device.',
      chooseFiles: 'Choose Files',
    },
    characterLimit: {
      title: 'Character Limit',
      message:
        'Set the minimum and maximum number of characters allowed in this field. User must input between {{min}} and {{max}}  characters while answering this question.',
      min: 'Min',
      max: 'Max',
      reset: 'Reset',
      cancel: 'Cancel',
      done: 'Done',
    },
    languageSelectMenu: {
      onleOneLanguage: 'Only one language is available',
      primary: 'Primary',
      primaryLanguage: 'Primary Language',
      setAsPrimary: 'Set as Primary',
      removeLanguage: 'Remove Language',
      addLanguage: 'Add Language',
    },
  },
  templateListView: {
    title: 'Form Templates',
    showDefault: 'Show Defaults',
    addTemplate: 'Create New Template',
    tableHeaders: {
      name: 'Name',
      createdDate: 'Created Date',
      editHistory: 'Last Edited',
      actions: 'Actions',
    },
    tableActions: {
      edit: 'Edit',
      delete: 'Delete',
      copy: 'Copy',
      deleteSuccessMessage: '{{template}} has been deleted.',
      deleteErrorMessage: 'Error deleting {{template}}. Please try again.',
    },
    paginationText: {
      showingResult: 'Showing {{currentItems}} of {{totalItems}} templates',
    },
  },
  validation: {
    templateNameMaxLength: 'Value cannot exceed the maximum allowed length.',
    templateNameRequired: 'This field is required.',
    linkMaxLength: 'Value cannot exceed the maximum allowed length.',
    labelMaxLength: 'Value cannot exceed the maximum allowed length.',
    labelRequired: 'This field is required.',
    textMaxLength: 'Value cannot exceed the maximum allowed length.',
    textRequired: 'This field is required.',
    confirmTextMaxLength: 'Value cannot exceed the maximum allowed length.',
    confirmTextRequired: 'This field is required.',
    fileAccept: 'Only specific file types are allowed.',
    fileRequired: 'This field is required.',
    fileMaxSize: 'File size exceeds the maximum allowed.',
    sectionMaxLength: 'Value cannot exceed the maximum allowed length.',
    sectionRequired: 'This field is required.',
    fieldRequired: 'This field is required.',
    requiredInEnglish: 'This field is required for English.',
    validUrlInEnglish: 'Please enter a valid URL for English.',
    urlRequiredInEnglish: 'Please upload a document for English.',
    validUrlInFrench: 'Please enter a valid URL for French.',
    urlRequiredInFrench: 'Please upload a document for French.',
    urlRequiredForBoth: 'Both English and French documents are required.',
    urlRequired: 'Please upload a document.',
    invalidFollowUp: 'Invalid follow-up question configuration.',
    invalidFollowUpOption: 'Invalid follow-up question option.',
    duplicateOptions: 'Duplicate options found.',
    duplicateQuestionId: 'Duplicate IDs found.',
  },
  languageSelect: {
    start: 'Start',
    defaultButtonText: 'Select Language',
    modalTitle: 'Create Hire Form Template',
    templateName: 'Template Name',
    templateNamePlaceholder: 'Enter Name',
    templateNameError: 'Template Name is required.',
    primaryLanguage: 'Primary Language',
    primaryLanguageError: 'Primary Language is required.',
    primaryLanguagePlaceholder: 'Choose language',
    alternativeLanguage: 'Alternative Language',
    alternativeLanguagePlaceholder: 'Choose languages',
  },
};
