import {
  useDisclosure,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  ModalFooter,
} from '@chakra-ui/core';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MultiSelect, { StylesConfig } from 'react-select';
import theme from '../../../styles/customTheme';
import colors from '../../../styles/colors';
import { PATH_HIRE_FORM_CREATE } from '../../../routes/constants';
import { useHistory } from 'react-router-dom';
import { useStoreState } from '../../../models/hooks';
import { availableLanguages } from './hireForm.interface';

interface FormValues {
  templateName: string;
  defaultLanguage: string;
  languages: { value: string; label: string }[];
}

interface HireFormLanguageSelectModalProps {
  triggerRender: React.ReactNode;
}

export default function HireFormLanguageSelectModal({ triggerRender }: HireFormLanguageSelectModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { i18n } = useTranslation();
  const history = useHistory();
  const { t } = useTranslation('hrFormTemplate');
  const supportedLanguages = useStoreState((s) => s.app.accounts?.configuration?.supportedLanguages) ?? availableLanguages;

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      templateName: '',
      defaultLanguage: '',
      languages: [],
    },
  });

  const defaultLanguage = watch('defaultLanguage');
  const languages = watch('languages');

  const languageOptions = React.useMemo(() => {
    const filteredLanguages = supportedLanguages
      .map((lang) => ({
        ...lang,
        isFixed: lang.value === defaultLanguage,
      }))
      ?.filter((lang) => lang.value !== defaultLanguage);
    setValue(
      'languages',
      languages?.filter((lang) => lang.value !== defaultLanguage),
    );
    return filteredLanguages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage]);

  React.useEffect(() => {
    if (isOpen) {
      reset({
        templateName: '',
        defaultLanguage: i18n.language,
        languages: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, isOpen]);

  function handleModalOpen() {
    if (supportedLanguages?.length > 1) {
      onOpen();
    } else {
      onSubmit({
        templateName: '',
        defaultLanguage: supportedLanguages[0].value,
        languages: [supportedLanguages[0]],
      });
    }
  }

  const onSubmit = async (data: FormValues) => {
    const params = new URLSearchParams(
      (Object.keys(data) as Array<keyof FormValues>)?.reduce((acc, key) => {
        let value = data[key];
        if (key === 'languages' && Array.isArray(value)) {
          acc[key] = value?.reduce((acc, lang) => {
            acc.push(lang.value);
            return acc;
          }, [] as string[]);
          acc[key].push(data.defaultLanguage);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {} as any),
    );
    history.push(`${PATH_HIRE_FORM_CREATE}?${params.toString()}`);

    onClose();
  };

  const chakraSelectStyles: StylesConfig<{ value: string; label: string; isFixed: boolean }, true> = {
    control: (provided) => ({
      ...provided,
      border: `1px solid ${colors.input.borderColor}`,
      background: colors.input.background,
      borderRadius: theme.radii.md,
      '&:hover': {
        background: colors.input.hoverBackground,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.input.placeholderColor,
      fontSize: 'md',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '1rem',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      color: colors.black,

      '&:hover': {
        color: colors.black,
      },
      '& svg': {
        height: '1em',
        width: '1em',
      },
    }),

    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },

    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: theme.colors.blue[50],
      borderRadius: theme.radii.md,
      border: '2px solid',
      borderColor: theme.colors.blue[30],
      ...(state.data.isFixed && {
        cursor: 'not-allowed',
        display: 'none !important',
        backgroundColor: theme.colors.blue[100],
        borderColor: theme.colors.blue[400],
        fontWeight: 'bold',
        '& > div': {
          color: theme.colors.blue[400],
        },
        marginLeft: 0,
      }),
    }),
  };

  return (
    <>
      <Box>
        {triggerRender ? (
          <Box onClick={handleModalOpen}>{triggerRender}</Box>
        ) : (
          <Button onClick={handleModalOpen}>{t('languageSelect.defaultButtonText')}</Button>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('languageSelect.modalTitle')}</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Template Name */}
              <FormControl isInvalid={!!errors.templateName} mb={4}>
                <FormLabel htmlFor="templateName" marginBottom={1} fontWeight="bold" fontSize="sm">
                  {t('languageSelect.templateName')}
                </FormLabel>
                <Controller
                  name="templateName"
                  control={control}
                  rules={{ required: t('languageSelect.templateNameError') as string }}
                  render={({ value, onChange, ref }) => (
                    <Input
                      id="templateName"
                      placeholder={t('languageSelect.templateNamePlaceholder')}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      variant="filled"
                    />
                  )}
                />
                {errors.templateName && <FormErrorMessage>{errors.templateName.message}</FormErrorMessage>}
              </FormControl>

              {/* Default Language */}
              <FormControl isInvalid={!!errors.defaultLanguage} mb={4}>
                <FormLabel htmlFor="defaultLanguage" marginBottom={1} fontWeight="bold" fontSize="sm">
                  {t('languageSelect.primaryLanguage')}
                </FormLabel>
                <Controller
                  name="defaultLanguage"
                  control={control}
                  rules={{ required: t('languageSelect.primaryLanguageError') as string }}
                  render={({ value, onChange }) => (
                    <Select
                      id="defaultLanguage"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      variant="filled"
                      placeholder={t('languageSelect.primaryLanguagePlaceholder')}
                    >
                      {supportedLanguages.map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </Select>
                  )}
                />

                {errors.defaultLanguage && <FormErrorMessage>{errors.defaultLanguage.message}</FormErrorMessage>}
              </FormControl>

              {/* Secondary Languages */}
              <FormControl mb={4}>
                <FormLabel htmlFor="secondaryLanguages" marginBottom={1} fontWeight="bold" fontSize="sm">
                  {t('languageSelect.alternativeLanguage')}
                </FormLabel>
                <Controller
                  name="languages"
                  control={control}
                  render={({ value, onChange }) => {
                    return (
                      <MultiSelect
                        id="secondaryLanguages"
                        isMulti
                        options={languageOptions}
                        value={value}
                        isClearable={false}
                        onChange={onChange}
                        styles={chakraSelectStyles}
                        placeholder={t('languageSelect.alternativeLanguagePlaceholder')}
                      />
                    );
                  }}
                />
              </FormControl>

              <ModalFooter paddingX={0}>
                <Button colorScheme="red" onClick={onClose} marginRight={3}>
                  {t('formBuilder.cancel')}
                </Button>
                <Button colorScheme="blue" type="submit" mr={3}>
                  {t('languageSelect.start')}
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
