import { BackgroundChecks } from '../../../firebase/firestore/documents/candidate';
import { DialogFlowAgent } from '../../../firebase/firestore/documents/accounts';
import { Requisition } from '../../../firebase/firestore/documents/requisition';

export type IndustryType = {
  id: number;
  name: {
    en: string;
    fr: string;
    es: string;
  };
};

export type Skill = {
  id: number;
  name: {
    fr: string;
    en: string;
  };
  value: number;
};

export type SkillForPosition = {
  id: number;
  value: number;
};

export type Question = {
  id?: number;
  text: string;
  preferredAnswer: boolean;
  knockout: boolean;
};

export type FreeFormQuestion = {
  id?: number;
  text: string;
  isActive: boolean;
};
export enum CorrectAnswerDeterminationType {
  IS_EQUAL = 'IS_EQUAL',
  HAS_ANSWER = 'HAS_ANSWER',
  DOES_NOT_HAVE_ANSWER = 'DOES_NOT_HAVE_ANSWER',
  AT_LEAST = 'AT_LEAST',
}

export type Schedule = {
  day: string;
  evening: string;
  night: string;
};

export type JobType = {
  certifications: string[];
  id: number;
  industry: number;
  industryRef: number;
  jobTitle: {
    en: string;
    es: string;
    fr: string;
  };
  linkedJobs: string[];
  occupation: number;
  occupationRef: number;
  relatedJobs: string[];
  skills: Skill[];
};

export type OccupationType = {
  id: number;
  industryRef: number;
  name: {
    fr: string;
    en: string;
  };
};

export type BusinessSelectType = {
  id: string;
  label: string;
  value: string;
  locationId?: string;
  placeId?: string;
  positions?: number;
  country?: string;
  latitude?: number;
  longitude?: number;
  completeAddress?: string;
  hasRequisition?: boolean;
  isPaused?: boolean;
};
export type RequisitionSelectType = {
  id: string;
  label: string;
  value: string;
  color?: string;
  requisitoin?: Requisition;
};

export type PositionSelectType = {
  id: string;
  label: any;
  value: string;
  proposedSalary?: {
    customUnit: string;
    maxValue: number | string;
    minValue: number | string;
    unit: string;
  };
  enableHireFormSolution?: boolean;
  requisitionActiveThresholdDays?: number;
  isShiftIntersectable?: boolean;
};

export type GetIndustriesResponse = {
  industries: IndustryType[];
};

export type GetJobListResponse = {
  jobs: JobType[];
};

export type GetOccupationResponse = {
  occupations: OccupationType[];
};

export type Interview = {
  interviewType: string;
  interviewDuration: null | number;
  interviewMessage: string;
  interviewLocation: string | undefined;
};

export type MultipleChoiceImage = {
  galleryId: string;
  url: string;
  path?: string;
};
export type MultipleChoiceQuestion = {
  question: string;
  images: MultipleChoiceImage[];
  knockout: boolean;
  isActive?: boolean;
  totalAnswer: number;
  options: {
    text: string;
    isPreferredAnswer?: boolean;
    priority: number;
    id?: string;
  }[];
  preferredAnswers?: string[];
  shouldMatchAtLeast?: number;
  correctAnswerIf?: CorrectAnswerDeterminationType;
};

export type JobPosition = {
  industryName?: string;
  industry: string;
  occupation: string;
  job: string;
  customName?: string;
  jobCustomName?: string;
  businessName: string;
  business: string;
  businessAddress?: string;
  noOfOpenings?: null | number;
  proposedSalary: {
    customUnit: null | string;
    maxValue: number | string;
    minValue: number | string;
    unit: string;
  };
  experienceFilterType: null | string;
  requiredExperience: null | number;
  jobDescription: string;
  fullTime: boolean;
  automated?: boolean;
  automationType?: null | string;
  automationConfiguration?: AutomationConfiguration;
  recruiterNote?: string;
  interview: Interview;
  specificQuestions: Question[];
  freeFormQuestions: FreeFormQuestion[];
  multipleChoiceQuestions: MultipleChoiceQuestion[];
  schedule: {
    [key: string]: Schedule;
  };
  skills: SkillForPosition[];
  enableHireFormSolution?: boolean;
  hireFormTemplateId?: string | null;
  modernHireEnable?: boolean;
  modernHirePassThreshold?: number;
  modernHireTemplateId?: string;
  enableScheduleMatch?: boolean;
  fitBandTemplateEnable?: boolean;
  fitBandTemplateId?: string;
  virtualRecruiterEnabled?: boolean;
  languageEvaluationTemplates?: {
    language: string;
    phrase: string;
    phraseId: string;
  }[];
  languageEvaluationEnable?: boolean;
  leTemplateId?: string;
  screeningOptions?: BackgroundChecks | null;
  enableScreeningOptions?: boolean;
  languageEvaluationPassThreshold?: number;
  videoUrlId?: string;
  videoUrl?: string;
  videoUrlThumbnail?: string;
  dialogFlowAgents?: DialogFlowAgent[];
  dialogFlowEnabled?: boolean;
  resumeEnabled?: boolean;
  newAvailabilities?: { [key: string]: { start_1: string; end_1: string } };
  positionId?: string;
  description?: string;
};

export type JobPositionToCopy = {
  positionId: string;
  business: string;
  automated?: boolean;
  automationType?: null | string;
  automationConfiguration?: {
    target: number | null;
  };
  customName?: string;
  businessName: string;
  noOfOpenings?: null | number;
  proposedSalary: {
    customUnit: null | string;
    maxValue: number | string;
    minValue: number | string;
    unit: string;
  };
  description: string;
  specificQuestions: Question[];
  freeFormQuestions: FreeFormQuestion[];
  multipleChoiceQuestions: MultipleChoiceQuestion[];
  languageEvaluationTemplates?: {
    language: string;
    phrase: string;
    phraseId: string;
  }[];
  screeningOptions?: BackgroundChecks | null;
  enableScreeningOptions?: boolean;
  resumeEnabled?: boolean;
  newAvailabilities?: { [key: string]: { start_1: string; end_1: string } };
  fullTime?: boolean;
};

export type JopPositionResponse = {
  assignedRecruiters?: FollowedRecruiters[];
  business: string;
  city: string;
  jobTitle: string;
  businessName: string;
  open: boolean;
  likes?: number;
  noOfHiredCandidates?: number;
  industry: string;
  industryName: string;
  fullTime: boolean;
  customName: string;
  jobCustomName?: string;
  proposedSalary?: {
    customUnit: null | string;
    maxValue: null | number;
    minValue: null | number;
    unit: string;
  };
  recruiterNotes: {
    id: string;
    notes: string;
  }[];
  createdAt: string;
  completeBusinessAddress: string;
  firstOpenAt: string;
  location: {
    coordinates: number[];
    type: string;
  };
  id: string;
  job: string;
  account: string;
  noOfOpenings?: number;
  experienceFilterType: null | string;
  requiredExperience: null | number;
  description: string;
  interview: Interview;
  specificQuestions: Question[];
  freeFormQuestions: FreeFormQuestion[];
  multipleChoiceQuestions: MultipleChoiceQuestion[];
  schedule: {
    [key: string]: Schedule;
  };
  automationType: string | null;
  automated?: boolean;
  automationConfiguration?: AutomationConfiguration;
  skills: SkillForPosition[];
  slugifiedAccountName: string;
  slugifiedPositionName: string;
  enableHireFormSolution?: boolean;
  hireFormTemplateId?: string | null;
  modernHireEnable?: boolean;
  modernHirePassThreshold?: number;
  modernHireTemplateId?: string;
  enableScheduleMatch?: boolean;
  fitBandTemplateEnable?: boolean;
  fitBandTemplateId?: string | null;
  virtualRecruiterEnabled?: boolean;
  languageEvaluationTemplates?: {
    language: string;
    phrase: string;
    phraseId: string;
  }[];
  languageEvaluationEnable?: boolean;
  leTemplateId?: string;
  screeningOptions?: BackgroundChecks;
  enableScreeningOptions?: boolean;
  languageEvaluationPassThreshold?: number;
  dialogFlowAgents?: DialogFlowAgent[];
  dialogFlowEnabled?: boolean;
  resumeEnabled?: boolean;
  videoUrlId?: string | undefined;
  videoUrl?: string | undefined;
  videoUrlThumbnail?: string | undefined;
  newAvailabilities?: { [key: string]: { start_1: string; end_1: string } };
  positionId?: string;
};

export type PositionFromPositionList = {
  applicants?: number;
  account: string;
  business: string;
  businessName: string;
  city: string;
  completeBusinessAddress: string;
  createdAt: string;
  customName: string | null;
  firstOpenAt: string;
  fullTime: boolean;
  id: string;
  indexedAt: string;
  industry: string;
  job: string;
  jobTitle: string;
  location: { type: string; coordinates: number[] };
  noOfHiredCandidates: null | number;
  noOfOpenings: null | number;
  open: boolean;
  skills: string[];
  skillsImportance: string[];
  assignedRecruiters?: FollowedRecruiters[];
  automated?: boolean;
  automationType?: string | null;
  automationConfiguration?: {
    target: number;
  };
  isPaused?: boolean;
  archived: boolean;
  proposedSalary?: {
    customUnit: string;
    maxValue: number | string;
    minValue: number | string;
    unit: string;
  };
  enableHireFormSolution?: boolean;
  requisitionActiveThresholdDays?: number;
  isShiftIntersectable?: boolean;
  virtualRecruiterEnabled?: boolean;
  resumeEnabled?: boolean;
};

export type FollowedRecruiters = {
  id: string;
  name: string;
};

export type PositionListResponse = {
  count: number;
  lastPage: number;
  to: number;
  from: number;
  data: PositionFromPositionList[];
};

export type CreatePositionResponse = {
  positionId: string;
};

export type PositionListQueryParams = {
  positionStatus: string;
  followedOnly: boolean;
  sortBy: string;
  sortOrder: string;
  page: number;
  limit: number;
  searchTerm: string;
  archive: boolean;
};

export type PositionStatus = {
  open: boolean;
};

export type DeepLinkResponse = {
  link: string;
};

export type ConfirmMessageType = {
  headerMessage: string;
  bodyMessage: string;
  confirmText: string;
  cancelText: string;
};

export enum PositionFilterEnum {
  FOLLOWED = 'followed',
  ALL = 'all',
  OPEN = 'open',
  CLOSED = 'closed',
  ARCHIVED = 'archived',
}

export type AutomationConfiguration = {
  target: null | number;
};

export type DeepLinkOfPositionType = {
  positionId: string;
  priority: boolean;
  referrerId?: string | undefined;
  source?: string | undefined;
  medium?: string | undefined;
  campaign?: string | undefined;
  priorityExpiry?: Date | null;
  locale: string;
};
