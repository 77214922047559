/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-default-export */
/* istanbul ignore file */
import { Cell, Column, usePagination, useTable } from 'react-table';
import i18next from 'i18next';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Heading, ScaleFade, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/core';
import moment from 'moment-timezone';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import { MdOutlineTask } from 'react-icons/md';
import { ActionEnum, RecruiterTodoListDataType } from './RecruiterTodoListType';
import BackgroundCheckIcon from '../../assets/img/certn-logo.svg';
import DocumentIcon from '../../assets/img/icon-document.svg';
import OfferIcon from '../../assets/img/icon-offer.svg';
import LanguageEvaluationIcon from '../../assets/img/icon-languageEvaluation.svg';
import LanguageEvaluationStatusIcon from '../../assets/img/language-evaluation-icon.svg';
import ConvensionalAiIcon from '../../assets/img/icon-ConvensionalAi.svg';
import ConversationalAiIcon from '../../assets/img/conversational-ai-icon.svg';

import colors from '../../styles/colors';
import { CandidateStatusDropdown } from './CandidateStatusDropdown';
import { TodosType } from '../../firebase/firestore/documents/lookups';
import { getTranslatedValue } from '../../utils/localizedString';

import { CandidateMessagingViewModal } from '../../app/components/CandidateMessagingViewModal';
import useCandidateRecruiterAction from './useCandidateRecruiterAction';
import { ToDoList } from '../../firebase/firestore/documents/toDo';
import { RecruiterToDoStatusFilter } from './RecruiterToDoStatusFilter';

const TableStyle = css`
  cursor: pointer;
  &:hover, &:focus, &:active {
    background: #f7fafc;
  }
  &.activeRow {
    background: ${colors.gray[50]};
  }
  &:hover .rightArrow {
    opacity: 1;
    transform: translate(0, -50%);
   }
}
`;

export type RecruiterTodoListTableViewProps<Data extends Record<string, unknown>> = {
  isLoading: boolean;
  handleAction: (
    id: string,
    action: string,
    candidateName: string,
    candidateId: string,
    helpRequestedMessages?: {
      userType: string;
      message: string;
      timestamp: Date;
      name: string;
    }[],
    latestHelpRequestedFor?: string,
    seekerHireDetailId?: string,
    hireDetailsRequestedAt?: string,
  ) => void;
  candidateTodos: TodosType;
  data: Data[];
  columns: Column<Data>[];
  onCallToDoAPI: () => void;
  accountId?: string;
  setToDoList: React.Dispatch<React.SetStateAction<ToDoList>>;
  onMessagingAction: (candidateId: string) => void;
  onCompletedChange: (needCompleted: boolean) => void;
  setFilterByStatusItems: (status: string[]) => void;
  setGroupByStatus: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function RecruiterTodoListTableView<Data extends Record<string, unknown>>({
  isLoading,
  handleAction,
  candidateTodos,
  data,
  columns,
  onCallToDoAPI,
  accountId,
  setToDoList,
  onMessagingAction,
  onCompletedChange,
  setFilterByStatusItems,
  setGroupByStatus,
}: RecruiterTodoListTableViewProps<Data>) {
  const { t } = useTranslation();

  const [activeRow, setActiveRow] = useState<string>('');
  const tableContainerRef = useRef<null | HTMLTableElement>(null);
  const [currentCandidateId, setCurrentCandidateId] = useState<string>('');
  const [isMessagingModal, setIsMessagingModal] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    usePagination,
  );

  /* istanbul ignore next */
  const onActionModalOpen = (candidateId: string) => {
    setCurrentCandidateId(candidateId);
    setIsMessagingModal(false);
    onOpen();
  };

  const onCopySeekerHireDetailId = async (hireDetailId: string) => {
    await navigator.clipboard.writeText(hireDetailId);
    toast({
      title: t('toDoList:hireDetailIdCopiedInfo'),
      duration: 2000,
      isClosable: true,
    });
  };

  const getColumnTitle = (title: string) => {
    const headingObject = candidateTodos[title as keyof TodosType]?.heading;
    return headingObject ? getTranslatedValue(headingObject, i18next.language) : title;
  };

  const getColumnDescription = (title: string, seekerHireDetailId: string | undefined) => {
    const detailObject = candidateTodos[title as keyof TodosType]?.details;
    const description = detailObject ? getTranslatedValue(detailObject, i18next.language) : title;
    return (
      <Box>
        <Flex>{description}</Flex>
        {seekerHireDetailId && (
          <Flex d="inline-flex">
            <Flex mr={2}>
              <Box mr={1}>{t('toDoList:hireFormId')}</Box>
              <Box>{seekerHireDetailId}</Box>
            </Flex>
            <Box>
              <FaRegCopy
                size={18}
                onClick={() => onCopySeekerHireDetailId(seekerHireDetailId)}
                data-testid={`copy-${seekerHireDetailId}`}
              />
            </Box>
          </Flex>
        )}
      </Box>
    );
  };

  const getColumnActionLabel = (title: string) => {
    const actionLabelObject = candidateTodos[title as keyof TodosType]?.actionLabel;
    return actionLabelObject ? getTranslatedValue(actionLabelObject, i18next.language) : title;
  };

  const getColumnActionButtonLabel = (
    id: string,
    title: string,
    candidateName: string,
    candidateId: string,
    helpRequestedMessages?: {
      userType: string;
      message: string;
      timestamp: Date;
      name: string;
    }[],
    latestHelpRequestedFor?: string,
    seekerHireDetailId?: string,
    hireDetailsRequestedAt?: string,
  ) => {
    const hasButton = candidateTodos[title as keyof TodosType];
    if (hasButton?.actionButton) {
      return (
        <Button
          onClick={() => {
            handleAction(
              id,
              title,
              candidateName,
              candidateId,
              helpRequestedMessages,
              latestHelpRequestedFor,
              seekerHireDetailId,
              hireDetailsRequestedAt,
            );
            onMessagingAction(candidateId);
          }}
          colorScheme="blue"
          w="170px"
          fontSize="14px"
          fontWeight="600"
          borderRadius="40px"
          data-testid={`ActionButton-${candidateId}-${title}`}
        >
          {getTranslatedValue(hasButton.actionButton, i18next.language)}
        </Button>
      );
    }
    return <Box />;
  };

  const getDataOfHoursAgo = (status: string) => {
    /* istanbul ignore next */
    switch (status) {
      case 'hireFormRequested12HoursAgo':
      case 'offerMade12HoursAgo':
      case 'hireFormResubmission12HoursAgo':
        return (
          <Box
            as="span"
            d="inline-block"
            whiteSpace="nowrap"
            px="6px"
            py="3px"
            ml={2}
            fontSize="13px"
            borderRadius="46px"
            lineHeight={1.1}
            bg="#2196F3"
            color="#fff"
          >
            {`12 ${t('toDoList:hrPlus')}`}
          </Box>
        );
      case 'hireFormCompleted24HoursAgo':
      case 'hireFormRequested24HoursAgo':
      case 'hireFormResubmission24HoursAgo':
      case 'bgCheckRequested24HoursAgo':
      case 'offerMade24HoursAgo':
      case 'bgCheckNotProcessedByCertn24HoursAgoCandidates':
        return (
          <Box
            as="span"
            d="inline-block"
            whiteSpace="nowrap"
            px="6px"
            py="3px"
            ml={2}
            fontSize="13px"
            borderRadius="46px"
            lineHeight={1.1}
            bg="#D69E2E"
            color="#fff"
          >
            {`24 ${t('toDoList:hrPlus')}`}
          </Box>
        );
      case 'offerMade36HoursAgo':
      case 'hireFormResubmission36HoursAgo':
        return (
          <Box
            as="span"
            d="inline-block"
            whiteSpace="nowrap"
            px="6px"
            py="3px"
            ml={2}
            fontSize="13px"
            borderRadius="46px"
            lineHeight={1.1}
            bg="#D69E2E"
            color="#fff"
          >
            {`36 ${t('toDoList:hrPlus')}`}
          </Box>
        );
      case 'bgCheckRequested48HoursAgo':
      case 'offerMade48HoursAgo':
      case 'hireFormRequested48HoursAgo':
      case 'hireFormResubmission48HoursAgo':
        return (
          <Box
            as="span"
            d="inline-block"
            whiteSpace="nowrap"
            px="6px"
            py="3px"
            ml={2}
            fontSize="13px"
            borderRadius="46px"
            lineHeight={1.1}
            bg="#E53E3E"
            color="#fff"
          >
            {`48 ${t('toDoList:hrPlus')}`}
          </Box>
        );
      case 'hireFormRequested72HoursAgo':
      case 'bgCheckRequested72HoursAgo':
      case 'hireFormResubmission72HoursAgo':
        return (
          <Box
            as="span"
            d="inline-block"
            whiteSpace="nowrap"
            px="6px"
            py="3px"
            ml={2}
            fontSize="13px"
            borderRadius="46px"
            lineHeight={1.1}
            bg="#E53E3E"
            color="#fff"
          >
            {`72 ${t('toDoList:hrPlus')}`}
          </Box>
        );
      default:
        return <Box as="span" />;
    }
  };

  const renderIcon = (actionType: ActionEnum) => {
    switch (actionType) {
      case ActionEnum.BACKGROUND_CHECK:
        return <img src={BackgroundCheckIcon} alt="background check" height={24} width={24} />;
      case ActionEnum.OFFER:
        return <img src={OfferIcon} alt="offer" height={20} width={20} />;
      case ActionEnum.HIRE_FORM:
        return <img src={DocumentIcon} alt="Hire form" height={20} width={20} />;
      case ActionEnum.LANGUAGE_EVALUATION:
        return <img src={LanguageEvaluationIcon} alt="Hire form" height={20} width={20} />;
      default:
        return <img src={ConvensionalAiIcon} alt="AI" height={20} width={20} />;
    }
  };
  const candidateRecruiterActions = useCandidateRecruiterAction(accountId ?? /* istanbul ignore next */ '');

  const onCopyURL = async (candidateId: string) => {
    await navigator.clipboard.writeText(`${window.location.origin}/candidate/${candidateId}/messaging`);
    toast({
      title: t('toDoList:urlCopiedInfo'),
      duration: 2000,
      isClosable: true,
    });
  };

  useEffect(() => {
    // istanbul ignore next
    if (candidateRecruiterActions) {
      onCallToDoAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateRecruiterActions]);

  const renderCell = (cellData: Cell<Data>) => {
    const columnData = (cellData.row.original as unknown) as RecruiterTodoListDataType;
    switch (cellData.column.id) {
      /* istanbul ignore next */
      case 'actionType':
        return (
          <Flex
            className={cellData.column.id}
            data-testid={cellData.column.id}
            justifyContent="space-between"
            py="15px"
            alignItems="center"
          >
            <Flex mr="15px">
              <Box mr={2} h="18px" w="18px" minW="24px">
                {renderIcon(cellData.value as ActionEnum)}
              </Box>
              <Box>
                <Heading fontSize="16px" color="gray.900" mb="5px" lineHeight="1.2">
                  {getColumnTitle(columnData.title)}
                </Heading>
                <Text fontSize="15px" color="gray.700">
                  {getColumnDescription(columnData.title, columnData?.seekerHireDetailId)}
                </Text>
              </Box>
            </Flex>
            <Box>
              {getColumnActionButtonLabel(
                columnData.id,
                columnData.title,
                columnData.candidateFullName,
                columnData.candidateId,
                columnData?.helpRequestedMessages,
                columnData?.latestHelpRequestedFor,
                columnData?.seekerHireDetailId,
                columnData?.hireDetailsRequestedAt,
              )}
            </Box>
          </Flex>
        );
      case 'lastStatus':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="15px">
            <Text fontSize="15px" color="gray.600" mb={1} d="flex" alignItems="center">
              {columnData.actionType === ActionEnum.LANGUAGE_EVALUATION && (
                <img
                  src={LanguageEvaluationStatusIcon}
                  alt="language evaluation"
                  height={20}
                  width={20}
                  style={{ marginRight: '5px', display: 'inline-block', verticalAlign: 'middle', marginBottom: '5px' }}
                />
              )}
              {columnData.actionType === ActionEnum.CONVERSATIONAL_AI && (
                <img
                  src={ConversationalAiIcon}
                  alt="conversational ai"
                  height={20}
                  width={20}
                  style={{ marginRight: '5px', display: 'inline-block', verticalAlign: 'middle', marginBottom: '5px' }}
                />
              )}
              {moment(columnData.lastStatus).format('DD/MM/YYYY hh:mm A')}

              {getDataOfHoursAgo(columnData.title)}
            </Text>
            <Heading fontSize="16px" color="gray.700" mb={1} lineHeight="1.2">
              {getColumnActionLabel(columnData.title)}
            </Heading>
          </Box>
        );
      case 'status':
        return (
          <CandidateStatusDropdown
            id={columnData.id}
            status={columnData.title}
            candidateId={columnData.candidateId}
            category={columnData.category}
            actionLabel={columnData.status}
            candidateRecruiterActions={candidateRecruiterActions}
            setToDoList={setToDoList}
            statusAssignedBy={columnData?.statusAssignedBy}
          />
        );
      case 'location':
        return (
          <Link to={{ pathname: `/positionCandidates/${columnData.position}/messaging`, state: 'RecruiterTodoListTableView' }}>
            <Box className={cellData.column.id} data-testid={cellData.column.id} py="15px">
              <Text fontSize="15px" color="gray.600" mb={1}>
                {cellData.value}
              </Text>
              <Heading
                fontSize="16px"
                color="gray.700"
                mb={1}
                lineHeight="1.2"
                as="span"
                style={{ color: colors.linkBlue, textDecoration: 'underline' }}
              >
                {columnData.customName}
              </Heading>
            </Box>
          </Link>
        );
      case 'candidateFullName':
        /* istanbul ignore next */
        return (
          <Flex className={cellData.column.id} data-testid={cellData.column.id} py="10px" justifyContent="space-between">
            <Box
              as="span"
              style={{ color: colors.linkBlue, textDecoration: 'underline' }}
              mr={3}
              onClick={() => onActionModalOpen(columnData.candidateId)}
            >
              {cellData.value}
            </Box>

            <FaRegCopy
              style={{ minWidth: '18px' }}
              width={18}
              size={18}
              onClick={() => onCopyURL(columnData.candidateId)}
              data-testid={`RefreshButton-${columnData.candidateId}`}
            />
          </Flex>
        );
      /* istanbul ignore next */
      default:
        return (
          <Text className={cellData.column.id} data-testid={cellData.column.id}>
            {cellData.value !== undefined ? cellData.render('Cell') : 'N/A'}
          </Text>
        );
    }
  };

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      <Table
        {...getTableProps()}
        className="mainTable"
        key="mainTable"
        w="100%"
        border="1px solid #E5E7EB"
        borderCollapse="collapse"
        bg="#fff"
        ref={tableContainerRef}
      >
        <Thead className="tableHeading" bg="blue.100">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                  isNumeric={column.isNumeric}
                  key={column.getHeaderProps().key}
                  className="fixBorder"
                  style={{ borderColor: '#d0e9f4' }}
                  px={3}
                  py={2}
                  w={column.id === 'actionType' ? '50%' : 'auto'}
                  border="1px solid #E5E7EB"
                  borderCollapse="collapse"
                >
                  {column.Header === t('toDoList:status') ? (
                    <RecruiterToDoStatusFilter
                      onCompletedFilterChange={onCompletedChange}
                      setGroupedByStatusItems={setFilterByStatusItems}
                      setGroupByStatus={setGroupByStatus}
                    />
                  ) : (
                    column.render('Header') // Only render text for non-status columns
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!isLoading ? (
            data.length ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    css={TableStyle}
                    {...row.getRowProps()}
                    style={{ height: '40px' }}
                    key={row.getRowProps().key}
                    cursor="pointer"
                    data-testid="tabletTr"
                    onClick={() => setActiveRow(row.original.id as string)}
                    className={activeRow === (row.original.id as string) ? 'activeRow' : ''}
                  >
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps()}
                        isNumeric={cell.column.isNumeric}
                        pr=""
                        key={cell.getCellProps().key}
                        data-testid="tableRow"
                        px={3}
                        border="1px solid #E5E7EB"
                        borderCollapse="collapse"
                      >
                        {renderCell(cell)}
                      </Td>
                    ))}
                  </Tr>
                );
              })
            ) : (
              <Td colSpan={5}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" py={10}>
                  <MdOutlineTask size={104} color={colors.gray[400]} />
                  <Text py={3} fontSize="24px" fontWeight={700}>
                    {t('toDoList:allCaughtUp')}
                  </Text>
                  <Text fontSize="16px" fontWeight={400} textAlign="center" color="#718096">
                    {t('toDoList:noPendingUpdates')}
                    <br />
                    {t('toDoList:checkBackLater')}
                  </Text>
                </Box>
              </Td>
            )
          ) : (
            <Td colSpan={5}>
              <Flex alignItems="center" justifyContent="center" p={5}>
                <Spinner colorScheme="blue" size="lg" />
              </Flex>
            </Td>
          )}
        </Tbody>
      </Table>
      {isOpen && (
        /* istanbul ignore next */ <CandidateMessagingViewModal
          isOpen={isOpen}
          onClose={onClose}
          currentCandidateId={currentCandidateId}
          messagingViewModal={isMessagingModal}
        />
      )}
    </ScaleFade>
  );
}
