import {
  Box,
  FormControl,
  Stack,
  FormLabel,
  Text,
  Switch,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../models/hooks';
import moment from 'moment';
import theme from '../../../../styles/customTheme';
import { useHireFormContext } from '../HireForm.context';

interface FinalSignatureSwitchProps {
  needFinalSignature: boolean;
  setNeedFinalSignature: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FinalSignatureSwitch({ needFinalSignature, setNeedFinalSignature }: FinalSignatureSwitchProps) {
  const { i18n, t } = useTranslation('hrFormTemplate');
  const { currentTemplate } = useStoreState((state) => state.hrFormTemplate);
  const { language } = useHireFormContext();

  const user = useStoreState((s) => s.app.user);
  const [isExpanded, setIsExpanded] = React.useState(false);

  React.useEffect(() => {
    moment.locale(i18n.language);
    // eslint-disable-next-line
  }, [i18n.language]);

  React.useEffect(() => {
    if (currentTemplate) {
      setNeedFinalSignature(currentTemplate?.needFinalSignature ?? false);
    }
  }, [currentTemplate, setNeedFinalSignature]);

  return (
    <Box>
      <Accordion allowMultiple onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionItem border={isExpanded ? `1px solid ${theme.colors.gray[200]}` : 'none'} borderRadius="md" isFocusable={false}>
          <AccordionButton
            _focus={{ outline: 'none', boxShadow: 'none' }}
            sx={{ borderBottom: isExpanded ? `1px solid ${theme.colors.gray[200]}` : 'none' }}
          >
            <FormControl display="flex" alignItems="flex-start">
              <Switch
                id="hr-form-final-review-signature"
                size="sm"
                onChange={(e) => setNeedFinalSignature(e.target.checked)}
                isChecked={needFinalSignature}
              />
              <Stack spacing={0} marginLeft={4}>
                <FormLabel htmlFor="hr-form-final-review-signature" mb="0" fontSize="sm" fontWeight="bold" lineHeight={1}>
                  {t('formBuilder.languageReviewModal.finalReviewSignature.label', { lng: language })}
                </FormLabel>
                <Text fontSize="sm" color={'gray.500'} textAlign="left">
                  {t('formBuilder.languageReviewModal.finalReviewSignature.description', { lng: language })}
                </Text>
              </Stack>
            </FormControl>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text variant="h6" fontSize="sm" mb={1} fontWeight="bold">
              {t('formBuilder.languageReviewModal.finalReviewSignature.disclaimerTitle', { lng: language })}
            </Text>
            <Text fontSize="sm">
              {t('formBuilder.languageReviewModal.finalReviewSignature.disclaimerText', {
                lng: language,
                name: `${user?.firstName} ${user?.lastName}`,
                date: moment().format(i18n.language === 'en' ? 'DD MMM YYYY [at] hh:mm A' : 'DD MMMM YYYY [à] hh:mm A'),
              })}
              {/* I {user?.firstName} {user?.lastName}, confirm that all information entered in the application from on{' '}
              {moment().format('DD MMM YYYY [at] hh:mm A')} is accurate, and I understand that providing false information may
              result in my disqualification from the recruitment process or dismissal. */}
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
