import {
  Box,
  Button,
  Stack,
  Text,
  HStack,
  Divider,
  Input,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from '../formBuilderSchema';
import LabelSchemaBuilder from '../LabelSchemaBuilder';
import FormElementTextBuilder from '../FormElementTextBuilder';
import {
  MdClose,
  MdOutlineBorderColor,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineRemoveRedEye,
  MdOutlineUploadFile,
} from 'react-icons/md';
import omit from 'lodash/omit';
import TextSchemaBuilder from '../TextSchemaBuilder';
import useErrorHandling from '../../useErrorHandling';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../styles/customTheme';

export default function DocumentPreview({ ...props }: SchemaBuilderProps) {
  const { defaultValue, language } = props;
  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        {props?.labelRightRenderer}
      </HStack>
      <FormElementTextBuilder {...props} placeholder="Enter text here..." />
      <Divider variant="dashed" marginY={3} marginTop={3} />
      <TextSchemaBuilder
        label={defaultValue?.documentReview?.description?.[language]!}
        onLabelChange={(value) => {
          const changedValue = {
            ...omit(props.defaultValue, 'documentReview'),
            documentReview: {
              ...defaultValue?.documentReview!,
              description: { ...defaultValue?.documentReview?.description!, [language]: value },
            },
          };
          props.onChange(changedValue);
        }}
        language={props.language}
        placeholder="Enter description here...."
        path={[...props.path, 'documentPreview', 'description']}
      />

      <DocumentUpload {...props} />
      {/* <DocumentError path={props.path} /> */}
      <HStack>
        <MdOutlineCheckBoxOutlineBlank />
        <TextSchemaBuilder
          label={defaultValue?.documentReview?.acknowledgeContent?.[language]!}
          onLabelChange={(value) => {
            const changedValue = {
              ...omit(props.defaultValue, 'documentReview'),
              documentReview: {
                ...defaultValue?.documentReview!,
                acknowledgeContent: { ...defaultValue?.documentReview?.acknowledgeContent!, [language]: value },
              },
            };
            props.onChange(changedValue);
          }}
          language={props.language}
          placeholder="Enter acknowledgement text here...."
          path={[...props.path, 'documentReview', 'acknowledgeContent']}
        />
      </HStack>
      <HStack>
        <DocumentPreviewButton {...props} />
      </HStack>
    </Stack>
  );
}

// function DocumentError({ path }: Pick<SchemaBuilderProps, 'path'>) {
//   const { t } = useTranslation('hrFormTemplate');

//   const { error, hasError } = useErrorHandling({
//     path: [...path, 'documentReview', 'url'],
//     valdationFor: 'document_preview_file',
//     onValidation: (val) => val,
//   });
//   if (!hasError || !error) return null;
//   return (
//     <Text fontSize={theme.fontSizes.xs} color={theme.colors.red[500]}>
//       {t(error?.message!)}
//     </Text>
//   );
// }

function DocumentUpload({ path, onChange, defaultValue, language }: SchemaBuilderProps) {
  const { t } = useTranslation('hrFormTemplate');

  const inputId = 'document-upload_' + defaultValue?.id;
  const [file, setFile] = React.useState<File | null>(null);
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(defaultValue?.documentReview?.url?.[language] ?? null);
  const [fileUrl, setFileUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    setFileUrl(file ? URL.createObjectURL(file) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path: [...path, 'documentReview', 'url', language],
    valdationFor: 'document_preview_file',
    onValidation: (val) => val,
  });
  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (file) {
      const validateFile = { mimetype: file.type, size: file.size };
      validateError({ file: validateFile }, () => {
        setFile(file ?? null);
      });
    }
  }
  return (
    <FormControl isInvalid={hasError}>
      <Box
        style={{
          backgroundColor: error ? '#FED7D7' : '#F0F8FE',
          borderRadius: '4px',
          padding: '24px',
          border: '2px dashed',
          borderColor: error ? 'red' : '#E2E8F0',
        }}
      >
        <Input ref={inputRef} accept="application/pdf" type="file" display="none" onChange={handleFileChange} id={inputId} />

        <Stack align="center" style={{ rowGap: theme.space[3] }}>
          <Box
            style={{
              backgroundColor: '#2196F326',
              padding: 16,
              borderRadius: '50%',
              aspectRatio: '1/1',
            }}
          >
            <MdOutlineUploadFile size={24} fill="#2196F3" />
          </Box>
          <Box
            color="#A0AEC0"
            style={{
              fontSize: 12,
              fontWeight: 400,
              textAlign: 'center',
              lineHeight: '18px',
            }}
          >
            <Text> {t('formBuilder.documentReview.description1')}</Text>
            {t('formBuilder.documentReview.description2')}
          </Box>
          {(file || defaultValue?.documentReview?.url?.[language]?.length! > 0) && (
            <HStack spacing={2} align="center" backgroundColor="#F0F8FE" borderRadius={20} padding={2}>
              <Text color="#A0AEC0" isTruncated fontSize="sm" maxW={'300px'}>
                {file?.name || defaultValue?.documentReview?.url?.[language]}
              </Text>
              <HStack divider={<Divider />} spacing={0}>
                <DocumentPdfViewer
                  url={(fileUrl || pdfUrl) ?? ''}
                  file={file}
                  onUpload={(url) => {
                    const changedValue = {
                      ...omit(defaultValue, 'documentReview'),
                      documentReview: {
                        ...defaultValue?.documentReview!,
                        url: { ...defaultValue?.documentReview?.url!, [language]: url },
                      },
                    };

                    onChange(changedValue);

                    if (inputRef.current) inputRef.current.value = '';
                    setPdfUrl(url);
                    setFile(null);
                  }}
                  onChange={onChange}
                  defaultValue={defaultValue}
                  onCancel={() => setFile(null)}
                />

                <Button variant="square" size="xs" colorScheme="gray" onClick={() => setFile(null)}>
                  <MdClose color="#A0AEC0" />
                </Button>
              </HStack>
            </HStack>
          )}
          {!file && (
            <Button as="label" htmlFor={inputId} colorScheme="blue" size="sm" cursor="pointer">
              {t('formBuilder.documentReview.chooseFiles')}
            </Button>
          )}
        </Stack>
      </Box>
      <FormErrorMessage>{t(error?.message!)}</FormErrorMessage>
    </FormControl>
  );
}

function DocumentPdfViewer({
  url,
  file,
  onCancel = () => {},
  onUpload = () => {},
}: { url: string; file: File | null; onUpload: (url: string) => void; onCancel: () => void } & Pick<
  SchemaBuilderProps,
  'onChange' | 'defaultValue'
>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { uploadDocument } = useStoreActions((state) => state.hrFormTemplate);
  const { uploadingDocument } = useStoreState((state) => state.hrFormTemplate);

  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  React.useEffect(() => {
    if (file) onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  async function handleUpload() {
    const payload = new FormData();
    payload.append('file', file!);
    const response = await uploadDocument({ account: accountId, payload: payload });

    onUpload(response);
    onClose();
  }

  function handleCancel() {
    onCancel();
    onClose();
  }

  return (
    <>
      <Button variant="square" size="xs" colorScheme="gray" onClick={onOpen} title="Preview">
        <MdOutlineRemoveRedEye />
      </Button>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        isCentered
        closeOnOverlayClick={!uploadingDocument}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent padding={0}>
          <ModalBody padding={0} height="100%" flexGrow={1} borderRadius={theme.radii.md}>
            <iframe src={url!} width="100%" height="600px" style={{ border: 'none' }} title="PDF Preview"></iframe>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" variant="ghost" mr={3} onClick={handleCancel} disabled={uploadingDocument}>
              Close
            </Button>
            {file && (
              <Button variant="ghost" colorScheme="blue" isLoading={uploadingDocument} onClick={handleUpload}>
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function DocumentPreviewButton({ defaultValue, language, onChange, path }: SchemaBuilderProps) {
  const [value, setValue] = React.useState(defaultValue?.documentReview?.confirmText?.[language]);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const { t } = useTranslation('administration');
  const { t: tForm } = useTranslation('hrFormTemplate');
  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path: [...path, 'documentReview', 'confirmText'],
    valdationFor: 'document_preview_confirm_text',
    onValidation: (val) => val,
  });

  React.useEffect(() => {
    setValue(defaultValue?.documentReview?.confirmText?.[language]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, language]);

  // function handleChange(value: string) {
  //   validateError(value);
  //   setValue(value);
  // }

  return (
    <FormControl isInvalid={hasError}>
      <Box
        sx={{
          outline: '1px solid',
          outlineColor: theme.colors.blue[600],
          borderRadius: 50,
        }}
        _focusWithin={{
          outline: '2px solid',
          outlineColor: theme.colors.blue[600],
          backgroundColor: theme.colors.blue[100],
          transition: 'unset',
        }}
        onClick={() => setIsEditing(true)}
        width={'fit-content'}
      >
        <InputGroup>
          <Input
            ref={inputRef}
            variant="unstyled"
            value={value}
            onChange={(e) => {
              validateError(e.target.value);
              const update = {
                ...omit(defaultValue, 'documentReview'),
                documentReview: {
                  ...defaultValue?.documentReview!,
                  confirmText: { ...defaultValue?.documentReview?.confirmText!, [language]: e.target.value },
                },
              };
              onChange(update);
            }}
            width="100%"
            onBlur={() => setIsEditing(false)}
            size="md"
            placeholder={t('companyResources.consentButtonPlaceholder')}
            minWidth="fit-content"
            sx={{
              paddingX: theme.space[2],
              paddingY: theme.space[1],
              color: theme.colors.blue[600],
              fontWeight: 'bold',
              fontSize: theme.fontSizes.sm,
              textAlign: 'center',
              '&:focus': {
                outline: 'unset',
                transition: 'unset',
              },
            }}
            _focus={{
              textAlign: 'left',
            }}
            _placeholder={{ color: theme.colors.white, fontSize: theme.fontSizes.sm, fontWeight: 'normal' }}
          />
          {!isEditing && (
            <InputRightElement
              height="-webkit-fill-available"
              pointerEvents="none"
              padding={0}
              children={<MdOutlineBorderColor color={theme.colors.gray[200]} />}
            />
          )}
        </InputGroup>
      </Box>
      {/* <Button variant="outline" size="sm" colorScheme="blue" borderRadius={20}>
        <Editable
          value={value}
          submitOnBlur
          onChange={handleChange}
          onSubmit={(value) => {
            onChange({
              ...omit(defaultValue, 'documentReview'),
              documentReview: {
                ...defaultValue?.documentReview!,
                confirmText: { ...defaultValue?.documentReview?.confirmText!, [language]: value },
              },
            });
          }}
          size="sm"
          ref={inputRef}
          placeholder="Enter text here..."
        >
          <EditablePreview />
          <Input as={EditableInput} variant="unstyled" />
        </Editable>
      </Button> */}
      <FormErrorMessage>{tForm(error?.message!)}</FormErrorMessage>
    </FormControl>
  );
}
