import { Box, Checkbox, Divider, Heading, Text, Tooltip, Flex, FormControl, FormErrorMessage } from '@chakra-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import _isNil from 'lodash/isNil';
import { FormattedError, getErrorMessage, hasErrorMessage } from '../../../../utils/FormErrorUtils';
import { JobPosition } from '../PositionType';
import { Lookups } from '../../../../firebase/firestore/documents/lookups';
import { backgroundCheckForAdminstrationStyle } from '../../../messaging/seeker/header/actionsDropdown/ConfirmationModal.styles';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { BackgroundChecks } from '../../../../firebase/firestore/documents/candidate';
import useBackgroundChecks from '../../../../app/hooks/useBackgroundChecks';
import { useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

export type CertnBackgroundCheckCardProp = {
  jobPositionData: JobPosition;
  formErrors: FormattedError[];
  setBackgroundCheckValue: (checked: boolean, backgroundCheckId: string) => void;
  screeningOptions?: BackgroundChecks;
  setEnableCertnBackgroundCheck: (checked: boolean) => void;
};

export const CertnBackgroundCheckCard = ({
  jobPositionData,
  formErrors,
  setBackgroundCheckValue,
  screeningOptions,
  setEnableCertnBackgroundCheck,
}: CertnBackgroundCheckCardProp): JSX.Element => {
  const enableToggleOfBackgroundCheckOnPositionLevel: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL];
  });
  const backgroundChecks = useBackgroundChecks();
  const chekboxRef = React.useRef<HTMLInputElement>(null);

  const { t, i18n } = useTranslation();

  const hasError = hasErrorMessage(formErrors, 'screeningOptions');

  React.useEffect(() => {
    // istanbul ignore next
    if (hasError && chekboxRef.current) {
      chekboxRef.current.focus();
      chekboxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const showInfoTooltip = (id: string) => {
    let toolTipMessage = '';

    switch (id) {
      case 'request_criminal_record_check':
      case 'request_enhanced_criminal_record_check':
        toolTipMessage = t('certn:tooltipMessageForCanadianBaseRecordCheck');
        break;
      default:
        toolTipMessage = t('certn:tooltipMessageForUSBasedRecordCheck');
    }
    return (
      <Box ml={1}>
        <Tooltip hasArrow label={toolTipMessage} bg="gray.900" fontSize="sm" placement="right">
          <InfoOutlineIcon />
        </Tooltip>
      </Box>
    );
  };

  const checkListItem = (bc: Lookups) => {
    const valueCheck: boolean = !_isNil(jobPositionData?.screeningOptions)
      ? jobPositionData?.screeningOptions[bc.id as keyof BackgroundChecks]
      : false;

    const shouldDisable = !_isNil(screeningOptions) ? screeningOptions[bc.id as keyof BackgroundChecks] : false;

    return (
      <Box mb={2} key={bc.id} w="50%" ml={0}>
        <Box className="bc-content" css={backgroundCheckForAdminstrationStyle}>
          <Checkbox
            data-testid="CheckboxTest"
            colorScheme="blue"
            key={bc.id}
            value={bc.id}
            onChange={(e) => setBackgroundCheckValue(e.target.checked, bc.id)}
            isChecked={valueCheck}
            alignItems="flex-start"
            isDisabled={!shouldDisable ?? /* istanbul ignore next */ false}
            ref={chekboxRef}
          >
            <Box pl="10px" mt="-1px">
              <Heading d="flex" as="h4" lineHeight="1.1" marginBottom="5px" fontSize="md">
                {getTranslatedValue(bc.label, i18n.language)}
                {(bc.id === 'request_criminal_record_check' ||
                  bc.id === 'request_enhanced_criminal_record_check' ||
                  bc.id === 'request_us_criminal_record_check_tier_1' ||
                  bc.id === 'request_us_criminal_record_check_tier_2') &&
                  showInfoTooltip(bc.id)}
              </Heading>
            </Box>
          </Checkbox>
        </Box>
      </Box>
    );
  };

  return (
    <Box backgroundColor="white" borderRadius={5} mb={6}>
      {enableToggleOfBackgroundCheckOnPositionLevel && (
        <Box px={6} py={0}>
          <FormControl isInvalid={hasErrorMessage(formErrors, 'screeningOptions')}>
            <Checkbox
              isChecked={jobPositionData.enableScreeningOptions}
              onChange={(e) => setEnableCertnBackgroundCheck(e.target.checked)}
              data-testid="enableCertnIntegrationField"
            >
              {t('createJobPosition:enabledCertnIntegration')}
            </Checkbox>
            <FormErrorMessage>{getErrorMessage(formErrors, 'screeningOptions')}</FormErrorMessage>
          </FormControl>
        </Box>
      )}

      {jobPositionData.enableScreeningOptions && (
        <Box>
          <Box px={6} py={4}>
            <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
              {t('createJobPosition:certnIntegration')}
            </Heading>
            <Text fontSize="xs" color="gray.500">
              {t('createJobPosition:certnIntegrationSubtitle')}
            </Text>
          </Box>
          <Divider borderColor="#ECEFF1" />
          <Box p={6}>
            <Flex flexDirection="row" flexWrap="wrap" mb={3}>
              <Box mb={2} w="50%" ml={0}>
                <Box className="bc-content" css={backgroundCheckForAdminstrationStyle}>
                  <Checkbox
                    data-testid="CheckboxSoquij"
                    value="request_soquij"
                    onChange={(e) => setBackgroundCheckValue(e.target.checked, 'request_soquij')}
                    colorScheme="blue"
                    alignItems="flex-start"
                    isDisabled={!screeningOptions?.request_soquij}
                    isChecked={!screeningOptions?.request_soquij ? false : jobPositionData.screeningOptions?.request_soquij}
                  >
                    <Box pl="10px" mt="-1px">
                      <Heading d="flex" as="h4" lineHeight="1.1" marginBottom="5px" fontSize="md">
                        <Trans i18nKey="certn:soquijEnableLabel">
                          <Box fontWeight="bold" as="span" mr={2}>
                            SOQUIJ
                          </Box>
                        </Trans>
                        <Tooltip
                          hasArrow
                          label={t('certn:soquijTooltipMessage')}
                          fontSize="sm"
                          placement="right"
                          bg="gray.900"
                          borderRadius="3px"
                        >
                          <InfoOutlineIcon />
                        </Tooltip>
                      </Heading>
                    </Box>
                  </Checkbox>
                </Box>
              </Box>
              {backgroundChecks.length > 0 &&
                backgroundChecks.sort((a, b) => (a.order > b.order ? 1 : -1)).map((bc) => checkListItem(bc))}
            </Flex>
          </Box>
        </Box>
      )}
    </Box>
  );
};
