/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* istanbul ignore file */
import React, { Fragment, useState } from 'react';
import { Flex, Box, Text, Heading, Button, Tab, TabList, Tabs, TabPanels, TabPanel, useToast } from '@chakra-ui/core';
import { RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from '@chakra-ui/slider';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import colors from '../../../styles/colors';
import { useStoreActions } from '../../../models/hooks';
import { FitbandCalculationConfiguration } from './FitbandConfigurationView';
import { CandidateShifts, FitbandResultData, FitbandType, Shifts } from './FitbandConfigurationTypes';

type AvailabilityScreenProps = {
  setTestingOpen: (isTestingOpen: boolean) => void;
  templateData: FitbandType;
  penaltyData: FitbandCalculationConfiguration;
  accountId: string | undefined;
  formValidation: (formData: any, scheme: Joi.ObjectSchema) => boolean;
  setRequisitionSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setExtraSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  requisitionEnabled: boolean | undefined;
};

enum ShiftTabs {
  OnlyShift,
  Shift1,
  Shift2,
}

enum AvailabilityTabs {
  Day,
  Night,
}

type ScoreProps = {
  fitbandResult: FitbandResultData | undefined;
  isLoading: boolean;
  handleEvaluation: () => void;
};

export const AvailabilityEvaluation = ({
  setTestingOpen,
  templateData,
  penaltyData,
  accountId,
  formValidation,
  setRequisitionSubmitted,
  setExtraSubmitted,
  setFormSubmitted,
  requisitionEnabled,
}: AvailabilityScreenProps): JSX.Element => {
  const { t } = useTranslation('administration');
  const toast = useToast();
  const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
  const weekEnds = ['sat', 'sun'];

  const { evaluateFitbandTemplate } = useStoreActions((actions) => actions.fitbandTemplate);

  const [selectedShift, setSelectedShift] = useState<ShiftTabs>(requisitionEnabled ? ShiftTabs.Shift1 : ShiftTabs.OnlyShift);
  const [selectedAvailability, setSelectedAvailability] = useState<AvailabilityTabs>(AvailabilityTabs.Day);
  const [selectedReqDaysShift1, setSelectedReqDaysShift1] = useState<string[]>([]);
  const [selectedReqDaysShift2, setSelectedReqDaysShift2] = useState<string[]>([]);
  const [selectedCandidateDayShifts, setSelectedCandidateDayShifts] = useState<string[]>([]);
  const [selectedCandidateNightShifts, setSelectedCandidateNightShifts] = useState<string[]>([]);

  const [shiftAvailabilities, setShiftAvailabilities] = useState<Shifts>({});
  const [seekerAvailabilities, setSeekerAvailabilities] = useState<CandidateShifts>({});
  const [nightAvailabilities, setNightAvailabilities] = useState<CandidateShifts>({});

  const [resultData, setResultData] = useState<FitbandResultData | undefined>(undefined);
  const [isResultAvailable, setIsResultAvailable] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isLoadingAgain, setIsLoadingAgain] = useState<boolean>(false);
  const [isBtnClicked, setIsBtnClicked] = useState<boolean>(false);

  const onHandleClose = () => {
    setTestingOpen(false);
  };

  const fitbandTestSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      goodFit: Joi.number().integer().min(1).max(100).required(),
      mediumFit: Joi.alternatives()
        .try(
          Joi.number()
            .integer()
            .min(1)
            .max(100)
            .custom((value, helpers) => {
              if (value >= helpers.state.ancestors[0].goodFit) {
                return helpers.error('mediumFit.invalid');
              }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return value;
            }),
        )
        .allow(null, '')
        .messages({
          'mediumFit.invalid': t('fitbandConfiguration.fitbandModal.mediumFitError'),
          'number.min': '',
          'any.required': '',
        }),

      // Adding `requisitionChecked` to the schema
      requisitionChecked: Joi.boolean().required(),
      availabilityChecked: Joi.boolean().required(),

      reqDays: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('requisitionChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),

      reqHours: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('requisitionChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),

      extraDays: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('availabilityChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),

      extraHours: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('availabilityChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),
    });

  const handleRequisitionDayClick = (day: string) => {
    if (selectedShift === ShiftTabs.Shift1 || selectedShift === ShiftTabs.OnlyShift) {
      setSelectedReqDaysShift1((prevDays) => {
        const isDaySelected = prevDays.includes(day);
        const updatedDays = isDaySelected
          ? prevDays.filter((selectedDay) => selectedDay !== day) // Remove day
          : [...prevDays, day];

        setShiftAvailabilities((prev) => {
          const priorData = prev[day] || {}; // existing shift2 values
          const updatedAvailabilities = { ...prev };

          if (!isDaySelected) {
            // when selecting the day, initialize with default values
            updatedAvailabilities[day] = {
              ...priorData,
              start_1: '09:00',
              end_1: '17:00',
            };
          } else {
            delete updatedAvailabilities[day].start_1;
            delete updatedAvailabilities[day].end_1;
          }

          return updatedAvailabilities;
        });

        return updatedDays;
      });
    } else if (selectedShift === ShiftTabs.Shift2) {
      setSelectedReqDaysShift2((prevDays) => {
        const isDaySelected = prevDays.includes(day);
        const updatedDays = isDaySelected ? prevDays.filter((selectedDay) => selectedDay !== day) : [...prevDays, day];

        setShiftAvailabilities((prev) => {
          const priorData = prev[day] || {};
          const updatedAvailabilities = { ...prev };

          if (!isDaySelected) {
            // when selecting the day, initialize with default values
            updatedAvailabilities[day] = {
              ...priorData,
              start_2: '17:00',
              end_2: '23:00',
            };
          } else {
            delete updatedAvailabilities[day];
          }
          return updatedAvailabilities;
        });

        return updatedDays;
      });
    }
  };

  const handleCandidateDayClick = (day: string) => {
    if (selectedAvailability === AvailabilityTabs.Day) {
      setSelectedCandidateDayShifts((prevSelectedDays) => {
        const isDayShiftSelected = prevSelectedDays.includes(day);
        const updatedDays = isDayShiftSelected
          ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
          : [...prevSelectedDays, day];

        setSeekerAvailabilities((prev) => {
          const prevData = prev[day] || {};
          const updatedCandidateDayAvailabilities = { ...prev };

          if (!isDayShiftSelected) {
            // when selecting the day, initialize with default values
            updatedCandidateDayAvailabilities[day] = {
              ...prevData,
              start_1: '09:00',
              end_1: '17:00',
            };
          } else {
            delete updatedCandidateDayAvailabilities[day];
          }
          return updatedCandidateDayAvailabilities;
        });

        return updatedDays;
      });
    } else if (selectedAvailability === AvailabilityTabs.Night) {
      setSelectedCandidateNightShifts((prevSelectedDays) => {
        const isNightShiftSelected = prevSelectedDays.includes(day);
        const updatedNights = isNightShiftSelected
          ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
          : [...prevSelectedDays, day];

        setNightAvailabilities((prev) => {
          const prevData = prev[day] || {};
          const updatedCandidateNightAvailabilities = { ...prev };

          // when selecting the day, initialize with default values
          if (!isNightShiftSelected) {
            updatedCandidateNightAvailabilities[day] = {
              ...prevData,
              start_1: '17:00',
              end_1: '23:00',
            };
          } else {
            delete updatedCandidateNightAvailabilities[day];
          }
          return updatedCandidateNightAvailabilities;
        });

        return updatedNights;
      });
    }
  };

  // render UI
  const renderRequisitionShiftOne = () => {
    const allDayRequisition = [...weekDays, ...weekEnds];

    return (
      <Fragment>
        <Box mb={4}>
          {allDayRequisition.map((day) => (
            <Box
              key={day}
              as="button"
              px={1}
              py={2}
              borderRadius="30px"
              backgroundColor={selectedReqDaysShift1.includes(day) ? 'blue.default' : colors.white}
              mr={2}
              mt={3}
              width="45px"
              color={selectedReqDaysShift1.includes(day) ? colors.white : colors.black}
              fontSize="12px"
              fontWeight={400}
              textTransform="capitalize"
              borderWidth="1px"
              borderColor={colors.input.borderColor}
              onClick={() => handleRequisitionDayClick(day)}
            >
              {t(`common:days.${day}`)}
            </Box>
          ))}
        </Box>

        {/* rendering only when selected */}
        {allDayRequisition.map((day) => {
          const isSelected = selectedReqDaysShift1.includes(day);
          if (!isSelected) return null; // Hide if not selected

          const currentShiftAvailabilities = shiftAvailabilities[day] || {};

          return (
            <Box ml="5px" w="100%" key={day}>
              <Flex w="100%" justifyContent="flex-start" alignItems="center">
                <Text w="15%" fontSize="14px" fontWeight={700} textTransform="capitalize">
                  {t(`common:days.${day}`)}
                </Text>
                <RangeSlider
                  w="85%"
                  defaultValue={[36, 68]}
                  value={
                    currentShiftAvailabilities.start_1 && currentShiftAvailabilities.end_1
                      ? [
                          parseInt(currentShiftAvailabilities.start_1.split(':')[0]) * 4 +
                            (currentShiftAvailabilities.start_1.includes(':30') ? 2 : 0),
                          parseInt(currentShiftAvailabilities.end_1.split(':')[0]) * 4 +
                            (currentShiftAvailabilities.end_1.includes(':30') ? 2 : 0),
                        ]
                      : [36, 68]
                  }
                  bg="white"
                  data-testid="shift1Slider"
                  max={96}
                  min={0}
                  minStepsBetweenThumbs={2}
                  step={2}
                  onChange={(value: number[]) => {
                    setShiftAvailabilities((prev) => {
                      // If the day is unchecked, remove it from shiftAvailabilities
                      if (!selectedReqDaysShift1.includes(day)) {
                        const updatedAvailabilities = { ...prev };
                        delete updatedAvailabilities[day];
                        return updatedAvailabilities;
                      }
                      const updatedShiftAvailabilities: {
                        start_1: string;
                        end_1: string;
                        start_2?: string;
                        end_2?: string;
                      } = {
                        start_1: `${Math.floor(value[0] / 4)
                          .toString()
                          .padStart(2, '0')}${value[0] % 4 === 2 ? ':30' : ':00'}`,
                        end_1: `${Math.floor(value[1] / 4)
                          .toString()
                          .padStart(2, '0')}${value[1] % 4 === 2 ? ':30' : ':00'}`,
                      };

                      if (selectedReqDaysShift2.includes(day)) {
                        updatedShiftAvailabilities.start_2 = prev[day]?.start_2 || '00:00';
                        updatedShiftAvailabilities.end_2 = prev[day]?.end_2 || '00:00';
                      }

                      return {
                        ...prev,
                        [day]: updatedShiftAvailabilities,
                      };
                    });
                  }}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack
                      bg={currentShiftAvailabilities ? colors.blue.default : colors.gray[200]}
                      w="85%"
                      p={1}
                      color="white"
                    />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} bg="#1F3CBA" />
                  <RangeSliderThumb index={1} bg="#1F3CBA" />
                </RangeSlider>
              </Flex>
              <Flex w="100%" justifyContent="space-between" mb="10px">
                <Box w="15%" />
                <Flex w="85%" justifyContent="space-between">
                  <Box style={{ width: 'fit-content', fontSize: '12px', color: '#36464E' }}>
                    {currentShiftAvailabilities.start_1
                      ? moment(currentShiftAvailabilities.start_1, ['HH:mm']).format('LT')
                      : moment('09:00', ['HH:mm']).format('LT')}
                  </Box>
                  <Box style={{ width: 'fit-content', marginRight: '10px', fontSize: '12px', color: '#36464E' }}>
                    {currentShiftAvailabilities.end_1
                      ? moment(currentShiftAvailabilities.end_1, ['HH:mm']).format('LT')
                      : moment('17:00', ['HH:mm']).format('LT')}
                  </Box>
                </Flex>
              </Flex>
            </Box>
          );
        })}
      </Fragment>
    );
  };

  const renderRequisitionShiftTwo = () => {
    const allDayRequisition = [...weekDays, ...weekEnds];
    return (
      <Fragment>
        <Box mb={4}>
          {allDayRequisition.map((day) => (
            <Box
              key={day}
              as="button"
              px={1}
              py={2}
              borderRadius="30px"
              backgroundColor={selectedReqDaysShift2.includes(day) ? 'blue.default' : colors.white}
              mr={2}
              mt={3}
              width="45px"
              color={selectedReqDaysShift2.includes(day) ? colors.white : colors.black}
              fontSize="12px"
              fontWeight={400}
              textTransform="capitalize"
              borderWidth="1px"
              borderColor={colors.input.borderColor}
              onClick={() => {
                if (isBtnClicked) return;
                setIsBtnClicked(true);
                if (!selectedReqDaysShift1.includes(day)) {
                  toast({
                    title: `You must select Shift 1 '${day}' at first.`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                  setTimeout(() => {
                    setIsBtnClicked(false);
                  }, 1000);
                  return;
                }
                handleRequisitionDayClick(day);
                setIsBtnClicked(false);
              }}
            >
              {t(`common:days.${day}`)}
            </Box>
          ))}
        </Box>
        <Box>
          {allDayRequisition.map((day) => {
            const isSelected = selectedReqDaysShift2.includes(day);
            if (!isSelected) return null;
            const currentShift2Availabilities = shiftAvailabilities[day] || {};
            return (
              <Box ml="5px" w="100%" key={day}>
                <Flex w="100%" justifyContent="flex-start" alignItems="center">
                  <Text w="15%" fontSize="14px" fontWeight={700} textTransform="capitalize">
                    {t(`common:days.${day}`)}
                  </Text>
                  <RangeSlider
                    w="85%"
                    isDisabled={!isSelected}
                    defaultValue={[68, 94]}
                    value={
                      currentShift2Availabilities.start_2 && currentShift2Availabilities.end_2
                        ? [
                            (parseInt(currentShift2Availabilities.start_2.split(':')[0]) % 24) * 4 +
                              (currentShift2Availabilities.start_2.includes(':30') ? 2 : 0) +
                              (parseInt(currentShift2Availabilities.start_2.split(':')[0]) < 12 ? 96 : 0),
                            (parseInt(currentShift2Availabilities.end_2.split(':')[0]) % 24) * 4 +
                              (currentShift2Availabilities.end_2.includes(':30') ? 2 : 0) +
                              (parseInt(currentShift2Availabilities.end_2.split(':')[0]) <= 12 ? 96 : 0),
                          ]
                        : [68, 94]
                    }
                    bg="white"
                    data-testid="shift2Slider"
                    max={144}
                    min={48}
                    minStepsBetweenThumbs={2}
                    step={2}
                    onChange={(value: number[]) => {
                      const shift1End = currentShift2Availabilities?.end_1 || '00:00';
                      const shift1EndMinutes = parseInt(shift1End.split(':')[0]) * 60 + (shift1End.includes(':30') ? 30 : 0);

                      const shift2StartMinutes = (value[0] / 4) * 60; // Convert index back to minutes

                      if (shift2StartMinutes < shift1EndMinutes) {
                        return; // Prevent setting shift2 start before shift1 end
                      }

                      setShiftAvailabilities((prev) => ({
                        ...prev,
                        [day]: {
                          ...prev[day],
                          start_2: `${((Math.floor(value[0] / 4) + 24) % 24).toString().padStart(2, '0')}${
                            value[0] % 4 === 2 ? ':30' : ':00'
                          }`,
                          end_2: `${((Math.floor(value[1] / 4) + 24) % 24).toString().padStart(2, '0')}${
                            value[1] % 4 === 2 ? ':30' : ':00'
                          }`,
                        },
                      }));
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack
                        bg={currentShift2Availabilities ? colors.blue.default : colors.gray[200]}
                        w="85%"
                        p={1}
                        color="white"
                      />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} bg="#1F3CBA" />
                    <RangeSliderThumb index={1} bg="#1F3CBA" />
                  </RangeSlider>
                </Flex>
                <Flex w="100%" justifyContent="space-between" mb="10px">
                  <Box w="15%" />
                  <Flex w="85%" justifyContent="space-between">
                    <Box
                      style={{
                        width: 'fit-content',
                        fontSize: '12px',
                        color: '#36464E',
                      }}
                    >
                      {currentShift2Availabilities.start_2
                        ? moment(currentShift2Availabilities.start_2, ['HH:mm']).format('LT')
                        : moment('17:00', ['HH:mm']).format('LT')}
                    </Box>
                    <Box
                      style={{
                        width: 'fit-content',
                        marginRight: '10px',
                        fontSize: '12px',
                        color: '#36464E',
                      }}
                    >
                      {currentShift2Availabilities.end_2
                        ? moment(currentShift2Availabilities.end_2, ['HH:mm']).format('LT')
                        : moment('23:00', ['HH:mm']).format('LT')}
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        </Box>
      </Fragment>
    );
  };

  const renderCandidateDayAvailability = () => {
    const allDayAvailabilities = [...weekDays, ...weekEnds];
    return (
      <Fragment>
        <Box mb={4}>
          {allDayAvailabilities.map((day) => (
            <Box
              key={day}
              as="button"
              px={1}
              py={2}
              borderRadius="30px"
              backgroundColor={selectedCandidateDayShifts.includes(day) ? colors.orange[500] : colors.white}
              mr={2}
              mt={3}
              width="45px"
              color={selectedCandidateDayShifts.includes(day) ? colors.white : colors.black}
              fontSize="12px"
              fontWeight={400}
              textTransform="capitalize"
              borderWidth="1px"
              borderColor={colors.input.borderColor}
              onClick={() => handleCandidateDayClick(day)}
            >
              {t(`common:days.${day}`)}
            </Box>
          ))}
        </Box>
        <Box>
          {allDayAvailabilities.map((day) => {
            const isSelected = selectedCandidateDayShifts.includes(day);
            if (!isSelected) return null;
            const currentDayAvailabilities = seekerAvailabilities[day] || {};

            return (
              <Box ml="5px" w="100%" key={day}>
                <Flex w="100%" justifyContent="flex-start" alignItems="center">
                  <Text w="15%" fontSize="14px" fontWeight={700} textTransform="capitalize">
                    {t(`common:days.${day}`)}
                  </Text>
                  <RangeSlider
                    w="85%"
                    isDisabled={!isSelected}
                    defaultValue={[36, 68]}
                    value={
                      currentDayAvailabilities.start_1 && currentDayAvailabilities.end_1
                        ? [
                            parseInt(currentDayAvailabilities.start_1.split(':')[0]) * 4 +
                              (currentDayAvailabilities.start_1.includes(':30') ? 2 : 0),
                            parseInt(currentDayAvailabilities.end_1.split(':')[0]) * 4 +
                              (currentDayAvailabilities.end_1.includes(':30') ? 2 : 0),
                          ]
                        : [36, 68]
                    }
                    bg="white"
                    data-testid="DaySlider"
                    max={96}
                    min={0}
                    minStepsBetweenThumbs={2}
                    step={2}
                    onChange={(value: number[]) => {
                      setSeekerAvailabilities((prev) => ({
                        ...prev,

                        [day]: {
                          start_1: `${Math.floor(value[0] / 4)
                            .toString()
                            .padStart(2, '0')}${value[0] % 4 === 2 ? ':30' : ':00'}`,
                          end_1: `${Math.floor(value[1] / 4)
                            .toString()
                            .padStart(2, '0')}${value[1] % 4 === 2 ? ':30' : ':00'}`,
                        },
                      }));
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack
                        bg={currentDayAvailabilities ? colors.orange[500] : colors.gray[200]}
                        w="85%"
                        p={1}
                        color="white"
                      />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} bg={colors.orange[700]} />
                    <RangeSliderThumb index={1} bg={colors.orange[700]} />
                  </RangeSlider>
                </Flex>
                <Flex w="100%" justifyContent="space-between" mb="10px">
                  <Box w="15%" />
                  <Flex w="85%" justifyContent="space-between">
                    <Box
                      style={{
                        width: 'fit-content',
                        fontSize: '12px',
                        color: '#36464E',
                      }}
                    >
                      {currentDayAvailabilities.start_1
                        ? moment(currentDayAvailabilities.start_1, ['HH:mm']).format('LT')
                        : moment('09:00', ['HH:mm']).format('LT')}
                    </Box>
                    <Box
                      style={{
                        width: 'fit-content',
                        marginRight: '10px',
                        fontSize: '12px',
                        color: '#36464E',
                      }}
                    >
                      {currentDayAvailabilities.end_1
                        ? moment(currentDayAvailabilities.end_1, ['HH:mm']).format('LT')
                        : moment('17:00', ['HH:mm']).format('LT')}
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        </Box>
      </Fragment>
    );
  };

  const renderCandidateNightAvailability = () => {
    const allDayAvailabilities = [...weekDays, ...weekEnds];

    return (
      <Fragment>
        <Box mb={4}>
          {allDayAvailabilities.map((day) => (
            <Box
              key={day}
              as="button"
              px={1}
              py={2}
              borderRadius="30px"
              backgroundColor={selectedCandidateNightShifts.includes(day) ? colors.orange[500] : colors.white}
              mr={2}
              mt={3}
              width="45px"
              color={selectedCandidateNightShifts.includes(day) ? colors.white : colors.black}
              fontSize="12px"
              fontWeight={400}
              textTransform="capitalize"
              borderWidth="1px"
              borderColor={colors.input.borderColor}
              onClick={() => handleCandidateDayClick(day)}
            >
              {t(`common:days.${day}`)}
            </Box>
          ))}
        </Box>

        <Box>
          {allDayAvailabilities.map((day) => {
            const isSelected = selectedCandidateNightShifts.includes(day);
            if (!isSelected) return null;
            const currentNightAvailabilities = nightAvailabilities[day] || {};

            return (
              <Box ml="5px" w="100%" key={day}>
                <Flex w="100%" justifyContent="flex-start" alignItems="center">
                  <Text w="15%" fontSize="14px" fontWeight={700} textTransform="capitalize">
                    {t(`common:days.${day}`)}
                  </Text>
                  <RangeSlider
                    w="85%"
                    isDisabled={!isSelected}
                    defaultValue={[68, 94]} // Default 12 PM - 11 AM (Next Day)
                    value={
                      currentNightAvailabilities.start_1 && currentNightAvailabilities.end_1
                        ? [
                            (parseInt(currentNightAvailabilities.start_1.split(':')[0]) % 24) * 4 +
                              (currentNightAvailabilities.start_1.includes(':30') ? 2 : 0) +
                              (parseInt(currentNightAvailabilities.start_1.split(':')[0]) < 12 ? 96 : 0),
                            (parseInt(currentNightAvailabilities.end_1.split(':')[0]) % 24) * 4 +
                              (currentNightAvailabilities.end_1.includes(':30') ? 2 : 0) +
                              (parseInt(currentNightAvailabilities.end_1.split(':')[0]) <= 12 ? 96 : 0),
                          ]
                        : [68, 94]
                    }
                    data-testid="NightSlider"
                    min={48} // Start from 12:00 PM (Noon)
                    max={144} // End at 11:00 AM (Next Day)
                    step={2} // 30-minute steps
                    minStepsBetweenThumbs={2}
                    onChange={(value: number[]) => {
                      setNightAvailabilities((prev) => ({
                        ...prev,
                        [day]: {
                          start_1: `${((Math.floor(value[0] / 4) + 24) % 24).toString().padStart(2, '0')}${
                            value[0] % 4 === 2 ? ':30' : ':00'
                          }`,
                          end_1: `${((Math.floor(value[1] / 4) + 24) % 24).toString().padStart(2, '0')}${
                            value[1] % 4 === 2 ? ':30' : ':00'
                          }`,
                        },
                      }));
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack
                        bg={currentNightAvailabilities ? colors.orange[500] : colors.gray[200]}
                        w="85%"
                        p={1}
                        color="white"
                      />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} bg={colors.orange[700]} />
                    <RangeSliderThumb index={1} bg={colors.orange[700]} />
                  </RangeSlider>
                </Flex>

                <Flex w="100%" justifyContent="space-between" mb="10px">
                  <Box w="15%" />
                  <Flex w="85%" justifyContent="space-between">
                    <Box style={{ width: 'fit-content', fontSize: '12px', color: '#36464E' }}>
                      {currentNightAvailabilities.start_1
                        ? moment(currentNightAvailabilities.start_1, ['HH:mm']).format('LT')
                        : moment('17:00', ['HH:mm']).format('LT')}
                    </Box>
                    <Box style={{ width: 'fit-content', marginRight: '10px', fontSize: '12px', color: '#36464E' }}>
                      {currentNightAvailabilities.end_1
                        ? moment(currentNightAvailabilities.end_1, ['HH:mm']).format('LT')
                        : moment('23:00', ['HH:mm']).format('LT')}
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        </Box>
      </Fragment>
    );
  };

  // handling evaluation
  const handleTemplateEvaluation = async () => {
    setFormSubmitted(true);
    if (penaltyData.requisitionChecked) {
      setRequisitionSubmitted(true);
    }

    if (penaltyData.availabilityChecked) {
      setExtraSubmitted(true);
    }

    const templateDataToVerify = {
      ...templateData,
      ...penaltyData,
    };

    const testDataToVerify = _omit(templateDataToVerify, ['name']);
    const testValidate = formValidation(testDataToVerify, fitbandTestSchema);

    if (!testValidate) return;

    const payload = {
      fitBandCalculationArg: {
        seeker: {
          nightAvailabilities,
        },
        shifts: shiftAvailabilities,
        positionData: {
          fitBandCalculationConfiguration: {
            shouldPenalizeExtraTime: penaltyData.availabilityChecked,
            penalizeFactorExtraDays: !isEmpty(penaltyData.extraDays) ? penaltyData.extraDays : 0,
            penalizeFactorExtraHours: !isEmpty(penaltyData.extraHours) ? penaltyData.extraHours : 0,
            shouldPenalizeRequiredTime: penaltyData.requisitionChecked,
            penalizeFactorRequiredDays: !isEmpty(penaltyData.reqDays) ? penaltyData.reqDays : 0,
            penalizeFactorRequiredHours: !isEmpty(penaltyData.reqHours) ? penaltyData.reqHours : 0,
          },
          fitBand: {
            mediumFit: templateData.mediumFit,
            goodFit: templateData.goodFit,
          },
        },
        account: {},
        seekerAvailabilities,
      },
    };

    setIsBtnLoading(true);
    setIsLoadingAgain(true);

    try {
      if (accountId) {
        const res = await evaluateFitbandTemplate({ accountId, fitbandData: payload });
        setResultData(res);
        setIsResultAvailable(true);
      }
    } catch (err) {
      console.error('Error testing templates-=-=-', err);
      throw err;
    } finally {
      setIsBtnLoading(false);
      setIsLoadingAgain(false);
    }
  };

  const handleReset = () => {
    setSelectedReqDaysShift1([]);
    setSelectedReqDaysShift2([]);
    setSelectedCandidateDayShifts([]);
    setSelectedCandidateNightShifts([]);
    setShiftAvailabilities({});
    setSeekerAvailabilities({});
    setNightAvailabilities({});
    setIsResultAvailable(false);
  };

  return (
    <Box p={1} mt={4}>
      <Flex justifyContent="space-between" alignItems="center" pb={2} borderBottom="1px solid #e2e8f0">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('fitbandConfiguration.fitbandModal.evaluateYourAvailability')}
        </Heading>
        <Box data-testid="closeBtn" onClick={onHandleClose} cursor="pointer">
          <IoMdClose size={20} color={colors.black} />
        </Box>
      </Flex>

      <Flex width="100%" mt={5}>
        <Box width="50%">
          <Text fontSize="14px" fontWeight={700}>
            {t('fitbandConfiguration.fitbandModal.adjustCompany')}
          </Text>
          <Box width="98%">
            <Tabs colorScheme="blue">
              {requisitionEnabled ? (
                <>
                  <TabList
                    borderWidth={0}
                    borderBottomWidth="0.5px"
                    borderBottomColor={colors.gray[50]}
                    backgroundColor={colors.white}
                  >
                    <Tab
                      onClick={() => setSelectedShift(ShiftTabs.Shift1)}
                      data-testid="company-shift1-tab"
                      py={3}
                      fontSize="sm"
                      width="48%"
                      _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      {t('fitbandConfiguration.fitbandModal.shift1')}
                    </Tab>
                    <Tab
                      onClick={() => setSelectedShift(ShiftTabs.Shift2)}
                      data-testid="company-shift2-tab"
                      p={0}
                      fontSize="sm"
                      width="48%"
                      _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      {t('fitbandConfiguration.fitbandModal.shift2')}
                    </Tab>
                  </TabList>

                  <TabPanels borderRadius={5} minHeight="150px">
                    <TabPanel p={0}>
                      <Box>{selectedShift === ShiftTabs.Shift1 && renderRequisitionShiftOne()}</Box>
                    </TabPanel>
                    <TabPanel p={0}>
                      <Box>{selectedShift === ShiftTabs.Shift2 && renderRequisitionShiftTwo()}</Box>
                    </TabPanel>
                  </TabPanels>
                </>
              ) : (
                <>
                  <TabList
                    borderWidth={0}
                    borderBottomWidth="0.5px"
                    borderBottomColor={colors.gray[50]}
                    backgroundColor={colors.white}
                  >
                    <Tab
                      onClick={() => setSelectedShift(ShiftTabs.OnlyShift)}
                      data-testid="company-shift1-tab"
                      py={3}
                      fontSize="sm"
                      width="100%"
                      _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      {t('fitbandConfiguration.fitbandModal.shiftOnly')}
                    </Tab>
                  </TabList>

                  <TabPanels borderRadius={5} minHeight="150px">
                    <TabPanel p={0}>
                      <Box>{selectedShift === ShiftTabs.OnlyShift && renderRequisitionShiftOne()}</Box>
                    </TabPanel>
                  </TabPanels>
                </>
              )}
            </Tabs>
          </Box>
        </Box>

        <Box width="50%" borderLeft="1px solid #e2e8f0">
          <Text fontSize="14px" fontWeight={700} ml={4}>
            {t('fitbandConfiguration.fitbandModal.adjustCandidate')}
          </Text>
          <Box width="96%" ml={4}>
            <Tabs colorScheme="blue">
              <TabList
                borderWidth={0}
                px={2}
                borderBottomWidth="0.5px"
                borderBottomColor={colors.gray[50]}
                backgroundColor={colors.white}
              >
                <Tab
                  onClick={() => setSelectedAvailability(AvailabilityTabs.Day)}
                  data-testid="dayTab"
                  py={3}
                  fontSize="sm"
                  width="48%"
                  _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
                  _focus={{ boxShadow: 'none' }}
                >
                  {t('fitbandConfiguration.fitbandModal.day')}
                </Tab>
                <Tab
                  onClick={() => setSelectedAvailability(AvailabilityTabs.Night)}
                  data-testid="nightTab"
                  p={0}
                  fontSize="sm"
                  width="48%"
                  _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
                  _focus={{ boxShadow: 'none' }}
                >
                  {t('fitbandConfiguration.fitbandModal.night')}
                </Tab>
              </TabList>

              <TabPanels borderRadius={5}>
                <TabPanel p={0}>
                  <Box>{selectedAvailability === AvailabilityTabs.Day && renderCandidateDayAvailability()}</Box>
                </TabPanel>
                <TabPanel p={0}>
                  <Box>{selectedAvailability === AvailabilityTabs.Night && renderCandidateNightAvailability()}</Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Flex>

      <Box>
        <Flex justifyContent="flex-end" alignItems="center" mt={5}>
          <Button data-testid="resetBtn" color="#EDF2F7" textColor="black" onClick={handleReset}>
            {t('fitbandConfiguration.fitbandModal.resetBtn')}
          </Button>
          <Button
            data-testid="evaluateBtn"
            colorScheme="blue"
            isLoading={isBtnLoading}
            ml={3}
            disabled={
              (isEmpty(selectedReqDaysShift1) && isEmpty(selectedReqDaysShift2)) ||
              (isEmpty(selectedCandidateDayShifts) && isEmpty(selectedCandidateNightShifts)) ||
              isBtnLoading ||
              isLoadingAgain
            }
            onClick={() => handleTemplateEvaluation()}
          >
            {t('fitbandConfiguration.fitbandModal.evaluateBtn')}
          </Button>
        </Flex>
      </Box>
      {isResultAvailable && (
        <ScoreCard isLoading={isLoadingAgain} handleEvaluation={handleTemplateEvaluation} fitbandResult={resultData} />
      )}
    </Box>
  );
};

export const ScoreCard = ({ fitbandResult, isLoading, handleEvaluation }: ScoreProps): JSX.Element => {
  const { t } = useTranslation('administration');
  const getColorByText = (text: string | undefined) => {
    switch (text) {
      case 'BAD':
        return colors.red[500];
      case 'MEDIUM':
        return colors.orange[500];
      default:
        return colors.green[500];
    }
  };

  const getBackgroundColor = (text: string | undefined) => {
    switch (text) {
      case 'BAD':
        return 'rgba(229, 62, 62, 0.1)';
      case 'MEDIUM':
        return 'rgba(255, 102, 0, 0.1)';
      default:
        return 'rgba(240, 255, 244, 1)';
    }
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={5}>
      <Text color="#718096">{t('fitbandConfiguration.fitbandModal.resultTitle')}</Text>
      <Box
        width="160px"
        height="160px"
        backgroundColor={getBackgroundColor(fitbandResult?.fit)}
        borderRadius="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={5}
        mb={5}
      >
        <Heading fontSize="48px" fontWeight={700} color={getColorByText(fitbandResult?.fit)}>
          {fitbandResult?.totalPoints}
        </Heading>
      </Box>
      <Text fontSize="14px" fontWeight={400}>
        {t('fitbandConfiguration.fitbandModal.basedOnScore')}
      </Text>
      <Box justifyContent="center" alignItems="center" mt="5px">
        <Text fontWeight={400} fontSize="14px">
          {t('fitbandConfiguration.fitbandModal.fitbandStartText')}{' '}
          <Text as="span" fontSize="24px" fontWeight={700} color={getColorByText(fitbandResult?.fit)}>
            {t(`fitbandConfiguration.fitbandModal.${fitbandResult?.fit}`)}
          </Text>
          {t('fitbandConfiguration.fitbandModal.fitbandEndText')}
        </Text>
      </Box>
      <Button
        data-testid="evaluateAgainBtn"
        colorScheme="blue"
        disabled={isLoading}
        isLoading={isLoading}
        mt={2}
        onClick={handleEvaluation}
      >
        {t('fitbandConfiguration.fitbandModal.evaluateAgainBtn')}
      </Button>
    </Box>
  );
};
