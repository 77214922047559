import { Box, Button, Divider, HStack, IconButton, Menu, MenuButton, MenuList, Text, Tooltip } from '@chakra-ui/core';
import React from 'react';
import { useHireFormContext } from '../HireForm.context';
import { availableLanguages, Language } from '../hireForm.interface';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdAdd, MdCheckBox, MdCheckBoxOutlineBlank, MdOutlineRemove, MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';
import theme from '../../../../styles/customTheme';
import { useStoreState } from '../../../../models/hooks';
import { useTranslation } from 'react-i18next';
import {
  FieldSchema,
  formBuilderType,
  FormBuilderTypes,
  generateFormSchema,
  mapFormSchema,
  Sections,
  updateMissingTranslations,
} from '../formElements/formBuilderSchema';
import { formBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';

export default function SelectLanguage() {
  const {
    setLanguage,
    language,
    languages,
    defaultLanguage,
    setLanguages,
    setDefaultLanguage,
    formSchema,
    setFormSchema,
  } = useHireFormContext();

  const { t } = useTranslation('hrFormTemplate');

  const supportedLanguages = useStoreState((s) => s.app.accounts?.configuration?.supportedLanguages) ?? availableLanguages;

  const sortedLanguages = React.useMemo(() => {
    return supportedLanguages.sort((a, b) => (a.value === defaultLanguage ? -1 : b.value === defaultLanguage ? 1 : 0));
  }, [defaultLanguage, supportedLanguages]);

  function handleLanguageChange(lang: string) {
    setLanguage(lang as Language);
  }

  function handleAddLanguage(lang: string) {
    setLanguage(lang as Language);
    setLanguages((prev) => [...prev, lang as Language]);
    const questionAdvancedMap = {
      [Sections.sin]: formBuilderType.sinAdvanced,
      [Sections.ssn]: formBuilderType.ssnAdvanced,
      [Sections.signature]: formBuilderType.signatureAdvanced,
      [Sections.documentPreview]: formBuilderType.documentPreview,
      [Sections.canadianBank]: formBuilderType.canadianBank,
      [Sections.americanBank]: formBuilderType.americanBank,
      [Sections.earliestStartDate]: formBuilderType.earliestStartDate,
      [Sections.addressValidator]: formBuilderType.addressValidator,
      [Sections.emergencyContact]: formBuilderType.emergencyContact,
      [Sections.miscellaneous]: formBuilderType.miscellaneous,
      [Sections.fullName]: formBuilderType.fullName,
    };

    const newFormSchema = generateFormSchema(formSchema, lang)?.map((question, index) => {
      const currentLanguages = [...languages, lang];
      let defaultValue = formBuilderSchemas[question.inputType as FormBuilderTypes]?.['defaultValue'] as FieldSchema;

      function translateFields(value: FieldSchema, defaultFollowUp?: FieldSchema) {
        const isAdvancedSection = Object.values(Sections).includes(value.section as Sections);
        const defaultAdvancedSection = (formBuilderSchemas[questionAdvancedMap[value.section as Sections]]?.[
          'defaultValue'
        ] as FieldSchema[])?.find((field) => field.text?.[defaultLanguage] === value.text?.[defaultLanguage]);

        if (isAdvancedSection && defaultAdvancedSection && !defaultFollowUp) {
          defaultValue = defaultAdvancedSection;
        }
        if (defaultFollowUp) {
          defaultValue = defaultFollowUp;
        }

        const translatedTextValue = defaultValue?.text?.[lang];

        const translatedText = updateMissingTranslations(
          value,
          'text',
          defaultLanguage,
          currentLanguages,
          translatedTextValue,
          defaultValue,
        )['text'];
        const translatedLabelValue = defaultValue?.label?.[lang];
        const translatedLabel = updateMissingTranslations(
          value,
          'label',
          defaultLanguage,
          currentLanguages,
          translatedLabelValue,
          defaultValue,
        )['label'];

        const options = value?.optionList?.map((option) => {
          const translatedOption = defaultValue?.optionList?.find(
            (o) => o?.text?.[defaultLanguage] === option?.text?.[defaultLanguage],
          )?.text;
          const translatedOptionValue = translatedOption?.[lang];

          return {
            ...option,
            key: translatedOptionValue,
            text: updateMissingTranslations(option, 'text', defaultLanguage, currentLanguages, translatedOptionValue, option)[
              'text'
            ],
          };
        });

        let documentReview = value?.documentReview;
        if (documentReview) {
          const defaultDocumentReview = defaultValue?.documentReview;
          const descriptionValue = defaultDocumentReview?.description?.[lang];
          const translatedDescription = updateMissingTranslations(
            documentReview,
            'description',
            defaultLanguage,
            currentLanguages,
            descriptionValue,
            defaultDocumentReview!,
          )['description'];
          const acknowledgeContentValue = defaultDocumentReview?.acknowledgeContent?.[lang];
          const translatedAcknowledgeContent = updateMissingTranslations(
            documentReview,
            'acknowledgeContent',
            defaultLanguage,
            currentLanguages,
            acknowledgeContentValue,
            defaultDocumentReview!,
          )['acknowledgeContent'];
          const confirmTextValue = defaultDocumentReview?.confirmText?.[lang];
          const translatedConfirmText = updateMissingTranslations(
            documentReview,
            'confirmText',
            defaultLanguage,
            currentLanguages,
            confirmTextValue,
            defaultDocumentReview!,
          )['confirmText'];

          documentReview = {
            ...documentReview,
            description: translatedDescription,
            acknowledgeContent: translatedAcknowledgeContent,
            confirmText: translatedConfirmText,
            url: {
              ...documentReview?.url,
              [lang]: '',
            },
          };
        }

        return {
          translatedText,
          translatedLabel,
          options,
          documentReview,
        };
      }

      const { translatedText, translatedLabel, options, documentReview } = translateFields(question);
      const followUpsDefault = defaultValue?.followUpQuestionList ? [...defaultValue?.followUpQuestionList] : null;
      const followUps = question?.followUpQuestionList?.map((followUp) => {
        const defaultFollowUp = followUpsDefault?.find((f) => f?.text?.[defaultLanguage] === followUp?.text?.[defaultLanguage]);

        const { translatedText, translatedLabel, options, documentReview } = translateFields(followUp, defaultFollowUp);

        return {
          ...followUp,
          text: translatedText,
          label: translatedLabel,
          optionList: options,
          documentReview,
        };
      });
      return {
        ...question,
        text: translatedText,
        label: translatedLabel,
        optionList: options,
        followUpQuestionList: followUps,
        documentReview,
      } as FieldSchema;
    });

    setFormSchema(mapFormSchema({ currentTemplate: newFormSchema ?? [], formBuilderSchemas }) ?? []);
  }

  function handleRemoveLanguage(lang: string) {
    setLanguages((prev) => prev.filter((l) => l !== lang));
    setLanguage(defaultLanguage);
  }

  return (
    <Menu closeOnSelect={false}>
      <Tooltip
        label={
          supportedLanguages.length === 1
            ? t('formBuilder.languageSelectMenu.onleOneLanguage')
            : t('languageSelect.primaryLanguagePlaceholder')
        }
        aria-label="Select language"
        placement="bottom"
        shouldWrapChildren
        hasArrow
        closeOnClick
      >
        <MenuButton
          as={Button}
          rightIcon={supportedLanguages.length === 1 ? null : <ChevronDownIcon />}
          data-testid="select-language-menu-button"
          disabled={supportedLanguages.length === 1}
          _disabled={{
            opacity: 0.9,
            cursor: 'not-allowed',
          }}
        >
          <HStack spacing={1}>
            <Text textTransform="capitalize">{language}</Text>
            {language === defaultLanguage && (
              <Text fontSize="xs" fontWeight="normal" color="gray.500">
                ({t('formBuilder.languageSelectMenu.primary')})
              </Text>
            )}
          </HStack>
        </MenuButton>
      </Tooltip>
      <MenuList>
        {sortedLanguages.map(({ label, value }) => {
          const isDefault = value === defaultLanguage;
          const isAvailable = languages.includes(value);
          const isSelected = language === value;
          const showRemoveAction = !isDefault && isAvailable;
          const showAddAction = !isAvailable;
          return (
            <Box
              key={value}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (isAvailable && !isSelected) {
                  handleLanguageChange(value);
                }
              }}
              sx={{
                minHeight: 10,
                px: 2,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                _hover: {
                  backgroundColor: theme.colors.gray[50],
                },
                ...(isSelected && {
                  backgroundColor: theme.colors.blue[100],
                }),
              }}
              data-testid={`select-language-menu-item-${value}`}
            >
              <HStack justify="space-between" width="100%" height="100%">
                <HStack opacity={isAvailable ? 1 : 0.5}>
                  <HStack spacing={1}>
                    <Box width={6}>
                      {isSelected ? (
                        <MdCheckBox color="blue" fontSize={20} />
                      ) : isAvailable ? (
                        <MdCheckBoxOutlineBlank fontSize={20} />
                      ) : undefined}
                    </Box>
                    <Text textTransform="capitalize" fontSize="sm">
                      {label}
                    </Text>

                    {isAvailable &&
                      (isDefault ? (
                        <Tooltip
                          label={t('formBuilder.languageSelectMenu.primaryLanguage')}
                          shouldWrapChildren
                          hasArrow
                          placement="right"
                          closeOnClick
                        >
                          <MdOutlineStar color={theme.colors.yellow[500]} />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          label={t('formBuilder.languageSelectMenu.setAsPrimary')}
                          shouldWrapChildren
                          hasArrow
                          placement="right"
                          closeOnClick
                        >
                          <MdOutlineStarBorder
                            color={theme.colors.gray[200]}
                            data-testid={`set-default-language-${value}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setDefaultLanguage(value);
                            }}
                          />
                        </Tooltip>
                      ))}
                  </HStack>
                </HStack>
                {(showAddAction || showRemoveAction) && (
                  <HStack zIndex={1} sx={{ opacity: 0.5, _hover: { opacity: 1 } }}>
                    <Divider orientation="vertical" minHeight={3} />
                    {showRemoveAction && (
                      <Tooltip
                        label={t('formBuilder.languageSelectMenu.removeLanguage')}
                        shouldWrapChildren
                        hasArrow
                        placement="right"
                        closeOnClick
                      >
                        <IconButton
                          variant="ghost"
                          icon={<MdOutlineRemove />}
                          aria-label="remove language"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRemoveLanguage(value);
                          }}
                          data-testid={`remove-language-${value}`}
                        />
                      </Tooltip>
                    )}
                    {showAddAction && (
                      <Tooltip
                        label={t('formBuilder.languageSelectMenu.addLanguage')}
                        shouldWrapChildren
                        hasArrow
                        placement="right"
                        closeOnClick
                      >
                        <IconButton
                          variant="ghost"
                          icon={<MdAdd />}
                          aria-label="add language"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAddLanguage(value);
                          }}
                          data-testid={`add-language-${value}`}
                        />
                      </Tooltip>
                    )}
                  </HStack>
                )}
              </HStack>
            </Box>
          );
        })}
      </MenuList>
    </Menu>
  );
}
