import { IconType } from 'react-icons';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { MultipleChoiceImage } from '../../positionManagement/PositionType';
import { v4 as uuidv4 } from 'uuid';
import { FormBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';

export const MAX_FOLLOW_UP_DEPTH = 1;

export enum formBuilderType {
  // basic elements
  text = 'text',
  info = 'info',
  number = 'number',
  date = 'date',
  time = 'time',
  email = 'email',
  address = 'address',
  phone = 'phone',
  yesNo = 'yesNo',
  checkbox = 'checkbox',
  radio = 'radio',
  image = 'image',
  file = 'file',
  link = 'link',
  button = 'button',
  singleCheckbox = 'singleCheckbox',
  gender = 'gender',
  documentReview = 'documentReview',
  bankAccountNumber = 'bankAccountNumber',
  routingNumber = 'routingNumber',
  branchTransitNumber = 'branchTransitNumber',
  financialInstitutionNumber = 'financialInstitutionNumber',
  checkboxDisable = 'checkboxDisable',
  correspondenceLanguage = 'correspondenceLanguage',
  sin = 'sin',
  ssn = 'ssn',
  signature = 'signature',
  // page elements
  section = 'section',
  // advanced elements
  sinAdvanced = 'sinAdvanced',
  ssnAdvanced = 'ssnAdvanced',
  documentPreview = 'documentPreview',
  americanBank = 'americanBank',
  canadianBank = 'canadianBank',
  earliestStartDate = 'earliestStartDate',
  addressValidator = 'addressValidator',
  // confirmation = 'confirmation',
  signatureAdvanced = 'signatureAdvanced',
  emergencyContact = 'emergencyContact',
  miscellaneous = 'miscellaneous',
  fullName = 'fullName',
}

export type FormBuilderTypes = keyof typeof formBuilderType;
export type BasicFormBuilderTypes = Extract<
  FormBuilderTypes,
  | 'text'
  | 'info'
  | 'number'
  | 'date'
  | 'time'
  | 'email'
  | 'address'
  | 'phone'
  | 'yesNo'
  | 'checkbox'
  | 'radio'
  | 'image'
  | 'file'
  | 'link'
  | 'documentReview'
  | 'singleCheckbox'
  | 'button'
  | 'gender'
  | 'bankAccountNumber'
  | 'routingNumber'
  | 'branchTransitNumber'
  | 'financialInstitutionNumber'
  | 'signature'
  // custom elements
  | 'checkboxDisable'
  | 'correspondenceLanguage'
  | 'sin'
  | 'ssn'
>;

export enum Sections {
  canadianBank = 'canadianBank',
  americanBank = 'americanBank',
  emergencyContact = 'emergencyContact',
  documentPreview = 'documentPreview',
  miscellaneous = 'miscellaneous',
  sin = 'sin',
  ssn = 'ssn',
  signature = 'signature',
  earliestStartDate = 'earliestStartDate',
  addressValidator = 'addressValidator',
  fullName = 'fullName',
}

export type AdvancedFormBuilderTypes = Extract<
  FormBuilderTypes,
  | 'sinAdvanced'
  | 'ssnAdvanced'
  | 'documentPreview'
  | 'canadianBank'
  | 'americanBank'
  | 'earliestStartDate'
  | 'addressValidator'
  // | 'confirmation'
  | 'signatureAdvanced'
  | 'emergencyContact'
  | 'miscellaneous'
  | 'fullName'
>;

export type LabelSchema = Record<string, string>;
export interface OptionSchema {
  key: string;
  id: string;
  text: LabelSchema;
  isSelected?: boolean;
  tag?: string | null;
}

export interface DocumentReview {
  description: LabelSchema;
  url: LabelSchema;
  acknowledgeContent: LabelSchema;
  confirmText: LabelSchema;
}
export interface FieldSchema {
  timestamp: number;
  id: string;
  inputType: FormBuilderTypes;
  order: number;
  section?: string;
  parentSection?: string | null;
  label?: LabelSchema;
  required?: boolean;
  text?: LabelSchema;
  validations?: Record<string, any>;
  optionList?: OptionSchema[] | null;
  isFollowUp: boolean;
  followUpQuestionList?: FieldSchema[] | null;
  hintText?: Partial<Record<string, string>>;
  parent?: string | null;
  optionId?: string | null;
  isInfo?: boolean;
  link?: LabelSchema | null;
  image?: MultipleChoiceImage;
  previewPlaceholders?: { placeholder: string; disableMinMax?: boolean };
  field: string;
  documentReview?: DocumentReview | null;
  disabled?: boolean;
  disabledId?: string;
  isFrontEndOnly?: boolean;
  hideFieldSelector?: boolean;
  min?: number | null;
  max?: number | null;
  enableWorkPermit?: boolean | null;
}

export interface SchemaBuilderProps {
  defaultValue: FieldSchema;
  onChange: (schema: FieldSchema | FieldSchema[] | SectionSchema) => void;
  language: string;
  path: (string | number)[];
  sectionIndex: number;
  fieldIndex: number;
  followUpDragOver?: () => void;
  isAdvanced?: boolean;
  followUpLabel: number;
  labelRightRenderer?: React.ReactNode;
  section: SectionSchema;
}

export type ComponentPanels = 'basic' | 'advanced' | 'tags' | 'page-element';
export interface FormBuilderSchema {
  type: FormBuilderTypes;
  component: (({ defaultValue, onChange }: SchemaBuilderProps) => JSX.Element) | null;
  props: any;
  defaultValue: FieldSchema | FieldSchema[];
  icon: IconType;
  label: string;
  panel: ComponentPanels;
  section?: string;
  hidden?: boolean;
}

export interface PageElementSchema {
  key: string;
  text: { en: string; fr: string };
  icon: IconType;
  label: string;
}

export interface HrFromTemplate {
  id: string;
  account: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  type?: string | null;
}

export interface HrFormTemplateGetAllResponse {
  after: string | null;
  before: string | null;
  hasNext: boolean;
  hasPrevious: boolean;
  limit: number | null;
  type?: string | null;
  templates: HrFromTemplate[];
  total: number;
}

export interface HrFromCreateTemplateResponse {
  data: {
    templateId: string;
  };
  message: string;
}

export interface HrFormUpdateTemplateResponse {
  data: {
    templateId: string;
  };
  message: string;
}
export interface SectionSchema {
  id: string;
  section: string;
  fields: (FieldSchema | SectionSchema)[];
  isAdvanced?: boolean;
}
export function isFieldSchema(item: FieldSchema | SectionSchema): item is FieldSchema {
  return 'inputType' in item;
}
export function generateFormSchema(
  sectionSchema: SectionSchema[],
  language: string,
  forValidation = false,
  selectedLanguages: string[] = ['en', 'fr'],
): FieldSchema[] {
  const schemas = [...(sectionSchema || [])];
  const questionsOmitKeys = [
    'timestamp',
    'hintText',
    'validations',
    'required',
    'previewPlaceholders',
    'disabledId',
    'disabled',
    'isFrontEndOnly',
    'hideFieldSelector',
  ];

  const followUpOmitKeys = [...questionsOmitKeys, 'documentReview', 'enableWorkPermit', 'section', 'parentSection'];

  const filterKeysByLanguages = (data: any): any => {
    if (Array.isArray(data)) {
      return data.map((item) => filterKeysByLanguages(item));
    } else if (typeof data === 'object' && data !== null) {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([key]) => selectedLanguages.includes(key as string)) // Only keep keys that exist in languages
          .map(([key, value]) => [key, filterKeysByLanguages(value)]), // Recursively filter
      );
    }
    return data;
  };

  function processOptionList(options?: OptionSchema[]): Omit<OptionSchema, 'key'>[] | null {
    if (!options || options.length === 0) return null;

    const filteredOptions = options.map((option) => omit(option, ['key']) as Omit<OptionSchema, 'key'>);

    return filteredOptions?.map((option) => {
      return {
        ...option,
        text: filterKeysByLanguages(option.text),
      };
    });
  }

  function processFollowUpQuestions(
    questions: FieldSchema[] | undefined,
    sectionName: string,
    parentSection: string | null = null,
  ): any[] | null {
    if (!questions || questions.length === 0) return null;
    return questions
      .filter((question) => !(question?.disabled || (question?.isFrontEndOnly && !forValidation)))
      .map((question) => {
        // Recursively handle both follow-up questions and nested option lists
        return {
          ...omit(question, followUpOmitKeys),
          label: filterKeysByLanguages(question.label),
          text: filterKeysByLanguages(question.text),
          field: question?.field ?? question.inputType,
          isInfo: question?.isInfo ?? false,
          optionList: processOptionList(question?.optionList ?? []), // Handle optionList for follow-up
          followUpQuestionList: processFollowUpQuestions(question?.followUpQuestionList ?? [], sectionName, parentSection), // Recursive call for nested follow-ups
          section: sectionName,
          parentSection,
        };
      });
  }

  return schemas
    .map((section) => {
      return section.fields
        ?.filter((item) => (isFieldSchema(item) ? !(item?.disabled || (item?.isFrontEndOnly && !forValidation)) : true))
        ?.flatMap((field) => {
          if (isFieldSchema(field)) {
            const documentReview = field?.documentReview
              ? {
                  acknowledgeContent: filterKeysByLanguages(field.documentReview?.acknowledgeContent),
                  confirmText: filterKeysByLanguages(field.documentReview?.confirmText),
                  description: filterKeysByLanguages(field.documentReview?.description),
                  url: filterKeysByLanguages(field.documentReview?.url),
                }
              : null;

            const newField = {
              ...field,
              label: filterKeysByLanguages(field.label),
              text: filterKeysByLanguages(field.text),
              documentReview,
              field: field?.field ?? field.inputType,
              optionList: processOptionList(field?.optionList ?? []),
              followUpQuestionList: processFollowUpQuestions(
                field?.followUpQuestionList ?? [],
                section.section,
                field.parentSection,
              ),
            };
            return {
              ...omit(newField, questionsOmitKeys),
              section: section.section,
            } as FieldSchema;
          } else {
            return generateFormSchema([field], language, forValidation, selectedLanguages);
          }
        });
    })
    .flat()
    .map((item, index) => {
      const temp = { ...omitBy(item as FieldSchema, isNil) };
      return {
        ...temp,
        order: index + 1,
      } as FieldSchema;
    });
}

export function mapFormSchema({
  currentTemplate,
  formBuilderSchemas,
}: {
  currentTemplate: FieldSchema[];
  formBuilderSchemas: FormBuilderSchemas;
}) {
  const grouped = currentTemplate
    .reduce<SectionSchema[]>((acc, field, index) => {
      const sectionName = field.section ?? `section${index + 1}`;
      const isAdvancedSection = Object.values(Sections).includes(sectionName as Sections);
      const existingSection = acc.find((group) => group.section === sectionName);

      const isSubSection = Boolean(field.parentSection?.trim());

      const defaultField = formBuilderSchemas[field.inputType].defaultValue;
      const newField = {
        ...defaultField,
        ...field,
        optionList: field?.optionList?.map((option, index) => ({
          ...option,
          key: field.inputType === 'yesNo' ? option.text.en! : String.fromCharCode(65 + index).toUpperCase(),
        })),
      };

      if (isSubSection) {
        const parentSectionName = field.parentSection ?? `section${index + 1}`;
        const hasExistingParentSection = acc.find((group) => group.section === parentSectionName);
        if (hasExistingParentSection) {
          const hasExistingSubSection = hasExistingParentSection.fields.find(
            (group) => group.section === sectionName,
          ) as SectionSchema;
          if (hasExistingSubSection) {
            hasExistingSubSection.fields.push(newField);
          } else {
            hasExistingParentSection.fields.push({
              section: sectionName,
              isAdvanced: isAdvancedSection,
              fields: [newField],
              id: uuidv4(),
            });
          }
        } else {
          acc.push({
            section: parentSectionName,
            isAdvanced: false,
            fields: [{ section: sectionName, isAdvanced: isAdvancedSection, fields: [newField], id: uuidv4() }],
            id: uuidv4(),
          });
        }
      } else {
        if (existingSection) {
          existingSection.fields.push(newField);
        } else {
          acc.push({ section: sectionName, isAdvanced: isAdvancedSection, fields: [newField], id: uuidv4() });
        }
      }

      return acc;
    }, [])
    ?.map((item) => {
      function mapForAvancedSection(section: SectionSchema): SectionSchema {
        const sectionName = section.section;
        const isCanadianBank = sectionName === Sections.canadianBank;
        const isAmericanBank = sectionName === Sections.americanBank;
        const isSin = sectionName === Sections.sin;
        if (isCanadianBank || isAmericanBank || isSin) {
          const fields = [...section.fields];
          const checkIndex = isCanadianBank ? 4 : isAmericanBank ? 3 : 1;
          const hasBankVoidCheque = section.fields.length > checkIndex;
          const selectedField = isCanadianBank ? Sections.canadianBank : isAmericanBank ? Sections.americanBank : 'sinAdvanced';

          const defaultFields = formBuilderSchemas[selectedField].defaultValue as FieldSchema[];

          defaultFields.forEach((field, index) => {
            if (index === checkIndex) {
              fields.splice(checkIndex, 0, { ...field });
            } else if (index > checkIndex) {
              fields.splice(index, 1, {
                ...(hasBankVoidCheque ? fields[index] : field),
                disabledId: field.disabledId,
                disabled: !hasBankVoidCheque,
              });
            }
          });
          return { ...section, fields: fields };
        } else {
          const mappedFilled = section?.fields?.map((field) => {
            if (isFieldSchema(field)) {
              return field;
            }
            return mapForAvancedSection(field);
          });
          return { ...section, fields: mappedFilled };
        }
      }
      return mapForAvancedSection(item);
    });

  return grouped;
}

export function addDataTagToSpans(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Find all span elements in the document
  const spans = doc.querySelectorAll('span');

  // Loop through each span and add data-tag="true" if the content matches the pattern [any]
  spans.forEach((span) => {
    if (/\[.*\]/.test(span.textContent || '')) {
      span.setAttribute('data-tag', 'true');
    }
  });

  // Return the updated HTML as a string
  return doc.body.innerHTML;
}

export const mapField = (field: FieldSchema, parentSection: string | null = null) => {
  // if (!field?.optionList?.length) return field;
  const optionIdMap = new Map(field?.optionList?.map((option) => [option.id, uuidv4()]));
  const newOptions = field?.optionList?.length
    ? [...field?.optionList!]?.map((option) => ({
        ...option,
        id: optionIdMap.get(option.id)!,
      }))
    : null;
  const newFollowUpQuestionList = field?.followUpQuestionList?.length
    ? field?.followUpQuestionList?.map((question) => {
        return {
          ...question,
          id: uuidv4(),
          optionId: optionIdMap.get(question.optionId!)!,
          timestamp: Date.now(),
        };
      })
    : null;
  const newField: FieldSchema = {
    ...field,
    timestamp: Date.now(),
    id: uuidv4(),
    optionList: newOptions,
    followUpQuestionList: newFollowUpQuestionList,
    parentSection,
  };
  return newField;
};

export const updateMissingTranslations = <T extends Record<string, any>>(
  obj: T,
  key: keyof T, // The field to check (e.g., "text", "label")
  defaultLanguage: string, // Default language to copy from
  languages: string[], // List of languages
  translationValue?: string,
  defaultValue?: T,
): T => {
  if (!obj || !obj[key]) return obj; // Ensure the object and key exist

  const updatedObj = { ...obj, [key]: { ...obj[key] } };

  const defaultData = defaultValue?.[key]?.[defaultLanguage];
  const currentData = updatedObj?.[key]?.[defaultLanguage];

  languages.forEach((lang) => {
    if (lang !== defaultLanguage && !updatedObj[key][lang]?.trim()) {
      updatedObj[key][lang] = defaultData === currentData ? translationValue || currentData : currentData;
    }
  });

  return updatedObj;
};
