/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { Flex, Text, IconButton } from '@chakra-ui/core';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import isUndefined from 'lodash/isUndefined';
import { useTranslation } from 'react-i18next';
import colors from '../../../../styles/colors';

export type PaginationProps = {
  hasNext?: boolean;
  isFetching?: boolean;
  hasPrevious?: boolean;
  handleNextPage?: () => void;
  handlePreviousPage?: () => void;
  showingResultsTranslation?: any | undefined;
  dataCount: number;
  totalDataCount: number | undefined;
};

export const TemplatePagination = ({
  hasNext,
  hasPrevious,
  isFetching,
  handlePreviousPage,
  handleNextPage,
  showingResultsTranslation,
  dataCount,
  totalDataCount,
}: PaginationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Flex alignItems="center" w="100%" paddingX={4} paddingY={3} background={colors.white}>
        <Flex alignItems="center" w="100%" justifyContent="space-between">
          <Flex alignItems="center">
            <Text mr={3} fontSize="sm" color="gray.500" data-testid="pagination-text">
              {isUndefined(showingResultsTranslation)
                ? /* istanbul ignore next */ t('common:pagination.showingResult', {
                    currentItems: dataCount,
                    totalItems: totalDataCount,
                  })
                : // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  showingResultsTranslation({
                    currentItems: dataCount,
                    totalItems: totalDataCount,
                  })}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <IconButton
              onClick={handlePreviousPage}
              mr={3}
              isDisabled={!hasPrevious || isFetching}
              variant="outline"
              colorScheme="blue"
              aria-label="Prev"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdKeyboardArrowLeft />}
              data-testid="prevPage-button"
            />
            <IconButton
              onClick={handleNextPage}
              mr={3}
              isDisabled={!hasNext || isFetching}
              variant="outline"
              colorScheme="blue"
              aria-label="Next"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdKeyboardArrowRight />}
              data-testid="nextPage-button"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
