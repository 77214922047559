import { Badge, Box, Flex, Heading, IconButton, Text } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GiPlainCircle } from 'react-icons/gi';
import { IoDocumentText, IoDocumentsSharp } from 'react-icons/io5';
import { MdOutlineUpgrade } from 'react-icons/md';
import { RiHandCoinFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Count } from '../../firebase/firestore/documents/toDo';
import colors from '../../styles/colors';

export type ToDoCountViewTopBarProps = {
  count: Count;
  onOpenSideBar: () => void;
  openSideBar: boolean;
};
export const ToDoCountViewTopBar = ({ count, onOpenSideBar, openSideBar }: ToDoCountViewTopBarProps): JSX.Element => {
  const { t } = useTranslation('toDoList');

  return (
    <Box p="12px" bg="#fff">
      <Flex alignItems="center">
        {count.total > 0 && (
          <Flex
            pl="10px"
            pr="10px"
            alignItems="center"
            justifyContent="space-between"
            background="#EDF2F7"
            borderRadius="30px"
            cursor="pointer"
            _hover={{ background: colors.gray[200] }}
            onClick={onOpenSideBar}
          >
            <MdOutlineUpgrade
              size="30px"
              style={{ transform: openSideBar ? /* istanbul ignore next */ 'rotate(270deg)' : 'rotate(90deg)' }}
            />
            <Heading as="h4" fontSize="md" ml={2}>
              {t('summary')}
            </Heading>
            {count.total.toString().length < 2 ? (
              <Box
                background={
                  // istanbul ignore next
                  count?.total === 0 ? colors.green[500] : colors.red[500]
                }
                w="20px"
                lineHeight="20px"
                borderRadius="50%"
                textAlign="center"
                color={colors.white}
                fontSize="14px"
                fontWeight={700}
                ml="6px"
              >
                {count.total}
              </Box>
            ) : (
              <Badge
                background={colors.red[500]}
                w="max-content"
                color={colors.white}
                ml="6px"
                fontSize="14px"
                fontWeight={700}
                borderRadius="12px"
              >
                {count.total}
              </Badge>
            )}
          </Flex>
        )}
        <Link to="/dashboard/offerAndHireProcess/offer_processing">
          <Flex
            py="8px"
            px="15px"
            data-testid="offersProcessingNavigateBtn"
            alignItems="center"
            justifyContent="space-between"
            background="white"
            borderRadius="8px"
            border="2px solid"
            borderColor="blue.30"
            ml={count.total > 0 ? '20px' : '0px'}
            cursor="pointer"
            sx={{
              transition: 'transform 0.1s ease-in-out',
              _hover: {
                borderColor: '#1F3CBA',
                boxShadow: '0 0 10px #1F3CBA',
              },
            }}
          >
            <IconButton
              isRound
              variant="solid"
              background="#4299E1"
              aria-label="Done"
              fontSize="14px"
              h="24px"
              w="24px"
              minWidth="24px"
              icon={<RiHandCoinFill color="#fff" />}
            />
            <Text fontSize="15px" fontWeight="bold" color={colors.gray[600]} ml="6px">
              {t('outstandingOffers')}
            </Text>
            <Text
              fontSize="20px"
              color={colors.gray[900]}
              fontWeight={700}
              ml="10px"
              borderLeft="1px solid"
              borderColor="blue.30"
              pl="10px"
              lineHeight={1}
            >
              {count.outsandingOffers}
            </Text>
          </Flex>
        </Link>
        <Link to="/dashboard/offerAndHireProcess/hire_processing">
          <Flex
            data-testid="hiresProcessingNavigateBtn"
            py="8px"
            px="15px"
            alignItems="center"
            justifyContent="space-between"
            background="white"
            borderRadius="8px"
            border="2px solid"
            borderColor="blue.30"
            ml="20px"
            cursor="pointer"
            sx={{
              transition: 'transform 0.1s ease-in-out',
              _hover: {
                borderColor: '#1F3CBA',
                boxShadow: '0 0 10px #1F3CBA',
              },
            }}
          >
            <IoDocumentText style={{ marginRight: '4px' }} color={colors.blue.default} size="20px" />

            <Text fontSize="15px" fontWeight="bold" color={colors.gray[600]} ml="6px">
              {t('hiresProcessing')}
            </Text>
            <Text
              fontSize="20px"
              color={colors.gray[900]}
              fontWeight={700}
              ml="10px"
              borderLeft="1px solid"
              borderColor="blue.30"
              pl="10px"
              lineHeight={1}
            >
              {count.hireProcessing}
            </Text>
          </Flex>
        </Link>
        <Link to="/dashboard/requisition">
          <Flex
            data-testid="requisitionNavigateBtn"
            py="8px"
            px="15px"
            alignItems="center"
            justifyContent="space-between"
            background="white"
            borderRadius="8px"
            border="2px solid"
            borderColor="blue.30"
            ml="20px"
            cursor="pointer"
            sx={{
              transition: 'transform 0.1s ease-in-out',
              _hover: {
                borderColor: '#1F3CBA',
                boxShadow: '0 0 10px #1F3CBA',
              },
            }}
          >
            <IoDocumentsSharp style={{ marginRight: '4px' }} color={colors.blue.default} size="20px" />

            <Text fontSize="15px" fontWeight="bold" color={colors.gray[600]} ml="6px">
              {t('requisition:requisitionRiskDashboard')}
            </Text>
          </Flex>
        </Link>
        <Flex
          py="8px"
          px="15px"
          alignItems="center"
          justifyContent="space-between"
          background="white"
          borderRadius="8px"
          border="2px solid"
          borderColor="blue.30"
          ml="20px"
        >
          <GiPlainCircle style={{ marginRight: '4px' }} color={colors.blue.default} size="14px" />

          <Text fontSize="15px" fontWeight="bold" color={colors.gray[600]} ml="6px">
            {t('toDo')}
          </Text>
          <Text
            fontSize="20px"
            color={colors.gray[900]}
            fontWeight={700}
            ml="10px"
            borderLeft="1px solid"
            borderColor="blue.30"
            pl="10px"
            lineHeight={1}
          >
            {count.toDo}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
