/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import { Box, Text, Menu, MenuButton, MenuList, Checkbox, VStack, Divider } from '@chakra-ui/core';
import { FaChevronDown } from 'react-icons/fa';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';

type StatusFilterProps = {
  onCompletedFilterChange: (needCompleted: boolean) => void;
  setGroupedByStatusItems: (status: string[]) => void;
  setGroupByStatus: React.Dispatch<React.SetStateAction<string[]>>;
};

export const RecruiterToDoStatusFilter = ({
  onCompletedFilterChange,
  setGroupedByStatusItems,
  setGroupByStatus,
}: StatusFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(['To Do', 'In Progress (for me)']);
  const allOptions = [
    {
      status: 'TODO',
      value: 'To Do',
      label: 'To Do',
    },
    {
      status: 'IN_PROGRESS',
      value: 'In Progress (for me)',
      label: 'In Progress (for me)',
    },
    {
      status: 'IN_PROGRESS',
      value: 'In Progress (for others)',
      label: 'In Progress (for others)',
    },
    {
      status: 'COMPLETED',
      value: 'Completed',
      label: 'Completed',
    },
  ];

  const selectAllOption = {
    value: 'Select All',
    label: 'Select All',
  };

  // istanbul ignore next
  const handleCheckboxChange = (value: string) => {
    let updatedOptions: string[];

    if (value === 'Select All') {
      updatedOptions = selectedOptions.length === allOptions.length ? ['To Do'] : allOptions.map((opt) => opt.value);
    } else {
      updatedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((item) => item !== value && item !== 'To DO')
        : [...selectedOptions, value];

      if (updatedOptions.length === allOptions.length) {
        updatedOptions = allOptions.map((opt) => opt.value);
      }
    }

    if (!updatedOptions.includes('To Do')) {
      updatedOptions.push('To Do'); // Ensure 'To Do' is always included
    }

    setSelectedOptions(updatedOptions);

    // Handle "Completed" status selection
    onCompletedFilterChange(updatedOptions.includes('Completed'));

    // Update the grouped list based on the selected options
    setGroupedByStatusItems(updatedOptions);
  };

  React.useEffect(() => {
    setGroupedByStatusItems(selectedOptions);
    setGroupByStatus(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <Menu>
      <MenuButton as={Box} cursor="pointer" onClick={() => setIsOpen(!isOpen)} data-testid="status-filter-button">
        <Box display="inline-flex" alignItems="center">
          <Text textTransform="none" whiteSpace="nowrap" data-testid="status-text">
            {t('toDoList:status')}
          </Text>
          <Box
            width="24px"
            height="24px"
            ml={2}
            backgroundColor="#D4D8F1"
            borderRadius="6px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            data-testid="chevron-icon"
          >
            <FaChevronDown size={16} color={colors.persianBlue} />
          </Box>
        </Box>
      </MenuButton>
      <MenuList p={4} key={v4()} data-testid="status-filter-menu">
        <VStack align="start" spacing={4}>
          <Checkbox
            isChecked={selectedOptions.length === allOptions.length}
            onChange={() => handleCheckboxChange(selectAllOption.value)}
            fontSize="14px"
            fontWeight={400}
            data-testid="select-all-checkbox"
          >
            {selectAllOption.label}
          </Checkbox>
          <Divider />
          {allOptions.map((option) => (
            <Checkbox
              key={option.value}
              isChecked={selectedOptions.includes(option.value)}
              fontSize="14px"
              fontWeight={400}
              onChange={() => option.value !== 'To Do' && handleCheckboxChange(option.value)}
              isDisabled={option.value === 'To DO'} // Disable unchecking 'To Do'
              colorScheme={option.value === 'To Do' ? 'gray' : 'blue'}
              data-testid={`checkbox-${option.value}`}
            >
              {option.label}
            </Checkbox>
          ))}
        </VStack>
      </MenuList>
    </Menu>
  );
};
