import Joi from 'joi';

export default function useHandleTemplateValidation() {
  function customTranslationSchema({
    message = 'validation.fieldRequired',
    key = 'en',
    ancestor = 3,
  }: {
    message?: string;
    key?: string;
    ancestor?: number;
  }) {
    return Joi.string()
      .messages({ 'string.empty': message, 'string.required': message, 'string.invalid': message })
      .custom((value, helpers) => {
        const { languages } = helpers.state.ancestors[ancestor]; // Access languages from parent object

        if (languages.includes(key) && (!value || value.trim() === '')) {
          return helpers.error('any.invalid', { message });
        }

        return value;
      });
  }

  function translationFieldSchema({
    message = 'validation.fieldRequired',
    ancestor = 3,
  }: {
    message?: string;
    ancestor?: number;
  }) {
    return Joi.object({
      en: customTranslationSchema({ key: 'en', message, ancestor }),
      fr: customTranslationSchema({ key: 'fr', message, ancestor }),
      es: customTranslationSchema({ key: 'es', message, ancestor }),
    });
  }

  // Define the OptionSchema
  function optionSchema({ message = 'validation.fieldRequired', ancestor = 5 }: { message?: string; ancestor?: number }) {
    return Joi.object({
      id: Joi.string()
        .guid({ version: ['uuidv4'] })
        .required(),
      text: translationFieldSchema({ ancestor, message }),
      tag: Joi.string().allow(null, '').optional(),
    });
  }

  // Define the DocumentReviewSchema
  const DocumentReviewSchema = Joi.object({
    acknowledgeContent: translationFieldSchema({ ancestor: 4 }),
    description: translationFieldSchema({ ancestor: 4 }),
    confirmText: translationFieldSchema({ ancestor: 4 }),
    url: translationFieldSchema({ ancestor: 4, message: 'validation.urlRequired' }),
  }).allow(null);

  const FollowUpSchema = Joi.object({
    id: Joi.string()
      .guid({ version: ['uuidv4'] })
      .required(),
    label: translationFieldSchema({ ancestor: 5 }),
    field: Joi.string().required(),
    inputType: Joi.string().required(),
    text: translationFieldSchema({ ancestor: 5 }),
    isInfo: Joi.boolean(),
    order: Joi.number().integer().positive().required(),
    optionList: Joi.array()
      .items(optionSchema({ ancestor: 7 }))
      .allow(null),
    isFollowUp: Joi.boolean().optional(),
    min: Joi.number().integer().positive().allow(null),
    max: Joi.number().integer().positive().allow(null),
    link: translationFieldSchema({ ancestor: 5 }).allow(null),
    parent: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    optionId: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    followUpQuestionList: Joi.array().items(Joi.link('#followUpSchema')).allow(null),
    section: Joi.string().required(),
    parentSection: Joi.string().allow(null),
  }).custom((value, helpers) => {
    if (value.isFollowUp !== true) {
      return helpers.error('any.invalid', {
        message: 'validation.invalidFollowUp',
      });
    }
    return value;
  });

  // Define the FieldSchema
  const QuestionSchema = Joi.object({
    id: Joi.string()
      .guid({ version: ['uuidv4'] })
      .required(),
    label: translationFieldSchema({ ancestor: 3 }),
    field: Joi.string().required(),
    inputType: Joi.string().required(),
    text: translationFieldSchema({ ancestor: 3 }),
    isInfo: Joi.boolean(),
    order: Joi.number().integer().positive().required(),
    optionList: Joi.array()
      .items(optionSchema({ ancestor: 5 }))
      .allow(null),
    isFollowUp: Joi.boolean().optional(),
    followUpQuestionList: Joi.array().items(FollowUpSchema).allow(null),
    min: Joi.number().integer().positive().allow(null),
    max: Joi.number().integer().positive().allow(null),
    link: translationFieldSchema({ ancestor: 3 }).allow(null),
    parent: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    optionId: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    documentReview: DocumentReviewSchema, // Optional but required fields inside documentReview if present
    enableWorkPermit: Joi.boolean().optional(),
    section: Joi.string().required(),
    parentSection: Joi.string().allow(null),
  }).custom((value, helpers) => {
    // Validate `followUpQuestionList` optionIds
    if (value.followUpQuestionList && value.optionList) {
      const validOptionIds = value.optionList.map((option: { id: string }) => option.id);
      for (const followUp of value.followUpQuestionList) {
        if (followUp.optionId && !validOptionIds.includes(followUp.optionId)) {
          return helpers.error('any.invalid', {
            message: 'validation.invalidFollowUpOption',
          });
        }
      }
    }

    // Validate unique IDs in `optionList`
    if (value.optionList) {
      const optionIds = value.optionList.map((option: { id: string }) => option.id);
      const uniqueOptionIds = new Set(optionIds);
      if (uniqueOptionIds.size !== optionIds.length) {
        return helpers.error('any.invalid', {
          message: 'validation.duplicateOptions',
        });
      }
    }

    return value;
  });
  // Set the ID for the base schema

  // Define the Question schema that extends from the base schema
  // const QuestionSchema = BaseQuestionSchema.keys({
  //   section: Joi.string().required(),
  // })
  //   .id('questionSchema')

  // Define the root schema
  const QuestionsSchema = Joi.array()
    .items(QuestionSchema)
    .custom((values, helpers) => {
      // Check for unique IDs across the entire form
      const ids = values.map((item: { id: string }) => item.id);
      const uniqueIds = new Set(ids);
      if (uniqueIds.size !== ids.length) {
        return helpers.error('any.invalid', {
          message: 'validation.duplicateQuestionId',
        });
      }

      return values;
    });

  const TemplateSchema = Joi.object({
    name: Joi.string().max(50).required().messages({
      'any.required': 'validation.templateNameRequired',
      'string.empty': 'validation.templateNameRequired',
      'string.max': 'validation.templateNameMaxLength',
    }),
    questions: QuestionsSchema.required(),
    languages: Joi.array().items(Joi.string().valid('en', 'fr', 'es')).min(1).required(),
    defaultLanguage: Joi.string()
      .required()
      .custom((value, helpers) => {
        const { languages } = helpers.state.ancestors[0]; // Get languages array from the same object
        if (!languages.includes(value)) {
          return helpers.error('any.invalid', { message: 'defaultLanguage must be in languages array' });
        }
        return value;
      }),
  });

  return { QuestionSchema, QuestionsSchema, TemplateSchema };
}
