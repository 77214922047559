import React from 'react';
import { FormBuilderProps } from '../../formBuilder/FormBuilder';
import { FieldSchema, isFieldSchema, SchemaBuilderProps, SectionSchema } from '../formBuilderSchema';
import { useHireFormContext } from '../../HireForm.context';
import DroppableElement from './DroppableElement';
import { Box, Button, HStack, Text } from '@chakra-ui/core';
import theme from '../../../../../styles/customTheme';
import { fieldLabel, formBuilderSchemas, schemaFields, schemaFieldsByType } from '../../formBuilderSchemas/formBuilder.schema';
import isArray from 'lodash/isArray';
import ElementSelectField from '../ElementSelectField';
import { HiTrash } from 'react-icons/hi';
import DeletePopover from '../advancedElements/DeletePopover';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MdDragIndicator } from 'react-icons/md';
import isEqual from 'lodash/isEqual';
import useHandleDrop from '../useHandleDrop';

interface FormBuilderElementProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName'> {
  field: FieldSchema | SectionSchema;
  fieldIndex: number;
  pathIndex: number;
  sectionIndex: number;
  isAdvancedSection: boolean;
  onChange: (value: FieldSchema | FieldSchema[] | SectionSchema) => void;
  isLastElement?: boolean;
  section: SectionSchema;
}

export default function FormElementBuilder({
  field,
  sectionIndex,
  isAdvancedSection,
  isLastElement,
  fieldIndex,
  pathIndex,
  onChange,
  section,
}: FormBuilderElementProps) {
  const { draggedElement } = useHireFormContext();

  const { handleDropToSection } = useHandleDrop({ sectionIndex, fieldIndex });

  return (
    <DroppableElement
      index={fieldIndex}
      onDrop={(_, dropTarget) => {
        handleDropToSection({ dropTarget });
      }}
      sx={{
        '&:hover .hire-form-element-actions': { visibility: 'visible' },
        '&': { paddingX: theme.space[4], paddingTop: theme.space[4], paddingBottom: isLastElement ? theme.space[4] : 0 },
      }}
      rightContent={
        <FormElementActions isAdvancedSection={isAdvancedSection} sectionIndex={sectionIndex} fieldIndex={fieldIndex} />
      }
      isLastElement={isLastElement}
      isDisabled={isAdvancedSection}
      acceptedTypes={['basic', 'advanced']}
      currentType={draggedElement?.panel!}
    >
      {isFieldSchema(field) ? (
        <BasicElementRender
          field={field}
          sectionIndex={sectionIndex}
          fieldIndex={fieldIndex}
          isAdvancedSection={isAdvancedSection}
          onChange={onChange}
          pathIndex={pathIndex}
          section={section}
        />
      ) : (
        <AdvancedElementRender
          section={field}
          parentSection={section}
          sectionIndex={sectionIndex}
          fieldIndex={fieldIndex}
          onChange={onChange}
          pathIndex={pathIndex}
        />
      )}
    </DroppableElement>
  );
}

function BasicElementRender({
  field,
  sectionIndex,
  fieldIndex,
  isAdvancedSection,
  pathIndex,
  onChange,
  section,
}: Omit<FormBuilderElementProps, 'field'> & { field: FieldSchema }) {
  const { language } = useHireFormContext();
  const ignoreFields = [schemaFields.correspondenceLanguage, schemaFields.gender];
  const elementFields =
    (!ignoreFields.includes(field.field as any) && schemaFieldsByType?.[field.field as keyof typeof schemaFieldsByType]) ||
    schemaFieldsByType?.[field.inputType];

  const { isLoading } = useStoreState((state) => state.hrFormTemplate);
  const elementRef = React.useRef<HTMLDivElement>(null);
  const { inputType, hideFieldSelector } = field;
  const { component: Component } = formBuilderSchemas[inputType];

  React.useEffect(() => {
    if (elementRef.current && field && !isLoading) {
      const currentElement = elementRef.current;
      setTimeout(() => {
        if (Date.now() - field.timestamp < 1000) {
          currentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!Component) return null;

  return (
    <Box backgroundColor={theme.colors.white} ref={elementRef}>
      <HStack
        justify="space-between"
        align="flex-start"
        position="relative"
        _after={{
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          boxSizing: 'content-box',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          // animation: timestamp > Date.now() - 1000 ? `${animation} 500ms ease-out` : '',
          zIndex: -10,
          borderRadius: theme.radii.md,
        }}
      >
        <Box flexGrow={1}>
          <MemoizedRenderElement
            field={field}
            onChange={onChange}
            language={language}
            isAdvanced={isAdvancedSection}
            sectionIndex={sectionIndex}
            fieldIndex={fieldIndex}
            pathIndex={pathIndex}
            section={section}
            labelRightRenderer={
              ((elementFields && elementFields.length > 0) || !hideFieldSelector) &&
              !isAdvancedSection && (
                <ElementSelectField
                  defaultValue={field}
                  options={elementFields ?? []}
                  onChange={(value) => {
                    const updatedField: FieldSchema = {
                      ...field,
                      field: value,
                      label: fieldLabel[value as keyof typeof fieldLabel],
                    };

                    onChange(updatedField);
                  }}
                />
              )
            }
          />
        </Box>
      </HStack>
    </Box>
  );
}

function AdvancedElementRender({
  section,
  fieldIndex,
  onChange,
}: Omit<FormBuilderElementProps, 'field' | 'isAdvancedSection' | 'section'> & {
  section: SectionSchema;
  parentSection: SectionSchema;
}) {
  return (
    <Box
      backgroundColor={theme.colors.white}
      borderRadius={theme.radii.md}
      padding={4}
      outline={`2px solid ${theme.colors.blue[200]}`}
    >
      <Text fontSize={theme.fontSizes.sm} fontWeight={'bold'}>
        {section.section}
      </Text>
      {section.fields
        ?.filter((item) => (isFieldSchema(item) ? !item?.disabled : true))
        .map((field, index) => {
          return (
            <FormElementBuilder
              key={field.id}
              field={field}
              fieldIndex={index}
              pathIndex={index}
              sectionIndex={fieldIndex}
              isAdvancedSection={true}
              section={section}
              onChange={(value) => {
                const newSection = { ...section };
                if (isArray(value)) {
                  value.forEach((item) => {
                    const fieldIndex = newSection.fields.findIndex((f) => f.id === item.id);
                    newSection.fields[fieldIndex] = item;
                  });
                } else {
                  const fieldIndex = newSection.fields.findIndex((f) => f.id === value.id);
                  newSection.fields[fieldIndex] = value;
                }
                onChange(newSection);
              }}
            />
          );
        })}
    </Box>
  );
}

interface FormBuilderActionsProps {
  isAdvancedSection: boolean;
  sectionIndex: number;
  fieldIndex: number;
}

function FormElementActions({ isAdvancedSection, sectionIndex, fieldIndex }: FormBuilderActionsProps) {
  const { formSchema, setFormSchema } = useHireFormContext();
  const { setErrors } = useStoreActions((s) => s.hrFormTemplate);
  const { errors } = useStoreState((s) => s.hrFormTemplate);

  return (
    <HStack>
      {!isAdvancedSection && (
        <HStack flexShrink={0} marginTop="0 !important" visibility="hidden" className="hire-form-element-actions">
          <SortableAnchor />
          <DeletePopover
            popoverTrigger={
              <Button borderRadius="50%" colorScheme="red" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
                <HiTrash />
              </Button>
            }
            onYes={() => {
              const newFormSchema = [...formSchema];
              newFormSchema[sectionIndex].fields.splice(fieldIndex, 1);
              setErrors([...errors]?.filter((e) => e.path[1] !== fieldIndex));
              setFormSchema(newFormSchema);
            }}
          />
        </HStack>
      )}
    </HStack>
  );
}

const SortableAnchor = SortableHandle(() => (
  <Button as="div" borderRadius="50%" cursor="grab" backgroundColor="gray" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
    <MdDragIndicator />
  </Button>
));

function RenderElement({
  field,
  onChange,
  fieldIndex,
  pathIndex,
  ...rest
}: { field: FieldSchema; pathIndex: number } & Omit<SchemaBuilderProps, 'defaultValue' | 'path' | 'followUpLabel'>) {
  const { inputType } = field;

  const { component: Component } = formBuilderSchemas[inputType];
  if (!Component) return null;

  const path = ['questions', pathIndex];
  function handleOnChange(value: any) {
    onChange(value);
  }

  return (
    <Component onChange={handleOnChange} {...rest} defaultValue={field} path={path} fieldIndex={fieldIndex} followUpLabel={0} />
  );
}

const MemoizedRenderElement = React.memo(RenderElement, (prev, next) => {
  return (
    isEqual(prev.field, next.field) &&
    prev.language === next.language &&
    prev.fieldIndex === next.fieldIndex &&
    prev.sectionIndex === next.sectionIndex &&
    prev.pathIndex === next.pathIndex &&
    prev.onChange === next.onChange
  );
});

export const SortableFormElementBuilder = React.memo(SortableElement(FormElementBuilder));
