import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { ProfileManagementController } from '../../../controllers/profileManagementController';
import { GetCandidateNumberInPreferencesType, GetVirtualResumeType, UserProfileType } from '../ProfileTypes';
import { TeamMemberInfoType } from '../../administration/team/TeamType';

export type ProfileManagementStoreModel = {
  profileManagement: ProfileManagementModel;
};

export interface ProfileManagementModel {
  updateProfile: Thunk<
    ProfileManagementStoreModel,
    { userID: string; userProfile: UserProfileType },
    void,
    HttpStoreModel,
    Promise<UserProfileType>
  >;
  uploadProfilePicture: Thunk<
    ProfileManagementStoreModel,
    { fileName: string; profilePicture: File },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  createTeam: Thunk<ProfileManagementStoreModel, { accountId: string; name: string }, void, HttpStoreModel, Promise<void>>;
  updateTeam: Thunk<ProfileManagementStoreModel, { accountId: string; name: string }, void, HttpStoreModel, Promise<void>>;
  removeTeamMember: Thunk<
    ProfileManagementStoreModel,
    { accountId: string; userId: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  invite: Thunk<
    ProfileManagementStoreModel,
    { accountId: string; teamMemberInfo: TeamMemberInfoType },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  resendInvite: Thunk<
    ProfileManagementStoreModel,
    { accountInviteId: string; userId: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  cancelInvite: Thunk<
    ProfileManagementStoreModel,
    { accountInviteId: string; userId: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  promoteTeamMember: Thunk<
    ProfileManagementStoreModel,
    { accountId: string; userId: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  demoteTeamMember: Thunk<
    ProfileManagementStoreModel,
    { accountId: string; userId: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  getCandidateNumberInPreferences: Thunk<
    ProfileManagementStoreModel,
    { accountId: string; daysFilter: number },
    void,
    HttpStoreModel,
    Promise<GetCandidateNumberInPreferencesType>
  >;

  getVirtualResume: Thunk<
    ProfileManagementStoreModel,
    { seekerId?: string },
    void,
    HttpStoreModel,
    Promise<GetVirtualResumeType>
  >;
}

export const profileManagementStoreModel: ProfileManagementModel = {
  updateProfile: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateProfile(payload.userID, payload.userProfile);
    return response?.data as UserProfileType;
  }),
  uploadProfilePicture: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.uploadProfilePicture(payload.fileName, payload.profilePicture);
  }),
  createTeam: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.createTeam(payload.accountId, payload.name);
  }),
  updateTeam: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateTeam(payload.accountId, payload.name);
  }),
  removeTeamMember: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.removeTeamMember(payload.accountId, payload.userId);
  }),
  invite: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.invite(payload.accountId, payload.teamMemberInfo);
  }),
  resendInvite: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.resendInvite(payload.accountInviteId, payload.userId);
  }),
  cancelInvite: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.cancelInvite(payload.accountInviteId, payload.userId);
  }),
  promoteTeamMember: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.promoteTeamMember(payload.accountId, payload.userId);
  }),
  demoteTeamMember: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    await controller.demoteTeamMember(payload.accountId, payload.userId);
  }),
  getCandidateNumberInPreferences: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getCandidateNumberInPreferences(payload.accountId, payload.daysFilter);
    return response?.data as GetCandidateNumberInPreferencesType;
  }),
  getVirtualResume: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ProfileManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getVirtualResume(payload?.seekerId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response?.data as GetVirtualResumeType;
  }),
};
