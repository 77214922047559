/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, no-extra-boolean-cast, @typescript-eslint/no-unsafe-call */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Heading, Flex, Text, VStack, Skeleton, HStack, useToast } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import isArray from 'lodash/isArray';
import { RiLock2Fill } from 'react-icons/ri';
import ImageView from '../../../../../app/components/ImageView';
import PixelatedImage from '../../../../../assets/img/pixelated-image.png';
import { fieldLabel, schemaFields } from '../../../../administration/hireForm/formBuilderSchemas/formBuilder.schema';
import { ImagePreviewModal } from '../../../../../app/components/ImagePreviewModal';
import { formBuilderType, LabelSchema } from '../../../../administration/hireForm/formElements/formBuilderSchema';
import {
  Candidate,
  CandidateHireDetailsAnswer,
  CandidateHireFormDetailResponse,
  CandidateHireFormDetailResponseWithError,
  SeekerHireDetail,
} from '../../../../../firebase/firestore/documents/candidate';
import colors from '../../../../../styles/colors';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';

interface SeekerHireFormAnswersProps {
  selectedCandidate: Candidate;
}

type THireDetailsValue = {
  questionId: string;
  field: string;
  label: string;
  answer: string | null;
  images: string[] | null;
};

type HireSectionT = {
  section: string;
  fields: (THireDetailsValue | HireSectionT)[];
};

export default function SeekerHireFormAnswers({ selectedCandidate }: SeekerHireFormAnswersProps) {
  const { t, i18n } = useTranslation('hireDetail');
  const [currentLanguage, setCurrentLanguage] = React.useState<string>(i18n.language);
  const toast = useToast();
  const { getSeekerHierDetails, loadHireDetailOfCandidate } = useStoreActions((actions) => actions.candidate);

  const [hireDetailOfCandidate, setHireDetailOfCandidate] = React.useState<CandidateHireFormDetailResponse | undefined>(
    undefined,
  );
  const [hireDetails, setHireDetails] = React.useState<CandidateHireDetailsAnswer[]>([]);
  const [filteredHireDetails, setFilteredHireDetails] = React.useState<HireSectionT[]>();
  const [isLoading, setIsLoading] = React.useState(false);

  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);
  const userId = useStoreState((s) => s.app.user?.id);

  const superAdminFields = [schemaFields.workPermitImageUrl, schemaFields.chequebookImageUrl, schemaFields.documentIdImageUrl];
  const defaultFields = [
    schemaFields.firstName,
    schemaFields.middleName,
    schemaFields.lastName,
    schemaFields.email,
    schemaFields.phoneNumber,
  ];

  const isSuperAdmin = (): boolean => {
    if (!superOwners || !userId) {
      return false;
    }
    return superOwners?.includes(userId);
  };

  const redactNumber = (str: string) => {
    if (!isSuperAdmin() && str) {
      const { length } = str;
      const hiddenPart = length > 3 ? '*'.repeat(length - 3) : /* istanbul ignore next */ '';
      return hiddenPart + str.substring(length - 3);
    }
    return str;
  };

  const checkResubmissionField = (field: string) => {
    const errorMsg = selectedCandidate.hireDetailsResubmissionRequest?.find((item) => item.field === field)?.comment;
    if (!!errorMsg?.trim()) {
      return (
        <Text color="tomato" fontSize="xs">
          {errorMsg}
        </Text>
      );
    }
    return '';
  };

  const hasResubmissionField = (field: string) => {
    return !!selectedCandidate.hireDetailsResubmissionRequest?.find((item) => item.field === field);
  };

  const formatName = (name: string) => {
    if (name === 'ssn' || name === 'sin') return name?.toUpperCase();
    return name
      ?.replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .trim();
  };

  const getLabelTranslation = (label: LabelSchema, field: string) => {
    const fieldDefinition = fieldLabel[field as keyof typeof fieldLabel];
    const translatedLabel = fieldDefinition?.[currentLanguage];
    const isTranslatedLabelAvailable = Boolean(translatedLabel?.trim());

    let currentLabelText = label?.[currentLanguage];
    const isCurrentLabelTextAvailable = Boolean(currentLabelText?.trim());

    if (!isCurrentLabelTextAvailable) {
      currentLabelText = isTranslatedLabelAvailable ? translatedLabel : label.en;
    }

    return formatName(currentLabelText || '');
  };

  const mappedHireDetails = (item: any) => {
    const acc: THireDetailsValue[] = [];
    const newItem = {
      questionId: item.questionId,
      field: item.field,
      label: getLabelTranslation(item.label, item.field),
      images: null,
    };

    switch (item.inputType) {
      case formBuilderType.info:
        break;
      case formBuilderType.documentReview:
      case formBuilderType.documentPreview: {
        let answer = item.answer?.confirmedDate;
        if (Boolean(answer?.trim())) {
          if (!isSuperAdmin()) {
            answer = moment(answer).format('MMM ** YYYY');
          } else {
            answer = moment(answer).format('ll');
          }
          acc.push({ ...newItem, answer });
        }
        break;
      }
      case formBuilderType.address: {
        Object.keys(item.answer).forEach((key) => {
          let answer = item.answer[key];
          if (schemaFields.addressLineOne === key) {
            answer = redactNumber(answer);
          }
          const addressRsubmission = [schemaFields.city, schemaFields.region, schemaFields.completeAddress, schemaFields.country];
          let field = key;
          if (addressRsubmission.includes(key as any)) {
            field = schemaFields.completeAddress;
          }
          acc.push({ ...newItem, label: getLabelTranslation({ en: key }, key), field, answer });
        });
        break;
      }
      case formBuilderType.image:
      case formBuilderType.file:
      case formBuilderType.signature: {
        acc.push({ ...newItem, answer: null, images: item.answer });
        break;
      }
      case formBuilderType.radio:
      case formBuilderType.singleCheckbox: {
        const answer = item.answer?.text?.[currentLanguage];
        if (Boolean(answer?.trim())) {
          acc.push({ ...newItem, answer });
        }
        break;
      }

      case formBuilderType.checkbox: {
        /* istanbul ignore else */
        if (isArray(item.answer)) {
          const answer = item.answer?.reduce((checkAnswer: string, ans: any, index: number) => {
            // eslint-disable-next-line
            const newAns = `${checkAnswer}${ans?.text?.[currentLanguage]}${index === item.answer.length - 1 ? '' : ', '}`;
            return newAns;
          }, '');
          acc.push({ ...newItem, answer });
        }
        break;
      }
      case formBuilderType.yesNo: {
        // const hasFollowUps = item.followups?.length > 0;
        // if (hasFollowUps) {
        //   item.followups?.forEach((followup: any) => {
        //     acc.push(...mappedHireDetails(followup));
        //   });
        // }
        break;
      }
      case formBuilderType.sin:
      case formBuilderType.ssn:
      case formBuilderType.branchTransitNumber:
      case formBuilderType.financialInstitutionNumber:
      case formBuilderType.bankAccountNumber:
      case formBuilderType.routingNumber: {
        acc.push({ ...newItem, answer: redactNumber(item.answer) });
        break;
      }
      case formBuilderType.date: {
        // eslint-disable-next-line
        let answer = item?.answer;
        if (!isSuperAdmin() && item.field === schemaFields.dateOfBirth) {
          answer = moment(answer).format('MMM ** YYYY');
        } else {
          answer = moment(answer).format('ll');
        }
        acc.push({ ...newItem, answer });
        break;
      }
      default: {
        acc.push({ ...newItem, answer: item.answer });
        break;
      }
    }

    return acc;
  };

  const filterHireDetails = (data: any[]) => {
    const defaultData = defaultFields?.reduce((acc, field) => {
      // if (!data.some((item) => item.field === field)) {
      acc.push({
        field,
        label: { en: fieldLabel[field as keyof typeof fieldLabel].en, fr: fieldLabel[field as keyof typeof fieldLabel].fr },
        answer: hireDetailOfCandidate?.seekerHireDetail?.[field as keyof SeekerHireDetail],
        section: t('candidateInformation'),
      });
      // }
      // eslint-disable-next-line
      return acc;
    }, [] as any[]);

    // eslint-disable-next-line
    const res = [...defaultData, ...data?.filter((item) => !defaultFields?.includes(item.field))].reduce(
      (acc: HireSectionT[], item) => {
        if (!item.answer) {
          // eslint-disable-next-line
          return acc;
        }
        const sectionName = formatName(item.section);
        const parentSectionName = formatName(item.parentSection);
        const hasParentSection = !!parentSectionName;
        if (hasParentSection) {
          const parentSectionIndex = acc?.findIndex((group: any) => group.section === parentSectionName);
          const mappedDetails = mappedHireDetails(item);
          if (parentSectionIndex === -1) {
            acc.push({
              section: parentSectionName,
              fields: [
                {
                  section: sectionName,
                  fields: [...mappedDetails],
                },
              ],
            });
          } else {
            const sectionIndex = acc[parentSectionIndex]?.fields?.findIndex((group: any) => group.section === sectionName);
            /* istanbul ignore else */
            if (sectionIndex === -1) {
              acc[parentSectionIndex].fields.push({
                section: sectionName,
                fields: [...mappedDetails],
              });
            } else if ('fields' in acc[parentSectionIndex].fields[sectionIndex]) {
              (acc[parentSectionIndex].fields[sectionIndex] as HireSectionT)?.fields?.push(...mappedDetails);
            }
          }
        } else {
          const sectionIndex = acc?.findIndex((group: any) => group.section === sectionName);
          const mappedDetails = mappedHireDetails(item);
          if (sectionIndex === -1) {
            // eslint-disable-next-line
            acc.push({
              section: sectionName,
              fields: [...mappedDetails],
            });
          } else {
            acc[sectionIndex]?.fields.push(...mappedDetails);
          }
        }

        // eslint-disable-next-line
        return acc;
      },
      [] as HireSectionT[],
    );

    function filterEmptySections(sections: HireSectionT[]): HireSectionT[] {
      return sections
        .map((section) => {
          // Recursively filter the nested `fields` if they are `HireSectionT` types
          const filteredFields = section.fields.filter((field) => {
            if ((field as HireSectionT).fields) {
              // Recursively filter nested sections
              return (field as HireSectionT).fields.length > 0;
            }
            return true; // Keep non-HireSectionT items
          });

          // Return the section only if it has non-empty fields
          return { ...section, fields: filteredFields };
        })
        .filter((section) => section.fields.length > 0); // Remove sections with empty fields
    }

    // eslint-disable-next-line
    return filterEmptySections(res);
  };

  const loadCandidateHireDetail = async () => {
    const hireDetailResponse = await loadHireDetailOfCandidate({ candidateId: selectedCandidate.id });
    const { status, title } = hireDetailResponse as CandidateHireFormDetailResponseWithError;
    if (status) {
      toast({
        title: `${title}`,
        status: 'error',
        isClosable: true,
      });
    } else {
      setHireDetailOfCandidate(hireDetailResponse as CandidateHireFormDetailResponse);
    }
  };

  const loadHireDetail = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const hireDetailsResponse = await getSeekerHierDetails({ candidateId: selectedCandidate.id });
      await loadCandidateHireDetail();
      setCurrentLanguage(
        hireDetailsResponse?.data?.answers?.languages?.includes(i18n.language)
          ? i18n.language
          : hireDetailsResponse?.data?.answers?.defaultLanguage || 'en',
      );
      setHireDetails(hireDetailsResponse?.data?.answers?.answers ?? []);
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCandidate, setHireDetails, getSeekerHierDetails]);

  React.useEffect(() => {
    const hireDetailsData = [...hireDetails];
    if (hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl) {
      const signatureUrl = isArray(hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl)
        ? hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl
        : [hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl];
      hireDetailsData.push({
        questionId: 'finalSignature',
        inputType: formBuilderType.signature,
        field: 'signature',
        answer: signatureUrl,
        section: 'Final Signature',
        label: {
          en: 'Final Signature',
          fr: 'Signature finale',
        },
        order: 0,
        isInfo: false,
        questionText: {
          en: 'question',
          fr: 'question',
        },
      });
    }
    setFilteredHireDetails(filterHireDetails(hireDetailsData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hireDetails, i18n, hireDetailOfCandidate]);

  React.useEffect(() => {
    // eslint-disable-next-line
    loadHireDetail();

    // eslint-disable-next-line
  }, []);

  if (isLoading)
    return (
      <VStack py={5}>
        {Array(5)
          .fill(0)
          .map((_, index) => {
            const flexBasis1 = `${Math.floor(Math.random() * (40 - 20 + 1) + 10)}%`;
            const flexBasis2 = `${Math.floor(Math.random() * (30 - 20 + 1) + 10)}%`;
            return (
              <HStack key={index} justify="space-between" spacing={2} px={5} width="100%" data-testid="skeleton">
                <Skeleton key={`${index}-1`} height="4" flexBasis={flexBasis1} colorScheme={colors.black} />
                <Skeleton key={`${index}-2`} height="3" flexBasis={flexBasis2} colorScheme={colors.gray[400]} />
              </HStack>
            );
          })}
      </VStack>
    );

  const sectionRenderer = (section: HireSectionT, isSubSection = false) => {
    const sectionName = section?.section;

    const values = section.fields;
    /* istanbul ignore if */
    if (!values?.length) return null;
    return (
      <>
        <Box key={sectionName} paddingLeft={isSubSection ? 4 : 0}>
          <Heading px={5} as="h3" fontSize="md">
            {sectionName}
          </Heading>
          <Box
            pb={1}
            borderTop={`1px solid ${colors.gray[50]}`}
            borderBottom={isSubSection ? 'none' : `1px solid ${colors.gray[50]}`}
          >
            {values?.map((value) => {
              if ('fields' in value) {
                return sectionRenderer(value, true);
              }
              const label = value.label || '';
              const isImage = value.images && value.images.length > 0;
              const isSuperAdminField = superAdminFields.includes(value.field as schemaFields);
              const isOnlyForSuperAdmin = isSuperAdminField && !isSuperAdmin();
              const answer = value.answer || '';
              const images = isOnlyForSuperAdmin && isImage ? value.images?.map(() => PixelatedImage) : value.images;
              return (
                <VStack spacing={0} key={value.questionId} px={5}>
                  <Flex alignItems="center" justifyContent="space-between" width="100%">
                    <Heading
                      flex="1"
                      as="h6"
                      fontSize="sm"
                      lineHeight="2.2"
                      color={hasResubmissionField(value.field) ? 'tomato' : 'gray.800'}
                      whiteSpace="nowrap"
                    >
                      {label}
                    </Heading>
                    {isImage ? (
                      <HStack>
                        {images?.map((image, imageIndex) => (
                          <ImagePreviewModal
                            key={`${image}-${imageIndex}`}
                            isDisabled={isOnlyForSuperAdmin}
                            images={images}
                            getImageKey={(val) => val || ''}
                            getImageSrc={(val) => val || ''}
                            previewTitle={label}
                            selectedImage={image}
                            setTrigger={
                              <ImageView src={image} alt={image} height="40px" width="40px" key={`${image}-${imageIndex}`} />
                            }
                            modalSize="6xl"
                            previewImageHeight="600px"
                          />
                        ))}
                      </HStack>
                    ) : (
                      <Text as="div" fontSize="sm" textAlign="right" flex="1">
                        {answer}
                        {checkResubmissionField(value.field)}
                      </Text>
                    )}
                  </Flex>
                  {isOnlyForSuperAdmin && (
                    <Text
                      as="div"
                      d="flex"
                      fontSize="xs"
                      color={colors.gray[400]}
                      alignItems="center"
                      justifyContent="flex-end"
                      width="100%"
                    >
                      <RiLock2Fill style={{ marginRight: '5px' }} />
                      {t('noPermissionToViewImage')}
                    </Text>
                  )}
                </VStack>
              );
            })}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {filteredHireDetails?.map((item) => {
        return sectionRenderer(item);
      })}
    </>
  );
}
