import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { v4 as uuidv4 } from 'uuid';
import { FieldSchema, formBuilderType, isFieldSchema, mapFormSchema, SectionSchema } from '../formElements/formBuilderSchema';
import { formBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  Spinner,
  Center,
  Text,
  Stack,
  Divider,
  HStack,
  Button,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import theme from '../../../../styles/customTheme';
import { formatLabel } from '../formElements/section/SectionNameInput';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCircle } from 'react-icons/md';
import ButtonPreview from '../formElements/ButtonPreview';
import InputPreview from '../formElements/InputPreview';
import { SignatureIcon } from '../formElements/SignatureSchemaBuilder';
import { PATH_HIRE_FORM_EDIT } from '../../../../routes/constants';
import moment from 'moment';

interface HireFormViewModeProps {
  trigger: React.ReactNode;
  templateId: string;
  templateName: string;
  templateType?: string | null;
}

export default function HireFormViewMode({ trigger, templateId, templateName, templateType }: HireFormViewModeProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, i18n } = useTranslation('hrFormTemplate');

  const { getTemplateById } = useStoreActions((state) => state.hrFormTemplate);
  const { currentTemplate, isLoading } = useStoreState((state) => state.hrFormTemplate);

  const { currentUserProfile } = useCurrentUserProfile();
  const history = useHistory();

  const [questions, setQuestions] = React.useState<SectionSchema[] | undefined>([]);

  const accountId = currentUserProfile?.account ?? '';

  const currentLanguage = currentTemplate?.languages?.includes(i18n.language)
    ? i18n.language
    : currentTemplate?.defaultLanguage || 'en';

  React.useEffect(() => {
    if (!isOpen) return;
    getTemplateById({ account: accountId, id: templateId, history });

    // eslint-disable-next-line
  }, [isOpen]);

  React.useEffect(() => {
    if (!currentTemplate?.questions?.length || !templateId) {
      setQuestions([{ section: 'section1', fields: [], id: uuidv4() }]);
      return;
    }
    const grouped = mapFormSchema({
      currentTemplate: currentTemplate.questions,
      formBuilderSchemas,
    });
    if (currentTemplate?.needFinalSignature) {
      grouped.push({
        section: 'Signature',
        fields: [
          {
            inputType: 'signature',
            label: { en: 'Candidate Signature', fr: 'Signature du candidat' },
            required: true,
            text: {
              en: `I ${
                currentUserProfile?.fullName
              }, confirm that all information entered in the application from on ${moment().format(
                'Do MMM YYYY [at] hh:mm A',
              )} is accurate, and I understand that providing false information may result in my disqualification from the recruitment process or dismissal.`,
              fr: `Je ${
                currentUserProfile?.fullName
              } confirme que toutes les informations saisies sur l'application depuis le ${moment().format(
                'Do MMM YYYY [at] hh:mm A',
              )} sont exactes et que je comprends que la fourniture d'informations fausses pourrait entraîner ma désqualification ou mon licencement du processus de recrutement.`,
            },
            timestamp: Date.now(),
            id: uuidv4(),
            order: 1,
            isFollowUp: false,
            parent: uuidv4(),
            section: 'Final Signature',
            field: 'signature',
          } as FieldSchema,
        ],
        id: uuidv4(),
      });
    }

    setQuestions(grouped);
    // eslint-disable-next-line
  }, [currentTemplate, templateId]);

  return (
    <>
      <Box onClick={onOpen}>{trigger}</Box>
      <Modal onClose={onClose} size="5xl" isOpen={isOpen} isCentered scrollBehavior="inside" autoFocus={false}>
        <ModalOverlay />
        <ModalContent backgroundColor="#F3F3F3" data-testid="HireFormViewMode">
          <ModalHeader>
            <HStack justify="space-between">
              <HStack spacing={0}>
                <Text>{templateName}</Text>
                {templateType === 'default' && (
                  <Text
                    fontStyle="italic"
                    color={theme.colors.gray[400]}
                    textOverflow="ellipsis"
                    fontWeight="normal"
                    fontSize="sm"
                  >
                    {' '}
                    , ({t('formBuilder.systemDefault')})
                  </Text>
                )}
              </HStack>
              <HStack>
                {templateType !== 'default' && (
                  <Button
                    onClick={() => history.push(PATH_HIRE_FORM_EDIT.replace(':templateId', templateId))}
                    size="sm"
                    colorScheme="blue"
                  >
                    {t('templateListView.tableActions.edit')}
                  </Button>
                )}
                <ModalCloseButton position="relative" inset={0} />
              </HStack>
            </HStack>
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              <Center paddingY={10} data-testid="loading">
                <Spinner size="sm" color="blue.500" />
              </Center>
            ) : (
              <Box>
                <Stack spacing={4}>
                  {questions?.map((section) => (
                    <Section key={section.id} section={section} currentLanguage={currentLanguage} />
                  ))}
                </Stack>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function Section({
  section,
  isSubSection = false,
  currentLanguage,
}: {
  section: SectionSchema;
  isSubSection?: boolean;
  currentLanguage: string;
}) {
  return (
    <Box key={section.id}>
      <Text fontSize="sm" fontWeight="bold" marginBottom={1}>
        {formatLabel(section.section)}
      </Text>
      <Box backgroundColor={theme.colors.white} borderRadius={theme.radii.md} padding={isSubSection ? 0 : 4}>
        <Stack divider={<Divider />}>
          {section.fields?.map((field) => {
            if (isFieldSchema(field)) {
              if (field && field.isFrontEndOnly) return null;
              return <Question key={field.id} field={field} currentLanguage={currentLanguage} />;
            } else {
              return (
                <Box borderRadius={theme.radii.md} border="1px solid #E5E7EB" padding={4}>
                  <Section key={field.id} section={field} isSubSection currentLanguage={currentLanguage} />
                </Box>
              );
            }
          })}
        </Stack>
      </Box>
    </Box>
  );
}

function Question({ field, currentLanguage }: { field: FieldSchema; currentLanguage: string }) {
  const { t } = useTranslation('hrFormTemplate');

  const hasDocumentReview = field?.documentReview;
  const hideLabelFor: string[] = [];
  const isYesNo = formBuilderType.yesNo === field.inputType;
  const isImage = formBuilderType.image === field.inputType;
  const isSignature = formBuilderType.signature === field.inputType;

  const inputPreviewFields: string[] = [
    formBuilderType.text,
    formBuilderType.number,
    formBuilderType.email,
    formBuilderType.date,
    formBuilderType.time,
    formBuilderType.phone,
  ];
  const placeholderType: Record<string, Record<string, string>> = {
    [formBuilderType.text]: {
      en: t('formBuilder.inputHere'),
      fr: t('formBuilder.inputHere'),
    },
    [formBuilderType.number]: {
      en: '1234567890',
      fr: '1234567890',
    },
    [formBuilderType.email]: {
      en: 'example@domain.com',
      fr: 'exemple@domaine.com',
    },
    [formBuilderType.date]: {
      en: 'MM-DD-YYYY',
      fr: 'MM-DD-YYYY',
    },
    [formBuilderType.time]: {
      en: 'HH:MM',
      fr: 'HH:MM',
    },
    [formBuilderType.phone]: {
      en: '000-000-0000',
      fr: '000-000-0000',
    },
  };
  const placeholderText = (formBuilderSchemas[field.inputType]?.defaultValue as FieldSchema)?.previewPlaceholders?.placeholder;
  const showShowMe = [
    formBuilderType.routingNumber,
    formBuilderType.bankAccountNumber,
    formBuilderType.branchTransitNumber,
    formBuilderType.financialInstitutionNumber,
  ].some((x) => x === field?.inputType);

  return (
    <Box key={field.id} paddingY={2} sx={{ 'p > span': { fontWeight: 'bold' } }}>
      <Box flexGrow={1}>
        <Stack>
          {hideLabelFor.includes(field.inputType) ? null : (
            <Text fontSize="xs" color={theme.colors.gray[500]} marginTop={-1} textDecoration="underline">
              {formatLabel(field?.label?.[currentLanguage] ?? '')}
            </Text>
          )}

          <Text fontSize="sm" dangerouslySetInnerHTML={{ __html: field?.text?.[currentLanguage] ?? '' }} />
          {placeholderText ||
            (inputPreviewFields.includes(field.inputType) && (
              <InputPreview value={placeholderText ?? placeholderType[field.inputType]?.[currentLanguage]} />
            ))}

          {isYesNo && (
            <HStack direction={field.optionList?.some((item) => item?.tag) ? 'column' : 'row'}>
              {field.optionList?.map((option, index) => {
                return <ButtonPreview key={index} label={option.text?.[currentLanguage]!} disabled />;
              })}
            </HStack>
          )}

          {isImage && (
            <HStack>
              <ButtonPreview label={t('formBuilder.chooseAFile')} disabled />
              <ButtonPreview label={t('formBuilder.openCamera')} disabled />
            </HStack>
          )}

          {showShowMe && (
            <Box>
              <ButtonPreview label={t('formBuilder.showMe')} disabled />
            </Box>
          )}

          {isSignature && (
            <Stack
              align="center"
              justify="center"
              borderRadius={theme.radii.md}
              border="1px solid #A8B1E3"
              backgroundColor="#F3F6F9"
              height="10rem"
            >
              <Text textAlign="center" fontSize="sm" fontWeight="bold">
                {t('formBuilder.signHere')}
              </Text>
              <SignatureIcon />
            </Stack>
          )}
        </Stack>
        {field?.optionList?.map((option) => {
          const hasFollowups = field?.followUpQuestionList?.filter((followUp) => followUp.optionId === option.id)?.length! > 0;
          if (isYesNo && !hasFollowups) return null;
          return (
            <Box
              key={option.id}
              marginTop={1}
              sx={
                isYesNo
                  ? {
                      border: '1px solid #E5E7EB',
                      backgroundColor: '#FBFDFF',
                      borderRadius: theme.radii.md,
                      padding: 4,
                      marginTop: 4,
                    }
                  : {}
              }
            >
              <HStack align={isYesNo ? 'flex-start' : 'center'} width="100%" flexDirection={isYesNo ? 'column' : 'row'}>
                {formBuilderType.checkbox === field.inputType && (
                  <MdOutlineCheckBoxOutlineBlank size={4} color={theme.colors.gray[400]} />
                )}
                {formBuilderType.radio === field.inputType && <MdOutlineCircle size={14} color={theme.colors.gray[400]} />}
                <HStack>
                  {isYesNo && (
                    <Text fontSize="sm" fontWeight={'normal'} color={theme.colors.gray[600]} textDecoration="underline">
                      {t('formBuilder.showNextQuestion')}:
                    </Text>
                  )}
                  {isYesNo && <ButtonPreview label={option?.text?.[currentLanguage]!} disabled />}
                  {!isYesNo && (
                    <Text
                      fontSize={'sm'}
                      fontWeight={'normal'}
                      color={theme.colors.gray[400]}
                      padding={0}
                      dangerouslySetInnerHTML={{ __html: option?.text?.[currentLanguage] ?? '' }}
                    />
                  )}
                </HStack>
                {hasFollowups && (
                  <Box width="100%" marginLeft="0 !important" borderRadius={theme.radii.md}>
                    <Stack divider={<Divider />}>
                      {field?.followUpQuestionList
                        ?.filter((followUp) => followUp.optionId === option.id)
                        ?.map((followUp) => (
                          <Question key={followUp.id} field={followUp} currentLanguage={currentLanguage} />
                        ))}
                    </Stack>
                  </Box>
                )}
              </HStack>
            </Box>
          );
        })}
        {hasDocumentReview && (
          <Box marginTop={2} borderRadius={theme.radii.md} paddingY={2}>
            <Stack>
              <Text
                fontSize="sm"
                dangerouslySetInnerHTML={{ __html: field?.documentReview?.description?.[currentLanguage] ?? '' }}
              />
              <DocumentPreviewModal url={field?.documentReview?.url?.[currentLanguage]} />
              <HStack align="flex-start">
                <MdOutlineCheckBoxOutlineBlank size={24} />
                <Text
                  fontSize="sm"
                  dangerouslySetInnerHTML={{ __html: field?.documentReview?.acknowledgeContent?.[currentLanguage] ?? '' }}
                />
              </HStack>
              <Button
                size="sm"
                borderRadius={theme.radii['3xl']}
                colorScheme="blue"
                width="fit-content"
                variant="outline"
                disabled
              >
                {field?.documentReview?.confirmText?.[currentLanguage] ?? ''}
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function DocumentPreviewModal({ url }: { url?: string }) {
  const { isOpen, onClose } = useDisclosure();

  if (!url) return null;
  return (
    <Box>
      <Box
        style={{
          backgroundColor: '#F0F8FE',
          borderRadius: '4px',
          border: '2px dashed',
          borderColor: '#E2E8F0',
        }}
      >
        <Stack align="center" overflow="hidden" position="relative" data-testid="document_preview">
          <embed src={url} type="application/pdf" width="100%" height="200px" />
        </Stack>
      </Box>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        isCentered
        scrollBehavior="inside"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent padding={0} height="100%">
          <ModalCloseButton position="fixed" top={2} right={2} color={theme.colors.white} />
          <ModalBody padding={0} height="100%" flexGrow={1} borderRadius={theme.radii.md}>
            <iframe
              src={url!}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
              title="PDF Preview"
              data-testid="pdfPreview"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
