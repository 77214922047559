export default {
  hireFormRequested12HoursAgo: 'Requested 12 Hrs Ago',
  hireFormRequested24HoursAgo: 'Requested 24 Hrs Ago',
  hireFormRequested48HoursAgo: 'Requested 48 Hrs Ago',
  hireFormRequested72HoursAgo: 'Requested 72 Hrs Ago',
  hireFormCompleted24HoursAgo: 'Completed 24 Hrs Ago',
  hireFormHelpRequested: 'Help Requested',
  hireFormInReview: 'Resubmission Requested',

  hireFormResubmission12HoursAgo: 'Hire Form Resubmission Requested 12 Hrs Ago',
  hireFormResubmission24HoursAgo: 'Hire Form Resubmission Requested 24 Hrs Ago',
  hireFormResubmission48HoursAgo: 'Hire Form Resubmission Requested 48 Hrs Agoo',
  hireFormResubmission72HoursAgo: 'Hire Form Resubmission Requested 72 Hrs Ago',

  bgCheckRequested24HoursAgo: 'Requested 24 Hrs Ago',
  bgCheckRequested48HoursAgo: 'Requested 48 Hrs Ago',
  bgCheckRequested72HoursAgo: 'Requested 78 Hrs Ago',
  bgCheckFlaggedByCertnCandidates: 'Flagged By Background Check',
  bgCheckNotProcessedByCertn24HoursAgoCandidates: 'Not Yet Processed ',

  offerMade12HoursAgo: 'Offer Made 12 Hrs Ago',
  offerMade24HoursAgo: 'Offer Made 24 Hrs Ago',
  offerMade36HoursAgo: 'Offer Made 36 Hrs Ago',
  offerMade48HoursAgo: 'Offer Made 48 Hrs Ago',

  summary: 'Summary',
  hiresProcessing: 'Hires In Process',
  outstandingOffers: 'Outstanding Offers',
  toDo: 'To Do',
  inProgress: 'In Progress',
  completed: 'Completed',
  hireForm: 'Hire Form',
  backgroundCheck: 'Background Check',
  offer: 'Offer',
  languageEvaluation: 'Language Evaluation',
  conversationalAI: 'Conversational AI',
  dialogflow: 'Conversational AI',
  filterBy: 'Filter By',

  pageTitle: 'Today’s To Do List',

  actionToTake: 'To Do',
  lastStatus: 'Last Update',
  status: 'Status',
  locationAndPosition: 'Location & Position',
  candidate: 'Candidate',
  hrPlus: 'h +',
  noData: 'No Data Found',
  hi: 'Hey',
  cancel: 'Cancel',
  sendEmail: 'Send Email',
  send: 'Send',
  sendAndDismissed: 'Send and Dismiss candidate',
  sending: 'Sending',
  contactCertn: 'Contact Certn',
  contactDollarama: 'Contact Dollarama',
  dismissedCandidateTitle: 'Dismissed Candidate',
  offerTitle: 'Pending Offer',
  offerMade12HoursAgoDesp: 'Offer made over 12 hours ago. Message candidate, informing them they need to respond to the offer.',
  offerMade24HoursAgoDesp:
    'Offer made over 24 hours ago. Message candidate, informing them they need to respond to the offer. Warn them they may be removed from the process if they do not accept or decline the offer.',
  offerMade36HoursAgoDesp: 'Offer made over 36 hours ago. Message candidate, informing them they need to respond to the offer.',
  offerMade48HoursAgoDesp:
    'Offer made over 48 hours ago. Final attempt: Call candidate by phone, then send a message to inform them that they will be removed from the process tonight unless they accept the offer.',
  hireFormTitle: 'Pending Hire Form Completion',
  hireFormResubmissionTitle: 'Pending Hire Form Resubmission Completion',
  hireFormRequested12HoursAgoDesp: -'Hire form requested over 12 hours ago. Message candidate, asking them to complete hire form as the last step in the hiring process.',
  hireFormRequested24HoursAgoDesp: 'Hire form requested over 24 hours ago. Message candidate, to complete hire form.',
  hireFormRequested48HoursAgoDesp:
    'Hire form requested over 48 hours ago. Final attempt: Call candidate by phone, then message them so they are aware that they will be removed from the process if this is not completed within 12 hours .',
  hireFormRequested60HoursAgoDesp:
    'Hire form requested over 60 hours ago. Dismiss candidate unless sufficient justification for the delay has been given. Review daily and dismiss if appropriate.',
  hireFormRequested72HoursAgoDesp:
    'Hire form requested over 60 hours ago. Dismiss candidate unless sufficient justification for the delay has been given. Review daily and dismiss if appropriate.',

  hireFormResubmission12HoursAgoDesp: -'Hire form resubmission over 12 hours ago. Message candidate, asking them to complete hire form as the last step in the hiring process.',
  hireFormResubmission24HoursAgoDesp: 'Hire form resubmission over 24 hours ago. Message candidate, to complete hire form.',
  hireFormResubmission48HoursAgoDesp:
    'Hire form resubmission over 48 hours ago. Final attempt: Call candidate by phone, then message them so they are aware that they will be removed from the process if this is not completed within 12 hours .',
  hireFormResubmission60HoursAgoDesp:
    'Hire form resubmission over 60 hours ago. Dismiss candidate unless sufficient justification for the delay has been given. Review daily and dismiss if appropriate.',
  hireFormResubmission72HoursAgoDesp:
    'Hire form resubmission over 60 hours ago. Dismiss candidate unless sufficient justification for the delay has been given. Review daily and dismiss if appropriate.',

  backgroundCheckTitle: 'Pending Background Check Completion',
  bgCheckRequested12HoursAgoDesp: 'Background check requested over 12 hours ago. Chase candidate to complete the process.',
  bgCheckRequested24HoursAgoDesp:
    'Background check requested over 24 hours ago. Warn candidate they may be removed from the process.',
  bgCheckRequested36HoursAgoDesp:
    'Background check requested over 36 hours ago. Message candidate to inform them they are being removed from the process and dismiss them.',
  bgCheckRequested48HoursAgoDesp:
    'Background check requested over 48 hours ago. Message candidate to inform them they are being removed from the process and dismiss them.',
  bgCheckFlaggedByCertnCandidatesDesp: 'Background check has been flagged by provider. Review the background check results.',
  bgCheckRequested72HoursAgoDesp:
    'Background check requested over 72 hours ago. Message candidate to inform them they are being removed from the process and dismiss them.',
  bgCheckNotProcessedByCertn24HoursAgoCandidatesDesp: `Background check still being analyzed by Certn 24 hours after submission. Contact Certn at <a href="mailto:support@certn.co" target='_blank'>support@certn.co</a> to request update`,
  hireFormCompleted24HoursAgoDesp: `Contact payroll@dollarama.com and CC appyhere@dollarama.com to get an update on the completed hire form still pending verification. Important: only submit if the candidate's earliest start date is planned for this week.`,
  hireForHelpRequestTitle: 'Hire Form Help Requested',
  hireFormHelpRequestDesp:
    'Candidate has requested assistance in completing their Hire Form. Message candidate to investigate their issue.',
  helpRequestFor: 'Candidate stuck on {{feildName}} step',
  yourReply: 'Your Reply',
  sendMessage: 'Send Message',
  enterYourMessage: 'Enter your message',
  showingResult: 'Showing {{currentItemsLength}} of {{totalItemsLength}} tasks',
  urlCopiedInfo: 'Copied Candidate URL',
  hireFormId: 'Hire Form ID:',
  hireDetailIdCopiedInfo: 'Copied Hire Form ID',
  mailtoHireForm: 'Hire%20form%20overdue%20for%20Hire%20ID:%20',
  showCompletedItems: 'Show Completed Tasks',
  allCaughtUp: 'You are All Caught Up!',
  noPendingUpdates: 'Looks like there are no pending updates at the moment.',
  checkBackLater: 'Check back later to see if any applications need your attention.',
  inProgressForMe: 'In Progress (for me)',
  inProgressForOthers: 'In Progress (for others)',
  selectAll: 'Select All',
};
